// libraries
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
// Core Files
import { Grid } from '@mui/material'
import { withEnv } from '@praxis/component-runtime-env'
// Core Files
import withRouter from '../../../../containers/Router/WithRouter'
import HeaderTitle from '../../../Header/HeaderTitle'
import HeaderTabs from '../HeaderTabs'
import EditProjectForm from '../../ProjectForms/EditProjectForm'
import ProjectDetailsWidget from './ProjectDetailsWidget'
import TasksListWidget from './TasksListWidget'
// actioncreator
import {
  getProjectAssetDetails,
  closeModal,
} from '../../../../store/projects/actionCreator'
//helper
import {
  constructBreadCrumbObj,
  generateBreadCrumb,
} from '../../../../helpers/ProjectsHelper'
import { isAuthorizedToPage } from '../../../../helpers/UtilityHelper'
//store
import { selectMemberOf } from '../../../../store/auth/selector'

const styles = makeStyles((theme) => ({
  root: {
    padding: '0 8px',
  },
  LIText: {
    flex: 'none !important',
    minWidth: '50%',
    width: '100px',
    overflowWrap: 'break-word',
  },
  LISecondaryAction: {
    display: 'grid',
    minWidth: '46%',
    gridTemplateColumns: '50px 30px auto auto',
    gridTemplateRows: '0px 0px 15px 0px',
    '-ms-grid-columns': '50px 30px auto auto',
    '-ms-grid-rows': '0px 0px 15px 0px',
    marginLeft: '10px',
  },
  TaskIconButton: {
    height: '22px !important',
    width: 'auto !important',
    padding: 0,
  },
  card: {
    margin: '16px 0px 0px 0px',
    height: 'auto',
  },
  whiteColor: {
    color: useTheme().palette.common.white,
  },
  media: {
    height: 'auto',
    maxWidth: '200px !important',
    maxHeight: '300px !important',
    margin: 'auto',
  },
  cardContent: {
    fontSize: '12px',
    '& span': {
      display: 'inline',
    },
  },
  assetDivider: {
    width: '100%',
    maxWidth: '100%',
    padding: '20px',
  },
  Typography: {
    fontSize: '12px',
    color: '#8D8D8D',
    paddingRight: '3px',
    fontWeight: 500,
  },
  editIcon: {
    marginRight: '10px',
    color: '#717171',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
  },
  width200: {
    width: '200px',
  },
}))

export class ProjectDashboard extends React.Component {
  constructor(props) {
    super(props)
    const { memberOf = [], env = {} } = this.props
    const { REACT_APP_ROLES_CONFIG = {} } = env
    this.isAuthorizedUser = isAuthorizedToPage(
      'projects',
      memberOf,
      REACT_APP_ROLES_CONFIG
    )
    this.state = {
      isModalOpen: false,
      editProjectEl: null,
      createTaskEL: null,
      isCoverImgLoading: false,
    }
  }

  componentDidMount() {
    const {
      getProjectAssetDetails,
      pagination = {},
      router = {},
      projectId = '',
    } = this.props
    const { params = {} } = router
    const idOfProject = params.projectId ? params.projectId : projectId
    getProjectAssetDetails(idOfProject, pagination)
  }

  closeEditProject = () => {
    const {
      getProjectAssetDetails = () => {},
      closeModal = () => {},
      pagination = {},
      router: { params = {} } = {},
      projectId = '',
    } = this.props
    const projectIdentifier = params.projectId ? params.projectId : projectId
    getProjectAssetDetails(projectIdentifier, pagination)
    closeModal('editProject')
  }

  render() {
    const {
      classes = {},
      projectData = {},
      projectId = '',
      modal = {},
    } = this.props
    const {
      project_id = '',
      project_initiator = {},
      project_type = '',
      project_uuid = '',
      run_date = '',
      uoi_id = '',
      project_name = '',
    } = projectData
    const projectDetails = {
      project_id,
      project_type,
      project_uuid,
      uoi_id,
      run_date,
      project_initiator: project_initiator !== null ? project_initiator : {},
    }
    const breadCrumb = constructBreadCrumbObj(project_name)

    return (
      <>
        <HeaderTitle title={generateBreadCrumb(breadCrumb)} icon="Project" />
        <Helmet>
          <title>{generateBreadCrumb(breadCrumb, true)}</title>
        </Helmet>
        <HeaderTabs activeTabIndex={0} id={projectId} />
        <div className={classes.root}>
          {project_name !== '' && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <ProjectDetailsWidget
                      classes={classes}
                      isAuthorizedUser={this.isAuthorizedUser}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TasksListWidget
                  projectId={projectId}
                  classes={classes}
                  isAuthorizedUser={this.isAuthorizedUser}
                />
              </Grid>
            </Grid>
          )}
        </div>
        {modal.editProject && (
          <EditProjectForm
            open={modal.editProject}
            onClose={this.closeEditProject}
            projectDetails={projectDetails}
          />
        )}
      </>
    )
  }
}

ProjectDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  projectId: PropTypes.string,
  projects: PropTypes.object,
  projectData: PropTypes.object,
  modal: PropTypes.object,
  closeModal: PropTypes.func,
  getProjectAssetDetails: PropTypes.func,
  pagination: PropTypes.object,
}

const mapStateToProps = (state = {}) => {
  const { projects = {} } = state
  const { projectData = {}, modal = {}, pagination = {} } = projects
  return {
    projects,
    projectData,
    modal,
    pagination,
    memberOf: selectMemberOf()(state),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProjectAssetDetails,
      closeModal,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <ProjectDashboard {...props} classes={classes} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEnv()(withRouter(MyComponent)))

export const promptConfig = [
  { label: 'Team', property: 'team_name' },
  { label: 'Prompt Name', property: 'name', type: 'link' },
  { label: 'Version', property: 'version' },
  { label: 'Category', property: 'category_id', type: 'id_to_name' },
  { label: 'Last Modified By', property: 'created_by' },
  { label: 'Last Modified Date', property: 'created_timestamp', type: 'timestamp' },
  { label: 'Conditions', property: 'conditions', type: 'object' },
];

export const runPromptCategories = [
  { label: 'Keywords', value: 'KEYWORDS' },
  { label: 'Description', value: 'DESCRIPTION' },
  { label: 'Classification', value: 'CLASSIFICATION' },
  { label: 'Talent', value: 'TALENT' },
]

export const tabsConfig = [
  { label: "ASSETHUB PROMPTS", value: "ASSETHUB" },
  { label: "PARTNER PROMPTS", value: "PARTNERS"}
]

export const promptConditions = [
  { id: 'project_type', label: "Project Type" },
  { id: 'channel', label: "Channel" },
  { id: 'classification', label: "Classification" },
  { id: 'file_type', label: "File Type" },
]

export const conditionLabels = {
  project_type: 'Project Type',
  channel: 'Channel',
  classification: 'Classification',
  file_type: 'File Type',
}
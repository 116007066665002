import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import CollapsibleCard from '../../components/Custom/CollapsibleCard'
import {
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_CARD_SHOW_BUTTON_TEXT,
} from '../../constants/metadata'
import { convertUTCtoLocal } from '../../utils/utils'

import { useMetadataContext } from './MetadataContext'

const VideoInfoCard = ({ containerClasses = '' }) => {
  const { assetMetadata = {} } = useMetadataContext()
  const { metadata: { video = {} } = {} } = assetMetadata
  const {
    agency = [],
    director = [],
    live_date = '',
    producer = [],
    qdaf = '',
  } = video || {}
  const liveDateFormatted = convertUTCtoLocal(live_date, 'lll') || ''
  return (
    <CollapsibleCard
      cardTitle={'Video Information'}
      hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
      showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
      defaultCollapse={true}
    >
      <Grid>
        {producer && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="producer">Producer</InputLabel>
            <Input id="producer" value={producer} disableUnderline />
          </FormControl>
        )}
        {director && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="director">Director</InputLabel>
            <Input
              id="director"
              fullWidth
              multiline
              value={director}
              disableUnderline
            />
          </FormControl>
        )}
        {agency && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="agency">Agency</InputLabel>
            <Input id="agency" value={agency} disableUnderline />
          </FormControl>
        )}
        {live_date && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="liveDate">
              Live Date
            </InputLabel>
            <Input
              id="liveDate"
              value={liveDateFormatted}
              disableUnderline
            />
          </FormControl>
        )}
        {qdaf && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="qdaf">QDAF</InputLabel>
            <Input
              id="qdaf"
              value={qdaf}
              disableUnderline
            />
          </FormControl>
        )}
      </Grid>
    </CollapsibleCard>
  )
}

export default VideoInfoCard

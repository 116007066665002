import React, { useState, useEffect, startTransition } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Drawer,
} from '@mui/material'
import { useEnv } from '@praxis/component-runtime-env'

import HeaderTitle from '../../../Header/HeaderTitle'
import ClassificationUploadDialog from '../../../Classification/ClassificationUploadDialog'
import UploadDialog from '../../../Upload/UploadDialog'
import BulkActionBar from '../../../Search/BulkActionBar'
import DeleteAssetsDailog from '../../../Search/DeleteAssetsDailog'
import ApproveAssetsDialog from '../../../Search/ApproveAssetsDialog'

import {
  dispatchNotification,
  showNotification,
} from '../../../../store/notification/actionCreator'

import {
  generateBreadCrumb,
  constructBreadCrumbObj,
  validateIfAssetsAreProcessedAndWip,
} from '../../../../helpers/ProjectsHelper'
import {
  removeFromObjectByKey,
  isAuthorizedToPage,
} from '../../../../helpers/UtilityHelper'
import { startDownload } from '../../../../helpers/bulkDownloadHelper'
import {
  getDeleteAssetNotificationMessage,
  bulkEditErrorMessageBuilder,
} from '../../../../helpers/MetadataHelper'
import { getChunkNumbers, getProcessedWipCount } from './helper'

import {
  deleteRevisions,
  fetchAssetMetadata,
  updateBulkMetadata,
} from '../../../../store/metadata/actionCreator'
import {
  setDeleteAssetInProgress,
  setDownloadAssetInProgress,
  fetchMetadatadetails,
} from '../../../../store/search/actions'
import {
  getProjectAssetsWithFilters,
  setProjectCoverImage,
  setDefaultPagination,
  setSelectedAssets,
  getProjectRevisionAssets,
} from '../../../../store/projects/actionCreator'
//helper
import {
  initUpload,
  uploadManagerInit,
  uploadStartCall,
  injectUploadState,
} from '../../../../store/uploads/actionCreator'
import apiConfig from '../../../../config/apiConfig'
import { toggleRightSidenav } from '../../../../store/layout/actionCreator'
import { isLoggedInUserProjectAdmin } from '../../../../store/projects/helpers'
import RetryAssetView from './RetryAssetView'
import HeaderTabs from '../HeaderTabs'
import AHAssetTable from './AHAssetTable'
import AssetFilters from './AssetFilters'

const styles = makeStyles((theme) => ({
  root: {
    padding: '0 8px',
    position: 'relative',
  },
  LIText: {
    flex: 'none !important',
    minWidth: '50%',
    width: '100px',
    overflowWrap: 'break-word',
  },
  LISecondaryAction: {
    display: 'grid',
    minWidth: '46%',
    gridTemplateColumns: '50px 30px auto auto',
    gridTemplateRows: '0px 0px 15px 0px',
    '-ms-grid-columns': '50px 30px auto auto',
    '-ms-grid-rows': '0px 0px 15px 0px',
    marginLeft: '10px',
  },
  TaskIconButton: {
    height: '22px !important',
    width: 'auto !important',
    padding: 0,
  },
  card: {
    margin: '4px 0px 0px 0px',
  },
  assetCardHeader: {
    background: 'none',
    borderBottom: '1px solid #a9a9a970',
  },
  noDataText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50vh',
  },
  whiteColor: {
    color: useTheme().palette.common.white,
  },
  media: {
    height: 'auto',
    maxWidth: '200px !important',
    maxHeight: '300px !important',
    margin: 'auto',
  },
  cardContent: {
    fontSize: '12px',
    '& span': {
      display: 'inline',
    },
  },
  assetDivider: {
    width: '100%',
    maxWidth: '100%',
    padding: '20px',
  },
  assetCardContent: {
    height: '100%',
    minHeight: '700px',
    overflow: 'auto',
    padding: '0px',
    '& > *': {
      borderRadius: 'unset',
      border: '0px',
    },
    paddingBottom: '0px !important',
  },
  actions: {
    display: 'flex',
  },
  Typography: {
    fontSize: '12px',
    color: '#8D8D8D',
    paddingRight: '3px',
    fontWeight: 500,
  },
  progress: {
    color: '#cab34f',
  },
  progressBar: {
    color: '#000',
    marginTop: 10,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: useTheme().transitions.create('transform', {
      duration: useTheme().transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [useTheme().breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  table: {
    backgroundColor: 'red',
  },
  tr: {
    height: '42px !important',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  uploadOverlay: {
    width: 'auto',
    height: '100%',
    background: 'rgba(13, 70, 160, .1)',
    zIndex: 10,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    border: '2px dashed #0d46a0',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: '20%',
  },
  largeIcon: {
    fontSize: 300,
    color: 'white',
  },
  ionContainer: {
    position: 'relative',
  },
  iconContent: {
    top: '0',
    left: '0',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  publishIcon: {
    color: '#0d46a0',
    fontSize: 50,
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    animationDuration: '1s',
    animationName: 'wobble-vertical',
    transform: 'perspective(1px) translateZ(0)',
  },
  editIcon: {
    marginRight: '10px',
    color: '#717171',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
  },
  dragInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    top: 10,
    color: '#8D8D8D',
    '& > svg': {
      fontSize: 35,
      zIndex: 1,
    },
  },
  dragInfoPosition: {
    position: 'absolute',
  },
  emptyProject: {
    textAlign: 'center',
    width: '100%',
    paddingTop: '30px',
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    '& > svg': {
      fontSize: 50,
      color: '#8D8D8D',
    },
  },
  width200: {
    width: '200px',
  },
  dropzone: {
    width: '100%',
    position: 'relative',
  },
  debugButton: {
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
  },
  failedAssetsLayout: {
    backgroundColor: '#fff !important',
    width: '75vw',
  },
}))

function AssetDashboard(props) {
  const { projectId = '' } = props
  const {
    projects: {
      projectAssetCount = Number(0),
      projectData = {},
      loading = {},
      isUploadTaskExists = false,
      pagination = {},
      filters = {},
      projectAssets = [],
      revisionAssetsMap = {},
      creativeAssetStatus = 'All',
    } = {},
    assetsSearch: {
      deleteAssetInProgress = false,
      downloadInProgress = false,
      isMetadataDownloadInitiated = false,
    } = {},
    auth: {
      memberOf = [],
      lanId = '',
      isAdGroupSuperAdmin = false,
      isLibrarian = false,
    } = {},
    layout: { isToggleRightSidenav = false, rightSideNavComponent } = {},
  } = useSelector((state) => state) || {}
  const { project_name = '', project_admins = [] } = projectData
  const isInternalProjectAdmin = isLoggedInUserProjectAdmin(
    lanId,
    project_admins,
  )

  const classes = styles()
  const dispatch = useDispatch()
  const envConfig = useEnv() || {}
  const { REACT_APP_ROLES_CONFIG = {}, USE_ENTERPRISE_UPLOADS_APIS = false } =
    envConfig
  const params = useParams()
  const navigate = useNavigate()
  const [downloadAssets, setDownloadAssets] = useState({})
  const [metaDataSingleAsset, setMetaDataSingleAsset] = useState({})
  const [downloadAssetType, setDownloadAssetType] = useState('')
  const [acceptedFiles, setAcceptedFiles] = useState('')
  const [
    displayDeleteAssetConfirmationModal,
    setDisplayDeleteAssetConfirmationModal,
  ] = useState(false)
  const [
    displayApproveAssetConfirmationModal,
    setdisplayApproveAssetConfirmationModal
  ] = useState(false)
  const [isDownloadInitiated, setIsDownloadInitiated] = useState(false)
  const [isDeleteInitiated, setIsDeleteInitiated] = useState(false)
  const [shouldPoll, setShouldPoll] = useState(false)
  const [hasMorePages, setHasMorePages] = useState(true)
  const [showPreviewMetaPage, setShowPreviewMetaPage] = useState(false)
  const [uploadCount, setUploadCount] = useState(0)
  const [files, setFiles] = useState([])
  const [uploadFiles, setUploadFiles] = useState([])
  const [failedAssetsDrawer, setFailedAssetsDrawer] = useState(false)

  const selectedAssetCount = Object.keys(downloadAssets).length
  const breadCrumb = constructBreadCrumbObj(project_name)
  const { projectId: routerProjectId } = params

  useEffect(() => {
    if (!downloadInProgress) {
      startTransition(() => {
        setIsDownloadInitiated(false)
        setDownloadAssets({})
      })
    } else if (!deleteAssetInProgress) {
      startTransition(() => {
        setIsDeleteInitiated(false)
        setDownloadAssets({})
        setDisplayDeleteAssetConfirmationModal(false)
      })
    }
  }, [deleteAssetInProgress, downloadInProgress])

  useEffect(() => {
    dispatch(setSelectedAssets({ ...downloadAssets }))
  }, [downloadAssets])

  useEffect(() => {
    setDefaultPagination()
    const nameOfPage = params.pageName ? params.pageName : ''
    const idOfProject = params.projectId ? params.projectId : projectId
    dispatch(getProjectAssetsWithFilters(idOfProject))
    if (nameOfPage === 'metaPage' && params['*']) {
      setShowPreviewMetaPage(true)
    }
  }, [])

  useEffect(() => {
    injectUploadState({
      isEnterpriseApi: true,
      resetLastFile: true,
    })
  }, [routerProjectId])

  useEffect(() => {
    injectUploadState({
      isEnterpriseApi: USE_ENTERPRISE_UPLOADS_APIS,
      uploadSuccessCallback: (uploadCount) => {
        onUploadSuccessCallback(uploadCount)
      },
      uploadFailedCallback: () => {
        dispatch(uploadStartCall({ uploadFiles: uploadFiles }))
      },
    })
    if (uploadFiles.length) {
      dispatch(
        uploadStartCall({
          uploadFiles: uploadFiles,
          uploadCount: uploadCount,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFiles])

  const onUploadSuccessCallback = (successUploadCount) => {
    setShouldPoll(true)
    if (successUploadCount <= 10) {
      dispatch(
        uploadStartCall({
          uploadFiles: uploadFiles,
        }),
      )
    }
  }

  const downloadMetadata = (e) => {
    const downloadAssetType = e.currentTarget.id
    setDownloadAssetType(downloadAssetType)
    if (!isEmpty(downloadAssets) && Object.values(downloadAssets)?.length) {
      let selected_file_ids = Object.values(downloadAssets).map(
        (asset) => asset?.assetId,
      )
      dispatch(fetchMetadatadetails(selected_file_ids))
    }
  }
  const onDrop = (files) => {
    const fileNames = files.map(file => file.name) || []
    fileNames.length && dispatch(getProjectRevisionAssets(projectId, fileNames))
    setFiles(files)
  }

  const checkUploadProgress = (projectAssets = []) => {
    const assetInProgress = projectAssets.find(
      (assetDetails) => assetDetails.status !== 'COMPLETE',
    )
    if (!assetInProgress && shouldPoll) {
      setShouldPoll(false)
    }
  }

  const onAssetSelection = (isChecked, assetSelectionData = {}) => {
    const {
      assetId = '',
      assetMasterId = '',
      fileName = '',
      renderUrl = '',
      imageMasterId = '',
      render_urls = {},
      creativeAssetStatus = '',
      status = ''
    } = assetSelectionData
    const assetPresentInDownloadAssets = Object.keys(downloadAssets).find(
      (id) => id === assetId,
    )
    if (isChecked && !assetPresentInDownloadAssets) {
      // if the current asset is not already selected add it to downloadAssets
      const downloadAsset = {
        [assetId]: {
          assetId,
          assetMasterId,
          fileName,
          filePath: renderUrl,
          imageMasterId,
          render_urls,
          creativeAssetStatus,
          status
        },
      }
      const updatedDownloadAssets = Object.assign(
        {},
        downloadAssets,
        downloadAsset,
      )
      setDownloadAssets(updatedDownloadAssets)
    } else {
      // remove from downloadAssets
      const updatedDownloadAssets = Object.assign(
        {},
        removeFromObjectByKey(downloadAssets, assetId),
      )
      setDownloadAssets(updatedDownloadAssets)
    }
  }

  const updateHasPagenation = (hasMorePages) => {
    startTransition(() => {
      setHasMorePages(hasMorePages)
      setDownloadAssets({})
    })
  }

  const onClearSelection = () => {
    startTransition(() => {
      setDownloadAssets({})
      if (isToggleRightSidenav) {
        dispatch(toggleRightSidenav(rightSideNavComponent, false))
      }
    })
  }

  const handleSelectAll = (downloadAssets = {}) => {
    setDownloadAssets(downloadAssets)
  }

  const handleOpenMetaPage = (projectId, assetUoiId) => {
    const newUrl = '/project/' + projectId + '/metaPage/' + assetUoiId
    startTransition(() => {
      setShowPreviewMetaPage(true)
    })
    navigate(newUrl, {})
  }

  const handleCloseMetaPage = (projectId) => {
    const newUrl = '/project/' + projectId + '/assets'
    startTransition(() => {
      setShowPreviewMetaPage(false)
      setMetaDataSingleAsset({})
    })
    navigate(newUrl, {})
  }

  const onDownloadClicked = (event) => {
    const downloadAssetType = event?.currentTarget?.id || ''
    startTransition(() => {
      setIsDownloadInitiated(true)
      setDownloadAssetType(downloadAssetType)
    })
    startDownload(
      { ...downloadAssets },
      dispatch,
      downloadAssetType,
      lanId,
      pagination.page,
    )
    dispatch(setDownloadAssetInProgress(true))
  }

  const closeBulkActionBar = () => {
    setDownloadAssets({})
  }

  const onStatusChangeCallback = (response = '', responseType = '') => {
    if (responseType === 'success') {
      dispatch(
        showNotification(
          true,
          'Successfully changed Asset(s) status to Approved',
          'success',
          3000,
        ),
      )
      setDownloadAssets({})
      dispatch(getProjectAssetsWithFilters(projectId, pagination, filters))
    } else {
      let assetErrorMessages = bulkEditErrorMessageBuilder(response)
      if (assetErrorMessages) {
        dispatchNotification(true, assetErrorMessages, 'error', 50000)
      }
    }
  }

  const onStatusChange = () => {
    setdisplayApproveAssetConfirmationModal(true)
    const payload = []

    if (!isEmpty(downloadAssets)) {
      const selectedAssetIds = Object.values(downloadAssets).reduce(
        (selectedAssets, { assetId = '' }) => {
          if (assetId) {
            selectedAssets.push(assetId)
          }
          return selectedAssets
        },
        [],
      )
      if (selectedAssetIds.length > 0) {
        selectedAssetIds.forEach((assetId) => {
          payload.push({
            asset_id: assetId,
            update_data: { creative_asset_status: 'Approved' },
          })
        })

        dispatch(updateBulkMetadata(
          payload,
          '',
          onStatusChangeCallback
        ))
      }

      setdisplayApproveAssetConfirmationModal(false)
    }
  }

  const onDeleteAssets = () => {
    if (!isEmpty(downloadAssets)) {
      setIsDeleteInitiated(true)
      const selectedAssetIds = Object.values(downloadAssets).reduce(
        (selectedAssets, { assetMasterId = '' }) => {
          if (assetMasterId) {
            selectedAssets.push(assetMasterId)
          }
          return selectedAssets
        },
        [],
      )
      if (selectedAssetIds.length > 0) {
        dispatch(setDeleteAssetInProgress(true))
        dispatch(
          deleteRevisions(
            { asset_master_uoi_ids: selectedAssetIds },
            getDeleteAssetNotificationMessage(selectedAssetIds.length),
            projectId,
            pagination,
          ),
        )
        setDisplayDeleteAssetConfirmationModal(false)
      }
    }
  }

  const dispatchToggleSidenav = () => {
    dispatch(toggleRightSidenav('Add Metadata'))
  }

  const toggleDeleteAssetsConfirmation = (displayModal = true) => {
    setDisplayDeleteAssetConfirmationModal(displayModal)
  }

  const toggleApproveAssetsConfirmation = (displayModal = true) => {
    setdisplayApproveAssetConfirmationModal(displayModal)
  }

  const handleSetProjectCoverImg = () => {
    const projectId = params && params.projectId ? params.projectId : projectId
    const key = Object.keys(downloadAssets)
    const imageMasterId = downloadAssets[key].imageMasterId
    dispatch(
      setProjectCoverImage(projectId, imageMasterId, () => {
        startTransition(() => {
          setDownloadAssets({})
        })
      }),
    )
  }

  const upload = (files = []) => {
    let projectDetails = {
      project_name: projectData.project_name,
      project_uuid: projectData.project_uuid,
    }
    let filesObj = {}
    let chunkSize = 5 * 1024 * 1024
    files.forEach(function (singleFile) {
      const totalNumberOfChunks = Math.ceil(singleFile.size / chunkSize)
      const singleFileObj = {
        completedChunks: 0,
        chunkSize: chunkSize,
        totalNumberOfChunks: totalNumberOfChunks,
        uploadStatus: 'Not Started',
        project_name: projectData.project_name,
        project_uuid: projectData.project_uuid,
        confidential: projectData.confidential,
        uoi_id: projectData.uoi_id,
        channel: projectData.channel,
        currentChunk: 0,
        partList: [],
        remainingChunks: getChunkNumbers(totalNumberOfChunks),
      }
      Object.assign(singleFile, singleFileObj)
      filesObj[singleFile.name] = {
        name: singleFile.name,
        completedChunks: singleFile.completedChunks,
        totalNumberOfChunks: singleFile.totalNumberOfChunks,
        uploadStatus: singleFile.uploadStatus,
      }
    })
    files.sort((a, b) => a.size - b.size)
    dispatch(initUpload(filesObj, projectDetails))
    startTransition(() => {
      setFiles([])
      setUploadFiles([...uploadFiles, ...files])
      dispatch(uploadManagerInit(files))
    })
  }

  const assetsListWidget = () => {
    const isAuthorizedUser = isAuthorizedToPage(
      'projects',
      memberOf,
      REACT_APP_ROLES_CONFIG,
    )
    const disableUpload = !isUploadTaskExists && !isAuthorizedUser
    const selectedAssets = Object.keys(downloadAssets)

    return (
      <Card className={classes.card} style={{ minHeight: '98.5%' }}>
        <CardHeader
          data-cy="assetsCard"
          title={
            projectAssetCount ? `All Assets ${projectAssetCount}` : 'All Assets'
          }
          action={
            <div style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}>
              {isAdGroupSuperAdmin ? (
                <Button
                  variant="contained"
                  color="error"
                  className={classes.debugButton}
                  onClick={() => setFailedAssetsDrawer(true)}
                >
                  Retry Assets
                </Button>
              ) : (
                <></>
              )}
              {disableUpload ? (
                <></>
              ) : (
                <ClassificationUploadDialog
                  projectData={projectData}
                  acceptedFiles={acceptedFiles}
                  memberOf={memberOf}
                  onDropFiles={(files) => onDrop(files)}
                />
              )}
            </div>
          }
          className={classes.assetCardHeader}
        />
        <CardContent className={classes.assetCardContent}>
          <AssetFilters />
          <AHAssetTable
            projectId={projectId}
            selectedAssetDetails={downloadAssets}
            checkUploadProgress={checkUploadProgress}
            onAssetSelection={onAssetSelection}
            selectedAssets={selectedAssets}
            updateHasPagenation={updateHasPagenation}
            handleSelectAll={handleSelectAll}
            handleOpenMetaPage={handleOpenMetaPage}
            handleCloseMetaPage={handleCloseMetaPage}
            showPreview={showPreviewMetaPage}
            metaDataSingleAsset={metaDataSingleAsset}
          />

        </CardContent>
      </Card>
    )
  }

  const ifWipSelected = validateIfAssetsAreProcessedAndWip(downloadAssets)
  const wipProcessedCount = getProcessedWipCount(downloadAssets)

  return (
    <div>
      <HeaderTitle title={generateBreadCrumb(breadCrumb)} icon="Project" />
      <Helmet>
        <title>{generateBreadCrumb(breadCrumb, true)}</title>
      </Helmet>
      <HeaderTabs activeTabIndex={1} id={projectId} />
        <div className={classes.root}>
          {project_name !== '' && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {assetsListWidget()}
              </Grid>
            </Grid>
          )}
        </div>
      {failedAssetsDrawer && (
        <Drawer
          anchor={'right'}
          open={failedAssetsDrawer}
          onClose={() => setFailedAssetsDrawer(false)}
          classes={{
            paper: classes.failedAssetsLayout,
          }}
        >
          <RetryAssetView
            allProjectAssets={projectAssets}
            parentClasses={classes}
            projectName={project_name}
          />
        </Drawer>
      )}
      {files.length > 0 && !loading.revisionAssets && (
        <UploadDialog
          files={files}
          memberOf={memberOf}
          open={true}
          onClose={() => setFiles([])}
          onUpload={(files) => upload(files)}
          AllAssets={projectAssets}
          revisionAssetsMap={revisionAssetsMap}
        />
      )}
      {selectedAssetCount > 0 && (
        <BulkActionBar
          closeBulkActionBar={closeBulkActionBar}
          selectedAssets={downloadAssets}
          noOfSelectedItems={selectedAssetCount}
          onDownloadClicked={(event) => onDownloadClicked(event)}
          onStatusChange={toggleApproveAssetsConfirmation}
          isWipSelected={
            ifWipSelected &&
            (isAdGroupSuperAdmin || isLibrarian || isInternalProjectAdmin)
          }
          onDeleteAssets={toggleDeleteAssetsConfirmation}
          enableBoardsDialog={false}
          enableDeleteAsset={
            isAdGroupSuperAdmin || isLibrarian || isInternalProjectAdmin
          }
          isDownloadInitiated={isDownloadInitiated}
          downloadAssetType={downloadAssetType}
          handleSetProjectCoverImg={handleSetProjectCoverImg}
          enableSetCoverImage={true}
          onClearSelection={onClearSelection}
          isAdminOrLibrarian={isAdGroupSuperAdmin || isLibrarian}
          onDownloadMetadata={downloadMetadata}
          isMetadataDownloadInitiated={isMetadataDownloadInitiated}
          toggleRightSidenav={() => dispatchToggleSidenav()}
          isToggleRightSidenav={isToggleRightSidenav}
          rightSideNavComponent={rightSideNavComponent}
        />
      )}
      {displayDeleteAssetConfirmationModal && (
        <DeleteAssetsDailog
          noOfSelectedAssets={selectedAssetCount}
          deleteAssets={onDeleteAssets}
          toggleConfirmationModal={toggleDeleteAssetsConfirmation}
          dialogOpen={displayDeleteAssetConfirmationModal}
        />
      )}
      {
        displayApproveAssetConfirmationModal && (
          <ApproveAssetsDialog
            noOfSelectedAssets={selectedAssetCount}
            wipProcessedCount={wipProcessedCount}
            approveAssets={onStatusChange}
            toggleConfirmationModal={toggleApproveAssetsConfirmation}
            dialogOpen={displayApproveAssetConfirmationModal}
          />

        )
      }

    </div>
  )
}


export default AssetDashboard

import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Alert,
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import './styles/styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchUserPreferences,
  updateUserPreferences,
} from '../../store/preferences/actionCreator'
import { BetaAnalytics, trackEventWithCustomInteraction } from '../../analytics/betaSearch'

const guidelines = [
  {
    label: 'Input text prompts guidelines',
    data: [
      'No Guest Data: Do not use any guest data in prompts.',
      'Purpose: Use prompts solely for creating metadata, product information and optimized search capabilities within AssetHub.',
      'Ethics and Policies: Ensure prompts align with Target’s Code of Ethics, Enterprise Policies, and GenAI Terms of Use.',
      'Personal Information: Do not use prompts based on personal information (PI) or protected characteristics (ethnicity, race, disability). If inadvertently obtained, have a remediation plan to delete and not use/save such outputs.',
    ],
  },
  {
    label: 'Output Usage Restrictions',
    data: [
      'AI Training: Do not use outputs to create, train, or improve other AI or machine learning tools.',
      'Hiring Decisions: Information obtained cannot be used for hiring or casting decisions.',
      'Approval: Obtain approval from Target Law and Privacy before using outputs for other purposes or systems.',
    ],
  },
  {
    label: 'Other Use Cases',
    data: [
      'Specific Use: Guidance is specific to using ChatGPT-4 (default) for Metadata Enrichment in AssetHub.',
      'Alignment: Ensure alignment with leaders for alternate use cases or additional tools.',
      'Consultation: Consult with Target’s GenAI advisory partners for additional guidance.',
    ],
  },
]

const links = {
  'Code of Ethics':
    'https://targetonline.sharepoint.com/sites/Ethics/SitePages/Code-of-Ethics.aspx',
  'Enterprise Policies':
    'https://targetonline.sharepoint.com/sites/CorporatePolicies/SitePages/All-Policies.aspx',
  'GenAI Terms of Use':
    'https://targetonline.sharepoint.com/sites/PrivacyRAI/SitePages/Terms-of-Use.aspx',
  'Target Law': 'mailto:ai.law@target.com',
  Privacy: 'mailto:lindah.mazarura@target.com',
}

const renderTextWithLinks = (text) =>
  text
    .split(
      /(Code of Ethics|Enterprise Policies|GenAI Terms of Use|Target Law|Privacy|GenAI Terms of Use)/g,
    )
    .map((part, index) =>
      links[part] ? (
        <a
          key={index}
          href={links[part]}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#007bff' }}
        >
          {part}
        </a>
      ) : (
        part
      ),
    )

const GenAITermsCondition = ({ open, onClose }) => {
  const dispatch = useDispatch()
  const [consent, setConsent] = useState(false)
  const [isCloseEnabled, setIsCloseEnabled] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const userPreferences = useSelector((state) => state.userPreferences)
  const auth = useSelector((state) => state.auth)
  const { custom_preference = {} } = userPreferences.preferences
  const { genai_preference = false } = custom_preference  

  const handleClose = (event, reason) => {
    if (
      !isCloseEnabled &&
      (reason === 'backdropClick' || reason === 'escapeKeyDown')
    ) {
      return
    }
    onClose()
  }

  const handleAccept = () => {
    setConsent(true)
    setIsCloseEnabled(true)
    let payload = {
      ...userPreferences,
      preferences: {
        ...userPreferences.preferences,
        custom_preference: {
          ...userPreferences.preferences.custom_preference,
          genai_preference: true,
        }
      },
    }
    dispatch(updateUserPreferences(payload))
    trackEventWithCustomInteraction({
      auth: auth,
      trackingData: {
        eventType: BetaAnalytics.event.ACCEPT_AI_TERMS,
        eventName: BetaAnalytics.event.ACCEPT_AI_TERMS,
      },
    })
    onClose()
  }

  const handleReject = () => {
    if (consent) {
      setShowWarning(true)
    }
  }

  const handleAcknowledgeChange = (event) => {
    setConsent(event.target.checked)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      className="ai-terms-popup"
    >
      <DialogTitle className="title">
        AssetHub GenAI Terms & Conditions
        {genai_preference && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: isCloseEnabled
                ? (theme) => theme.palette.grey[500]
                : '#ccc',
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>
        {showWarning && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            <strong>You clicked on “Reject”</strong>
            <br />
            You won't have access to AssetHub's AI Playground until you
            acknowledge that you accept the terms. If you need further
            assistance, please ping us on Slack at #assethub-help.
          </Alert>
        )}
        <Typography variant="body2">
          {guidelines.map((section, index) => (
            <div key={index}>
              <strong className="sub-title">{section.label}</strong>
              <ul>
                {section.data.map((item, idx) => (
                  <li key={idx} style={{ color: '#000' }}>
                    <strong className="body">{item.split(':')[0]}:</strong>
                    <span>{renderTextWithLinks(item.split(':')[1])}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Typography>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={consent || genai_preference}
              onChange={handleAcknowledgeChange}
            />
          }
          label="I acknowledge the terms and conditions"
        />
        <div>
          <Button
            color="error"
            variant="outlined"
            sx={{ marginLeft: 'auto' }}
            onClick={handleReject}
            disabled={!consent || genai_preference}
          >
            Reject
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAccept}
            className="accept"
            disabled={!consent || genai_preference}
          >
            Accept
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default GenAITermsCondition

import { mapKeys, isEmpty, omitBy } from 'lodash'
import { separateFilenameExtension, setInBrowserStorage } from './UtilityHelper'
import {
  ASSET_BASIC_REVIEW_COMPLETED,
  ASSET_REVIEW_PENDING,
  NOTIFICATION_SUCCESS_ASSET_DELETE,
} from '../constants/metadata'
import {
  INTERNAL_USAGE_CHANNEL_LABEL,
  OTHER_CHANNEL_LABEL,
} from '../constants/usageRights'
import { formatCSTDate } from './dateHelper'

export const getMetadataContract = function (assetMetadata = {}) {
  const {
    metadata: metadataInfo = {},
    location_info: locationInfo = [],
    librarian_review_status = ASSET_BASIC_REVIEW_COMPLETED,
    uploaded_by = '',
    uploaded_date = '',
    revision_number = Number(1),
    asset_id = '',
    original_asset_id = '',
    source = '',
    creative_asset_status = 'All',
  } = assetMetadata
  const {
    file_metadata = {},
    usage_info = [],
    creative_usage_instructions: creativeUsageInstructions = {},
    item_info: productInfo = [],
    image_data = {}, //needs to check
    asset_descriptions: assetDescription = {},
    project_info = {},
    asset_classification = {},
    user_tags = {},
    ai = {},
    video = {},
  } = metadataInfo || {}
  const {
    file_name = '',
    file_size_mb = '',
    pixel_dimensions = '',
    resolution = '',
    created_by = '',
    person_shown = null,
    created_date = null, //needs to check
    keywords = [], //needs to check
  } = file_metadata || {}
  const assetDetails = {
    fileName: file_name || '',
    fileSize: file_size_mb,
    pixelDimensions: pixel_dimensions,
    resolution: resolution,
    uploadDate: uploaded_date,
    uploadedBy: uploaded_by,
    creator: created_by,
    revisionNumber: revision_number,
    personShown: person_shown || []
  }

  const usageInformation = []

  usage_info?.map((usageItem = {}) => {
    const {
      drm_id = '',
      rights_holder_name = '',
      rights_holder_type = '',
      expiration_date = '',
      available_channels = '',
      buyer = '',
      available_territories = '',
      creation_date = '',
      project_id = [],
      terms = '',
      custom_usage_rights = false,
      custom_usage_rights_language = '',
      usage_rights_language = '',
    } = usageItem
    if (Object.keys(usageItem).length) {
      usageInformation.push({
        drmId: drm_id,
        rightsHolder: rights_holder_name,
        rightsHolderType: rights_holder_type,
        expirationDate: expiration_date,
        availableChannels: available_channels,
        buyer: buyer,
        availableTerritories: available_territories,
        createdDate: creation_date,
        projectId: project_id?.[0] ? project_id[0] : '',
        terms: terms ?? '',
        customUsageRights: custom_usage_rights,
        customUsageRightsLanguage: custom_usage_rights_language,
        usageRightsLanguage: usage_rights_language,
      })
    }
    return true
  })
  const {
    do_not_use = false,
    instructions = '',
    additional_directions = '',
    contacts = [],
  } = creativeUsageInstructions || {}

  const usageInstructions = {
    doNotUse: do_not_use,
    instructions: instructions,
    additionalDirections: additional_directions,
    contacts: contacts,
  }
  const productInformation = []
  productInfo?.map((productData = {}) => {
    if (Object.keys(productData).length) {
      const {
        dpci = '',
        tcin = '',
        brand = '',
        product_description = {},
        barcode = '',
        is_non_product = false, //needs to check
      } = productData
      const { title = '' } = product_description || {}
      productInformation.push({
        dpci: dpci,
        tcin: tcin,
        brandName: brand,
        productDescription: title,
        barcode: barcode,
        nonProduct: is_non_product || false,
      })
    }
    return true
  })
  const storeInformation = []
  locationInfo?.map((storeDetail = {}) => {
    if (Object.keys(storeDetail).length) {
      const {
        property_number: storeNumber = '',
        property_name: propertyName = '',
        street_address: streetAddress = '',
        city_name: city = '',
        store_location_description: storeLocationDescription = [],
      } = storeDetail
      storeInformation.push({
        storeNumber,
        propertyName,
        streetAddress,
        city,
        storeLocationDescription,
      })
    }
    return true
  })
  const assetDesKeyMappers = {
    subject: 'assetSubject',
    shot_types: 'shotType',
    keywords: 'keywords',
  }
  const assetDescriptions = mapKeys(
    assetDescription,
    (value, key) => assetDesKeyMappers[key] || key,
  )
  const {
    secondary_classification = '',
    asset_type = '',
    classification_id = '',
  } = asset_classification || {}
  const assetClassifications = {
    classifications_name: secondary_classification,
    asset_type: {
      name: asset_type,
      id: classification_id,
    },
  }

  let projectDetails = {}
  if (project_info) {
    const { project_run_date = '', campaign_description = [] } = project_info
    projectDetails = {
      ...project_info,
      runDate: project_run_date,
      campaign: (campaign_description || [])[0] || '',
    }
  }

  return {
    assetId: asset_id,
    contentId: assetMetadata.content_id,
    assetMasterId: original_asset_id,
    assetSource: source,
    assetDetails,
    usageInformation,
    usageInstructions,
    productInformation,
    storeInformation,
    assetDescriptions,
    assetClassifications,
    userTags: user_tags,
    ai: ai,
    video,
    projectDetails,
    librarianReviewStatus: librarian_review_status,
    creativeAssetStatus: creative_asset_status,
  }
}

export const getSaveMetadataContract = function (assetMetadata = {}) {
  const {
    assetId,
    contentId,
    assetDetails,
    usageInformation,
    usageInstructions = {},
    productInformation,
    storeInformation,
    assetDescriptions,
    assetClassifications = {},
    librarianReviewStatus = ASSET_BASIC_REVIEW_COMPLETED,

    userDefinedBrandName = null,
  } = assetMetadata

  const modifiedLibrarianReviewStatus = [
    ASSET_REVIEW_PENDING,
    'REVIEW_PENDING',
  ].includes(librarianReviewStatus)
    ? ASSET_BASIC_REVIEW_COMPLETED
    : librarianReviewStatus
  const assetNameWithoutExt =
    (separateFilenameExtension(assetDetails.fileName) || {})?.fileName || ''
  const storeIds = []
  storeInformation.forEach((storeInfo = {}) => {
    if (Object.keys(storeInfo).length) {
      storeIds.push({
        id: storeInfo.storeNumber || '',
        descriptions: storeInfo.storeLocationDescription || [],
      })
    }
  })

  const { asset_type: assetType = {} } = assetClassifications
  const usageRightsData = usageInformation
    ?.map((a) => a.drmId)
    ?.filter((obj) => !isEmpty(obj))
  const tcinData = productInformation
    ?.map((a) => a.tcin)
    ?.filter((obj) => !isEmpty(obj))
  const dpciData = productInformation
    ?.map((a) => a.dpci)
    ?.filter((obj) => !isEmpty(obj))

  return [
    {
      asset_id: assetId,
      update_data: {
        file_name: assetDetails?.fileName || '',
        created_by: assetDetails?.creator || '',
        person_shown: assetDetails?.personShown?.filter(item => item !== '') || [],
        creative_usage_rights: {
          do_not_use: usageInstructions.doNotUse,
          instructions: usageInstructions.instructions,
          additional_directions: usageInstructions.additionalDirections,
        },
        asset_descriptions: {
          keywords: assetDescriptions.keywords,
          subject: assetDescriptions.assetSubject,
          shot_types: assetDescriptions.shotType,
        },
        librarian_review_status: modifiedLibrarianReviewStatus,
        classification_id: assetType.id,
        drm_ids: {
          data: usageRightsData ?? [],
          action_type: 'MERGE',
        },
        tcins: {
          data: tcinData ?? [],
          action_type: 'MERGE',
        },
        dpcis: {
          data: dpciData ?? [],
          action_type: 'MERGE',
        },
        user_defined_brand_name: userDefinedBrandName,
      },
      // content_id: contentId, //TODO CHECK
      // store_ids: storeIds, //TODO CHECK future
    },
  ]
}

export const getSaveBulkMetadataContract = function (bulkMetadata = {}) {
  const {
    selectedAssets = {},
    usageInformation,
    usageInstructions = {},
    productInformation,
    storeInformation,
    assetDescriptions,
    assetClassifications = {},
    creativeAssetStatus,
    assetDetails = {},
    librarianReviewStatus = ASSET_BASIC_REVIEW_COMPLETED,
    userDefinedBrandName = null,
  } = bulkMetadata

  const storeIds = []
  storeInformation.forEach((storeInfo = {}) => {
    if (Object.keys(storeInfo).length) {
      storeIds.push({
        id: storeInfo.storeNumber || '',
        description: storeInfo.storeLocationDescription || [],
      })
    }
  })

  const bulkUpdateIds = []

  Object.keys(selectedAssets || {})?.map((key) => {
    if (selectedAssets[key]) {
      bulkUpdateIds.push({
        asset_id: selectedAssets[key].assetId,
        content_id: selectedAssets[key].contentId,
      })
    }
    return true
  })

  const { asset_type: assetType = {} } = assetClassifications
  const usageRightsData = usageInformation
    ?.map((a) => a.drmId)
    .filter((obj) => !isEmpty(obj))
  const tcinData = productInformation
    ?.map((a) => a.tcin)
    ?.filter((obj) => !isEmpty(obj))
  const dpciData = productInformation
    ?.map((a) => a.dpci)
    ?.filter((obj) => !isEmpty(obj))
  const finalPayload = Object.keys(bulkUpdateIds)?.map((assetId) => ({
    asset_id: bulkUpdateIds[assetId]?.asset_id,
    update_data: {
      created_by: assetDetails?.creator || '',
      person_shown: assetDetails?.personShown?.filter(item => item !== '') || [],
      creative_usage_rights: {
        do_not_use: usageInstructions.doNotUse,
        instructions: usageInstructions.instructions,
        additional_directions: usageInstructions.additionalDirections,
      },
      asset_descriptions: {
        keywords: assetDescriptions.keywords,
        subject: assetDescriptions.assetSubject,
        shot_types: assetDescriptions.shotType,
      },
      librarian_review_status: librarianReviewStatus,
      classification_id: assetType?.id || '',
      drm_ids: {
        data: usageRightsData ?? [],
        action_type: 'APPEND',
      },
      tcins: {
        data: tcinData ?? [],
        action_type: 'APPEND',
      },
      dpcis: {
        data: dpciData ?? [],
        action_type: 'APPEND',
      },
      user_defined_brand_name: userDefinedBrandName,
    },
  }))
  return finalPayload
}

export const getStorageIdentifier = (identifier = '') => {
  const lsId =
    'ah_metadata_' + identifier.toLowerCase().replace(/ /g, '_') + '_collapse'
  return lsId
}

export const collapseCallback = (identifier, value) => {
  const lsKey = getStorageIdentifier(identifier)
  setInBrowserStorage(lsKey, value)
}

export const getDeleteAssetNotificationMessage = (assetCount = 0) =>
  NOTIFICATION_SUCCESS_ASSET_DELETE.replace('{assetCount}', assetCount)

export const bulkEditErrorMessageBuilder = (
  assetErrors = [],
  selectedAssets = {},
) => {
  let assetErrorMessages = ''

  if (assetErrors.length > 0) {
    assetErrors.forEach((asset) => {
      const assetName = selectedAssets[asset.asset_id].fileName
      if (assetName) {
        assetErrorMessages += `- ${assetName}: ${asset.message} <br>`
      }
    })
  }
  return assetErrorMessages
}

export const getAvailableChannelsInfo = (
  channels = [],
  isInternalUsage = false,
  isOtherChannelUsage = false,
) => {
  if (isInternalUsage) return INTERNAL_USAGE_CHANNEL_LABEL
  else if (isOtherChannelUsage) return OTHER_CHANNEL_LABEL
  else return (channels[0] || {}).label || ''
}

export const getUsageInfoDetailsInfo = (usageInformation = {}) => {
  const {
    availableTerritories = '',
    buyer = '',
    createdDate = '',
    projectId = '',
    rightsHolderType = '',
    terms = '',
  } = usageInformation
  const createdDateFormatted = formatCSTDate(createdDate, 'll') || ''
  return [
    { label: 'Buyer', value: buyer },
    { label: 'Rights holder type', value: rightsHolderType },
    { label: 'Available territories', value: availableTerritories },
    { label: 'Created Date', value: createdDateFormatted },
    { label: 'Project ID/Name', value: projectId },
    { label: 'Terms', value: terms },
  ]
}

export const getNonProductBrand = (productInformation = []) =>
  productInformation.find((productInfo) => {
    const { nonProduct = false } = productInfo
    if (nonProduct) return productInfo
  })

import { useState } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'

import {
  removeFilterFromPayload,
  updateSearchPayload,
} from '../../../store/search/searchSlice'
import { FILTER_IS_REUSABLE_KEY } from '../../../constants/search'
import CustomButtonGroup from '../../../components/CustomButtonGroup'
import { useSearchContext } from '../SearchContext'
import { BetaAnalytics } from '../../../../analytics/betaSearch'

const useStyles = makeStyles({
  reuseContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
  },
  reuseItem: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  reuseText: {
    color: '#8d8d8d'
  },
  checkbox: {
    '&.Mui-disabled': {
      color: '#00000061 !important',
    },
  },
})

function AssetReusableFilter({
  filterKey = '',
  closePopOver = () => { },
  availableFilters = {},
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { trackAnalyticsData = () => { } } = useSearchContext()

  const availabledFilteredValue =
    availableFilters?.[FILTER_IS_REUSABLE_KEY]?.[0] || ''
  const [reuseReady, setReuseReady] = useState(availabledFilteredValue === 'true')
  const [notReuseReady, setNotReuseReady] = useState(availabledFilteredValue === 'false')
  const onClickApply = () => {
    let reuseFilters = {}
    if (reuseReady) {
      reuseFilters[filterKey] = ['true']
      dispatch(updateSearchPayload({ filters: reuseFilters }))
      trackAnalyticsData({
        eventType: BetaAnalytics.event.ADD_FILTER,
        eventName: BetaAnalytics.event.FILTER_IS_REUSABLE,
        eventData: { key: FILTER_IS_REUSABLE_KEY, value: 'true' },
      })
    } else if (notReuseReady) {
      reuseFilters[filterKey] = ['false']
      dispatch(updateSearchPayload({ filters: reuseFilters }))
      trackAnalyticsData({
        eventType: BetaAnalytics.event.ADD_FILTER,
        eventName: BetaAnalytics.event.FILTER_IS_REUSABLE,
        eventData: { key: FILTER_IS_REUSABLE_KEY, value: 'false' },
      })
    } else {
      dispatch(removeFilterFromPayload([FILTER_IS_REUSABLE_KEY]))
      trackAnalyticsData({
        eventType: BetaAnalytics.event.REMOVE_FILTER,
        eventName: BetaAnalytics.event.FILTER_IS_REUSABLE,
        eventData: { key: FILTER_IS_REUSABLE_KEY },
      })
    }
    closePopOver()
  }

  return (
    <Grid container className={classes.reuseContainer}>
      <Grid container item className={classes.reuseItem}>
        <Grid item className={classes.reuseText}>
          Reuse Ready for Target.com?
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={reuseReady}
                onChange={(event) =>
                  setReuseReady(event.target.checked)
                }
                value={reuseReady}
                size="small"
                disabled={notReuseReady}
                className={classes.checkbox}
                data-cy={`reuseReady`}
              />
            }
            label={'Reuse Ready'}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={notReuseReady}
                onChange={(event) => setNotReuseReady(event.target.checked)}
                value={notReuseReady}
                className={classes.checkbox}
                disabled={reuseReady}
                size="small"
                data-cy={`notReuseReady`}
              />
            }
            label={'Not Reuse Ready'}
          />
        </Grid>
      </Grid>
      <Grid item>
        <CustomButtonGroup onClickApply={onClickApply} hideClearButton={true} />
      </Grid>
    </Grid>
  )
}

export default AssetReusableFilter

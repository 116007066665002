import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { showNotification } from '../notification/actionCreator'
import apiConfig from '../../config/apiConfig'

export const fetchTenantData = createAsyncThunk(
  'tenant/fetch',
  async (_, { dispatch }) => {
    try {
      const { data } = await axios.get(apiConfig.tenants.tenantsUrl)
      return data || []
    } catch (error) {
      dispatch(showNotification(true, 'Something went wrong', 'error'))
      console.error(error.response?.data || 'Something went wrong')
    }
  },
)

export const fetchProject = createAsyncThunk(
  'tenant/fetchProject',
  async (_, { dispatch, rejectWithValue }) => {
    const url = `${apiConfig.projects.projecTypeUrl}?key=${apiConfig.key}`
    try {
      const { data } = await axios.get(url)
      return Array.isArray(data) ? data : []
    } catch (error) {
      dispatch(showNotification(true, 'Failed to fetch projects', 'error')) // Show error notification
      return rejectWithValue(error.response?.data || 'Something went wrong')
    }
  }
)

export const submitTenantForm = createAsyncThunk(
  'tenant/submit',
  async (formData, { dispatch }) => {
    try {
      await axios.post(apiConfig.tenants.tenantsUrl, formData)
      dispatch(
        showNotification(true, 'Tenant form submitted successfully', 'success'),
      )
      dispatch(fetchTenantData())
    } catch (error) {
      dispatch(showNotification(true, 'Something went wrong', 'error'))
      console.error(error.response?.data || 'Something went wrong')
    }
  },
)

export const submitTenantReview = createAsyncThunk(
  'tenant/review',
  async (reviewData, { dispatch }) => {
    try {
      await axios.post(
        `${apiConfig.tenants.tenantsUrl}/tenant/review`,
        reviewData,
      )
      dispatch(fetchTenantData())
      dispatch(
        showNotification(true, 'Review submitted successfully', 'success'),
      )
    } catch (error) {
      dispatch(showNotification(true, 'Review submission failed', 'error'))
      console.error(error.response?.data || 'Something went wrong')
    }
  },
)
const tenantSlice = createSlice({
  name: 'tenant',
  initialState: { 
    loading: false, 
    error: null, 
    data: null, 
    projects: null, 
    loadingProjects: false 
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => action.type.startsWith('tenant/fetch') && !action.type.includes('fetchProject'),
        (state, action) => {
          if (action.type.endsWith('/pending')) {
            state.loading = true
            state.error = null
          } else if (action.type.endsWith('/fulfilled')) {
            state.loading = false
            state.data = action.payload 
          } else if (action.type.endsWith('/rejected')) {
            state.loading = false
            state.error = action.payload
          }
        }
      )
      .addMatcher(
        (action) => action.type.startsWith('tenant/fetchProject'),
        (state, action) => {
          if (action.type.endsWith('/pending')) {
            state.loadingProjects = true
          } else if (action.type.endsWith('/fulfilled')) {
            state.loadingProjects = false
            state.projects = action.payload 
          } else if (action.type.endsWith('/rejected')) {
            state.loadingProjects = false
            state.error = action.payload
          }
        }
      )
  },
})
export default tenantSlice.reducer
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  aiContainer: {
    display: 'flex',
    height: '90vh',
    flexDirection: 'row',
    marginTop:"3rem"
  },
  imageContainer: {
    width: '20%',
    padding: '10px 15px',
    borderRight: '1px solid #6153536b',
    overflow: 'scroll',
  },
  promptImages: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '85vh',
    overflow: 'scroll',
    background: '#a396a30d',
  },
  promptContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  imageDiv: {
    width: '120px',
    minHeight: '90px',
    padding: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  imageStyles: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    border: '1px solid #5e959280',
  },
  imageStylesClicked: {
    width: '105px',
    height: '105px',
    objectFit: 'contain',
    border: '1px solid #13605c',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  imageStylesSelected: {
    maxWidth: '150px',
    maxHeight: '150px',
    objectFit: 'contain',
    border: '1px solid #13605c',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  submitButton: {
    backgroundColor: '#329eb7',
    color: 'white',
    '&:disabled': {
      color: 'grey',
      backgroundColor: '#7f889994',
    },
    '&:hover': {
      backgroundColor: '#417c8a',
      color: 'white',
    },
  },
  promptTextArea: {
    width: '100%',
    minWidth: '60vw',
  },
  contextTextArea: {
    width: '100%',
    minWidth: '45vw',
  },
  assetInfo: {
    marginTop: '-10px',
  },
  promptHistory: {
    borderTop: '1px dashed #333333',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  sessionHistroyLabel: {
    padding: '0px 0px 10px 10px',
  },
  description: {
    fontWeight: '300',
    letterSpacing: '0.15px',
  },
  promptCategoryTabs: {
    backgroundColor: '#c0c6cd82',
  },
  historyContainer: {
    height: '50vh',
    overflow: 'scroll',
  },
  historyItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '15px 10px',
    backgroundColor: '#e6d6d61a',
    borderBottom: '1px dotted #81727278',
  },
  clipBoardLink: {
    verticalAlign: 'middle',
    paddingLeft: '5px',
  },
})

export default useStyles;
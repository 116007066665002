import isEmpty from 'lodash/isEmpty'
import { secondarySortFields } from '../constants/projectTask'
import {
  FUNCTIONAL_TEST,
  FUNCTIONAL_TEST_ADMIN_USER,
  FUNCTIONAL_TEST_USER_EMAIL,
  FUNCTIONAL_TEST_USER_LOGIN_ID,
} from '../constants/functionalTest'
import { AD_ROUNDEL_PARTNER } from '../constants/adGroups'

export const sortMyArray = function (
  myArray = [],
  myOrderBy,
  myOrder = 'desc',
) {
  if (myArray === null || myArray.length === 0) {
    return []
  }
  const orderedArray =
    myOrder === 'desc'
      ? myArray.sort((a, b) => (b[myOrderBy] < a[myOrderBy] ? -1 : 1))
      : myArray.sort((a, b) => (a[myOrderBy] < b[myOrderBy] ? -1 : 1))
  return orderedArray
}

export const sortByDate = function (
  myArray = [],
  myOrderBy = '',
  myOrder = 'desc',
) {
  if (myArray === null || myArray.length === 0) {
    return []
  }
  const orderedArray =
    myOrder === 'desc'
      ? myArray.sort(function (a, b) {
          let nameA = new Date(a[myOrderBy]).getTime()
          let nameB = new Date(b[myOrderBy]).getTime()
          return nameB < nameA ? -1 : 1
        })
      : myArray.sort(function (a, b) {
          let nameA = new Date(a[myOrderBy]).getTime()
          let nameB = new Date(b[myOrderBy]).getTime()
          return nameA < nameB ? -1 : 1
        })
  return orderedArray
}

export const sortByAssetCount = (boardList = [], order = 'desc') => {
  if (boardList === null || boardList.length === 0) {
    return []
  }
  const orderedArray =
    order === 'desc'
      ? boardList.sort(function (a, b) {
          return b.asset_count - a.asset_count
        })
      : boardList.sort(function (a, b) {
          return a.asset_count - b.asset_count
        })
  return orderedArray
}

export const getFromBrowserStorage = function (storageKey = '') {
  return window.localStorage.getItem(storageKey)
}

export const setInBrowserStorage = function (
  storageKey = '',
  storageValue = '',
) {
  window.localStorage.setItem(storageKey, storageValue)
}

export const separateFilenameExtension = function (fullFilename = '') {
  if (!fullFilename) {
    return {
      fileName: '',
      fileExt: '',
    }
  }
  const extensionIndex = fullFilename.lastIndexOf('.')
  const fileName =
    extensionIndex !== -1
      ? fullFilename.substr(0, extensionIndex)
      : fullFilename
  const fileExt =
    extensionIndex !== -1 ? fullFilename.substr(extensionIndex) : ''
  return {
    fileName,
    fileExt,
  }
}

export const getInitials = (fullName = '', delimiter = '.', maxlength = 2) => {
  const fullNameArray = fullName.split(delimiter)
  const initialsArray = []
  fullNameArray.forEach(function (name = '') {
    initialsArray.push(name.charAt(0))
  })
  return initialsArray.slice(0, maxlength).join('').toUpperCase()
}

export const generateBreadcrumb = (breadCrumbArr, title) =>
  breadCrumbArr
    .map((breadCrumb) => {
      if (breadCrumb.path) {
        return !title
          ? '<a style="text-decoration: none" href="' +
              breadCrumb.path +
              '">' +
              breadCrumb.title +
              '</a>'
          : breadCrumb.title
      } else {
        return breadCrumb.title
      }
    })
    .join(!title ? ' <span style="color: #ccc">/</span> ' : ' / ')

export const filterByField = (elementList = [], filterBy = '', filterText) => {
  if (filterText) {
    return elementList.filter((element) =>
      element[filterBy]
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase()),
    )
  }
  return elementList
}

export const filterArrayByInsensitiveField = (
  elementList = [],
  filterText = '',
) =>
  elementList.filter((element) =>
    element.toLowerCase().includes(filterText.toLowerCase()),
  )

export const removeFromObjectByKey = (dataObject = {}, id = '') => {
  delete dataObject[id]
  return dataObject
}

export const isSecondarySortField = (fieldName) =>
  secondarySortFields.indexOf(fieldName) !== -1

export const containsEmptyValue = (obj) => {
  if (typeof obj !== typeof {} || !obj) throw new TypeError()

  if (isEmpty(obj)) return true

  for (let key in obj) {
    if (!obj[key]) return true
  }

  return false
}

export const isFunctionalTestAdminUser = () => {
  const isAdmin =
    !!getFromBrowserStorage(FUNCTIONAL_TEST) &&
    !!getFromBrowserStorage(FUNCTIONAL_TEST_ADMIN_USER)
  return isAdmin
}

export const isFunctionalTestUser = () =>
  !!getFromBrowserStorage(FUNCTIONAL_TEST)

export const fetchFunctionalTestUserEmail = () =>
  getFromBrowserStorage(FUNCTIONAL_TEST_USER_EMAIL)

export const fetchFunctionalTestUserLoginId = () =>
  getFromBrowserStorage(FUNCTIONAL_TEST_USER_LOGIN_ID)

export const fetchFunctionalTestUserData = () => {
  const lanId = getFromBrowserStorage(FUNCTIONAL_TEST_USER_LOGIN_ID)
  const email = getFromBrowserStorage(FUNCTIONAL_TEST_USER_EMAIL)
  return {
    email,
    lanId,
    firstName: lanId,
    lastName: lanId,
  }
}

export const isAuthorizedToPage = (
  pageName = 'default',
  memberOfAdGroups = [],
  rolesConfig = {},
) => {
  if (!memberOfAdGroups.length && ['home', 'help'].indexOf(pageName) !== -1) {
    return true
  }
  const { [pageName]: currentPageRoles = [] } = rolesConfig
  const memberOf = [...memberOfAdGroups]
  if (!currentPageRoles.length) return true
  return (
    memberOf.filter((member) => currentPageRoles.includes(member)).length > 0
  )
}

export const isEmptyObject = (value) =>
  Object.keys(value).length === 0 && value.constructor === Object

export const fetchPageEntitlements = (envConfig = {}, pageName = '') => {
  const { REACT_APP_ROLES_CONFIG = {} } = envConfig
  return REACT_APP_ROLES_CONFIG[pageName]
}

export const isMemberBelongToRoundel = (memberOf = []) =>
  memberOf.includes(AD_ROUNDEL_PARTNER)

export const sortStringWithNum = (list = [], orderBy = '', order = 'desc') => {
  const orderedList =
    order === 'desc'
      ? list.sort((a, b) => {
          const arrayA = a[orderBy].split('-')
          const arrayB = b[orderBy].split('-')
          const stringWithNumA = arrayA[arrayA.length - 1]
          const stringWithNumB = arrayB[arrayB.length - 1]
          const numberA = parseInt(stringWithNumA)
          const numberB = parseInt(stringWithNumB)
          return numberA < numberB ? -1 : 1
        })
      : list.sort((a, b) => {
          const arrayA = a[orderBy].split('-')
          const arrayB = b[orderBy].split('-')
          const stringWithNumA = arrayA[arrayA.length - 1]
          const stringWithNumB = arrayB[arrayB.length - 1]
          const numberA = parseInt(stringWithNumA)
          const numberB = parseInt(stringWithNumB)
          return numberA < numberB ? 1 : -1
        })
  return orderedList
}

export const removeEmptyValueKeysFromObj = (resourceObject = {}) => {
  const cleanedObj = {}
  for (const key in resourceObject) {
    if (Array.isArray(resourceObject[key]) && resourceObject[key].length) {
      cleanedObj[key] = resourceObject[key]
    }
  }
  return cleanedObj
}

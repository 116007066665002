import { FormControl, Grid, Input, InputLabel } from '@mui/material'

import CollapsibleCard from '../../components/Custom/CollapsibleCard'
import {
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_CARD_SHOW_BUTTON_TEXT,
} from '../../constants/metadata'
import { convertUTCtoLocal } from '../../utils/utils'

import { useMetadataContext } from './MetadataContext'

const ProjectInfoCard = ({ containerClasses = '' }) => {
  const { assetMetadata = {} } = useMetadataContext()
  const { metadata: { project_info = {} } = {} } = assetMetadata
  const {
    project_id = '',
    project_name = '',
    channel = '',
    category = '',
    campaign_description = '',
    project_run_date = '',
  } = project_info || {}
  const runDateFormatted = convertUTCtoLocal(project_run_date, 'lll') || ''
  return (
    <CollapsibleCard
      cardTitle={'Project Details'}
      hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
      showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
      defaultCollapse={true}
    >
      <Grid>
        {project_id && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="projectId">Project ID</InputLabel>
            <Input id="projectId" value={project_id} disableUnderline />
          </FormControl>
        )}
        {project_name && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="projectName">Project Name</InputLabel>
            <Input
              id="projectName"
              fullWidth
              multiline
              value={project_name}
              disableUnderline
            />
          </FormControl>
        )}
        {channel && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="projectChannel">Channel</InputLabel>
            <Input id="projectChannel" value={channel} disableUnderline />
          </FormControl>
        )}
        {runDateFormatted && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="projectLaunchDate">
              Run/Set/Launch Date
            </InputLabel>
            <Input
              id="projectLaunchDate"
              value={runDateFormatted}
              disableUnderline
            />
          </FormControl>
        )}
        {campaign_description && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="projectCampaign">Campaign/Season</InputLabel>
            <Input
              id="projectCampaign"
              value={campaign_description}
              disableUnderline
            />
          </FormControl>
        )}
        {category && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="category">Category</InputLabel>
            <Input id="category" value={category} disableUnderline />
          </FormControl>
        )}
      </Grid>
    </CollapsibleCard>
  )
}

export default ProjectInfoCard

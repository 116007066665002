import React, { useState, useEffect } from 'react'
import TablePagination from '@mui/material/TablePagination'
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  TableBody,
  TableSortLabel,
  Button,
  CircularProgress,
} from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone'
import WarningAmberTwoToneIcon from '@mui/icons-material/WarningAmberTwoTone'
import { useSelector , useDispatch } from 'react-redux'
import moment from 'moment'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import IconButton from '@mui/material/IconButton'
import HeaderTitle from '../../components/Header/HeaderTitle'
import { BULK_TEST, getGenAiBreadCrumbList } from '../helpers/genaiTestHelper'
import { generateBreadcrumb } from '../../helpers/stringHelper'
import {
  sortMyArray as sortList,
  sortByDate as sortDate,
  sortStringWithNum as sortTestId,
} from '../../helpers/UtilityHelper'
import { deleteBulkTests, fetchBulkTests } from '../services/genaiService'
import { CUSTOM_TIMESTAMP } from '../constants/genai'
import { REFERENCE_LINK } from '../../constants/routes'
import GenAITermsCondition from './GenAITermsCondition'
import { fetchUserPreferences } from '../../store/preferences/actionCreator'

const buttonTextColor = {
  color: '#178295',
}

const styles = makeStyles((theme) => ({
  buttonTextColor: buttonTextColor,
  displayStyle: {
    buttonTextColor,
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
  },
  tablePagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '12px',
    paddingRight: '10px',
  },
  newTestButton: {
    backgroundColor: '#178295',
    color: '#FFFFFF',
    width: '95px',
    height: '35px',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#178295',
    },
  },
}))

const statusStyle = {
  paddingRight: '2px',
  display: 'flex',
  alignItems: 'center',
  paddingTop: '25px',
  paddingBottom: '21px',
}

function BulkTestsListView() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const styleHelper = styles()

  const bulkTestBreadCrumbs = getGenAiBreadCrumbList(BULK_TEST)

  const [dataList, setDataList] = useState([])

  const [dataListLoader, setDataListLoader] = useState(false)

  const [orderBy, setOrderBy] = useState('create_time_stamp')

  const [order, setOrder] = useState('desc')

  const [rowsPerPage, setRowsPerPage] = useState(25)

  const [currPage, setCurrPage] = useState(0)

  const [mouseOverRow, setMouseOverRow] = useState('')

  const {
    auth: { isAdGroupSuperAdmin = false },
  } = useSelector((state) => state) || {}

  const getBulkTests = () => {
    setDataListLoader(true)
    fetchBulkTests()
      .then((response) => {
        const list = response.data
        setDataList(list)
        setDataListLoader(false)
      })
      .catch(() => setDataListLoader(false))
  }
  useEffect(() => {
    getBulkTests()
  }, [])

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortedData = () => {
    if (orderBy === 'create_time_stamp') {
      return sortDate(dataList, 'create_time_stamp', order)
    } else if (orderBy === 'test_id') {
      return sortTestId(dataList, 'test_id', order)
    }
    return sortList(dataList, orderBy, order)
  }

  const handleChangePage = (e, data) => {
    setCurrPage(data)
  }

  const handleChangeRowsPerPage = (event) => {
    const { target = {} } = event
    const { value = '' } = target
    setRowsPerPage(+value)
    setCurrPage(0)
  }

  const handleNewTest = () => {
    navigate('new')
  }

  const handleTestDelete = (testId) => {
    const deleteTestId = confirm(`Are you sure you want to delete ${testId}?`)
    if (deleteTestId) {
      deleteBulkTests(testId).then(() => {
        alert(`Deleted test with id : ${testId}`)
        getBulkTests()
      })
    }
  }

  const iconToDisplay = (status) => {
    switch (status) {
      case 'GENERATING':
        return (
          <HourglassEmptyIcon
            style={{ color: '#5F6368', paddingRight: '2px' }}
            fontSize="medium"
          />
        )
      case 'COMPLETED':
        return (
          <CheckCircleOutlineTwoToneIcon
            style={{ color: '#0C9D4F', paddingRight: '2px' }}
            fontSize="medium"
          />
        )
      default:
        return (
          <WarningAmberTwoToneIcon
            style={{ color: '#FF9900', paddingRight: '2px' }}
            fontSize="medium"
          />
        )
    }
  }
  return (
    <div style={{margin:"3rem 0 0 0"}}>
      <HeaderTitle title={generateBreadcrumb(bulkTestBreadCrumbs)} />
      <div className={styleHelper.tablePagination}>
        <TablePagination
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={dataList?.length || Number(0)}
          rowsPerPage={rowsPerPage}
          page={currPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(e) => handleChangeRowsPerPage(e)}
        />
        <div style={{ padding: '0 1rem 0 0' }}>
          <Button
            className={styleHelper.newTestButton}
            onClick={() => handleNewTest()}
          >
            New Test
          </Button>
        </div>
      </div>
      <TableContainer
        component={Paper}
        style={{
          marginLeft: '15px',
          paddingLeft: '1px',
          height: '80vh',
          overflow: 'scroll',
        }}
      >
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{
                  paddingRight: '0px',
                  width: '100px',
                  paddingLeft: '10px',
                }}
              >
                <TableSortLabel
                  active={orderBy == 'test_id'}
                  direction={orderBy === 'test_id' ? order : 'asc'}
                  onClick={() => handleSort('test_id')}
                >
                  Test Id
                </TableSortLabel>
              </TableCell>

              <TableCell
                align="left"
                style={{
                  paddingRight: '10px',
                  width: '150px',
                  paddingLeft: '3px',
                }}
              >
                <TableSortLabel
                  active={orderBy == 'test_name'}
                  direction={orderBy === 'test_name' ? order : 'asc'}
                  onClick={() => handleSort('test_name')}
                >
                  Test Name
                </TableSortLabel>
              </TableCell>

              <TableCell
                style={{
                  width: '100px',
                }}
              >
                <TableSortLabel
                  active={orderBy == 'create_time_stamp'}
                  direction={orderBy === 'create_time_stamp' ? order : 'desc'}
                  onClick={() => handleSort('create_time_stamp')}
                >
                  Date - Time
                </TableSortLabel>
              </TableCell>

              <TableCell align="center">
                <TableSortLabel
                  active={orderBy == 'model_used'}
                  direction={orderBy === 'model_used' ? order : 'asc'}
                  onClick={() => handleSort('model_used')}
                >
                  Model Used
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel
                  active={orderBy == 'prompt_category'}
                  direction={orderBy === 'prompt_category' ? order : 'asc'}
                  onClick={() => handleSort('prompt_category')}
                >
                  {' '}
                  Category
                </TableSortLabel>
              </TableCell>

              <TableCell align="left" style={{ paddingRight: '0px' }}>
                <TableSortLabel
                  active={orderBy == 'number_of_images'}
                  direction={orderBy === 'number_of_images' ? order : 'asc'}
                  onClick={() => handleSort('number_of_images')}
                >
                  #Images
                </TableSortLabel>
              </TableCell>

              <TableCell style={{ paddingRight: '0px' }}>
                <TableSortLabel
                  active={orderBy == 'review_complete_percentage'}
                  direction={
                    orderBy === 'review_complete_percentage' ? order : 'asc'
                  }
                  onClick={() => handleSort('review_complete_percentage')}
                >
                  Review Completed
                </TableSortLabel>
              </TableCell>

              <TableCell style={{ paddingRight: '0px' }}>
                <TableSortLabel
                  active={orderBy == 'pass_percentage'}
                  direction={orderBy === 'pass_percentage' ? order : 'asc'}
                  onClick={() => handleSort('pass_percentage')}
                >
                  Passed
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel
                  active={orderBy === 'test_creator'}
                  direction={orderBy === 'test_creator' ? order : 'asc'}
                  onClick={() => handleSort('test_creator')}
                >
                  Test Creator
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel
                  active={orderBy === 'process_status'}
                  direction={orderBy === 'process_status' ? order : 'asc'}
                  onClick={() => handleSort('process_status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>

              <TableCell width={80}></TableCell>

              <TableCell width={80}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dataListLoader ? (
              <CircularProgress />
            ) : (
              (sortedData() || [])
                .slice(
                  currPage * rowsPerPage,
                  currPage * rowsPerPage + rowsPerPage,
                )
                .map((data) => {
                  const {
                    test_id = '',
                    test_name = '',
                    create_time_stamp = '',
                    model_used = '',
                    number_of_images = '',
                    review_complete_percentage = '',
                    pass_percentage = '',
                    prompt_category = '',
                    test_creator = '',
                    process_status = '',
                  } = data
                  return (
                    <TableRow
                      onMouseOver={() => setMouseOverRow(test_id)}
                      onMouseOut={() => setMouseOverRow('')}
                      key={test_id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        height: '50px',
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ paddingRight: '5px' }}
                        align="center"
                      >
                        {test_id}
                      </TableCell>
                      <TableCell style={{ paddingRight: '0px' }}>
                        {test_name}
                      </TableCell>
                      <TableCell align="left" style={{ paddingRight: '0px' }}>
                        {moment(create_time_stamp).format(CUSTOM_TIMESTAMP)}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ paddingLeft: '15px', paddingRight: '2px' }}
                      >
                        {model_used}
                      </TableCell>
                      <TableCell align="left">{prompt_category}</TableCell>
                      <TableCell align="center" width={2}>
                        {number_of_images}
                      </TableCell>
                      <TableCell align="center" width={100}>
                        {review_complete_percentage}%
                      </TableCell>
                      <TableCell align="center">{pass_percentage}%</TableCell>
                      <TableCell align="left">{test_creator}</TableCell>

                      <TableCell align="left" style={statusStyle}>
                        {iconToDisplay(process_status)} {process_status}
                      </TableCell>
                      {mouseOverRow === test_id &&
                      process_status !== 'GENERATING' ? (
                        <TableCell
                          align="left"
                          className={styleHelper.buttonTextColor}
                        >
                          <div className={styleHelper.displayStyle}>
                            Review
                            <IconButton
                              className={styleHelper.buttonTextColor}
                              onClick={() => navigate(test_id)}
                            >
                              <ArrowForwardIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}

                      {mouseOverRow === test_id && isAdGroupSuperAdmin ? (
                        <TableCell
                          align="center"
                          className={styleHelper.buttonTextColor}
                        >
                          <div className={styleHelper.displayStyle}>
                            Delete
                            <IconButton
                              className={styleHelper.buttonTextColor}
                              onClick={() => handleTestDelete(test_id)}
                            >
                              <DeleteForeverIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  )
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
export default BulkTestsListView

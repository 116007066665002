import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { bindActionCreators } from 'redux'
import { withStyles } from '@mui/styles'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import CardContent from '@mui/material/CardContent'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import ListIcon from '@mui/icons-material/List'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import CircularProgress from '@mui/material/CircularProgress'
import withRouter from '../../containers/Router/WithRouter'

import {
  fetchTaskDetails,
  resetToInitState,
  expandTask,
  collapseTask,
  openTaskModal,
  closeTaskModal,
  generateTaskReport,
  downloadTaskReport,
} from './../../store/task/actionCreator'
import { selectGetTaskInfo } from './../../store/task/selector'
import { makeSelectAuthData } from './../../store/auth/selector'
import { selectHeaderTitle } from './../../store/layout/selector'

import HeaderTitle from '../Header/HeaderTitle'
import TaskList from './TaskList'
import AddTask from './AddTask'
import HeaderTabs from '../ProjectsPage/project/HeaderTabs'
import { generateBreadcrumb } from '../../helpers/UtilityHelper'

const styles = {
  homePage: {
    textAlign: 'left',
  },
  button: {
    marginRight: 8,
  },
  card: {
    margin: '10px 5px 10px 5px',
  },
}

export class TaskPage extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.expandTaskList = this.expandTaskList.bind(this)
    this.openTaskFrom = this.openTaskFrom.bind(this)
    this.closeTaskFrom = this.closeTaskFrom.bind(this)
    this.getTaskData = this.getTaskData.bind(this)
    const projName =
      get(this.props, 'task.taskList.userAssets.project_name', '') ||
      get(this.props, 'router.location.state.project.name', '')
    this.state = {
      editTask: {},
      breadCrumbTitle: projName,
      initializePoll: false,
    }
    // this.setTaskNames = this.setTaskNames.bind(this)
  }

  componentDidMount() {
    this.getTaskData()
  }

  componentWillUnmount() {
    const { editTask = {} } = this.state
    if (!Object.keys(editTask).length) this.props.resetToInitState()
  }
  componentDidUpdate(prevProps) {
    let prevPropsProjectID =
      get(prevProps, 'task.taskList.userAssets.project_uuid', '') ||
      get(prevProps, 'info.project.projectUuid', '')
    let nextPropsProjectID =
      get(this.props, 'task.taskList.userAssets.project_uuid', '') ||
      get(this.props, 'info.project.projectUuid', '')
    let projName =
      get(this.props, 'task.taskList.userAssets.project_name', '') ||
      get(this.props, 'router.location.state.project.name', '')

    if (nextPropsProjectID !== prevPropsProjectID) {
      if (projName) {
        this.setState({
          breadCrumbTitle: projName,
        })
      }
      const location = this.props.router.location
      if (
        location &&
        location.state &&
        location.state.eventTrigger === 'dashboard'
      ) {
        this.openTaskFrom()
      }
    }
  }
  getTaskData() {
    let loginId = this.props.auth.lanId
    let projectId = this.props.projectId
    if (loginId && projectId) {
      this.props.fetchTaskDetails(loginId, projectId)
    }
  }

  expandTaskList(groupId, expanded) {
    if (expanded) {
      this.props.collapseTask(groupId)
    } else {
      this.props.expandTask(groupId)
    }
  }
  setEditInfo() {
    return this.state.editTask
  }
  openTaskFrom(data) {
    this.setState(
      {
        editTask: data ? data : {},
      },
      () => {
        this.props.openTaskModal('taskForm')
      }
    )
  }

  closeTaskFrom() {
    this.props.closeTaskModal('taskForm')
  }

  initiatePoll = () => this.setState({ initializePoll: true })

  render() {
    const {
      classes,
      task = {},
      generateTaskReport = () => {},
      downloadTaskReport = () => {},
      auth = {},
    } = this.props
    const {
      taskList: { inActive = [], active = [], taskCount = Number(0), is_admin },
    } = task
    const inActiveCnt = inActive.length || Number(0)
    const activeCnt = active.length || Number(0)
    const breadCrumb = [
      {
        title: 'Projects',
        path: '/projects',
      },
      {
        title: this.state.breadCrumbTitle,
      },
    ]

    return (
      <div className={classes.homeBg}>
        <HeaderTitle title={generateBreadcrumb(breadCrumb)} icon="Project" />
        <Helmet>
          <title>{generateBreadcrumb(breadCrumb, true)}</title>
        </Helmet>
        <HeaderTabs activeTabIndex={2} id={this.props.projectId} />
        <div>
          {task.loading.taskListLoading ? (
            <Grid
              container
              spacing={3}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '250px' }}
            >
              <Grid item xs={12}>
                <CircularProgress size={50} className={classes.gridProgress} />
              </Grid>
            </Grid>
          ) : task.apiError ? (
            <Grid
              container
              spacing={3}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '250px' }}
            >
              <Grid item xs={12} className={classes.boxDiv}>
                <p>
                  An error occurred while retrieving task data. Please contact
                  support if this issue persists.
                </p>
              </Grid>
            </Grid>
          ) : taskCount >= 1 ? (
            <div>
              <Card className={classes.card} spacing={1}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="Recipe">
                      <ListIcon />
                    </Avatar>
                  }
                  action={
                    <span>
                      {is_admin && (
                        <Tooltip id="tooltip-icon" title="Create New Task">
                          <Fab
                            data-cy="createNewTask"
                            onClick={() => this.openTaskFrom()}
                            color="primary"
                            aria-label="add"
                            className={classes.button}
                          >
                            <div className="icon-default">
                              <AddIcon />
                            </div>
                            <div className="icon-hover">
                              <ListIcon />
                            </div>
                          </Fab>
                        </Tooltip>
                      )}
                    </span>
                  }
                  title="Active Tasks"
                  subheader={`${activeCnt} Active Tasks`}
                />
                <CardContent data-cy="activeTasks" className={classes.cardCont}>
                  <TaskList
                    List={this.props}
                    onExpand={this.expandTaskList}
                    loading={this.props.task.loading}
                    isActive
                    openTaskFrom={this.openTaskFrom.bind(this)}
                    getTaskData={this.getTaskData}
                    generateTaskReport={generateTaskReport}
                    downloadTaskReport={downloadTaskReport}
                    downloadingTaskReport={task.downloadingTaskReport}
                    auth={auth}
                    fetchTaskDetails={fetchTaskDetails}
                    initializePoll={this.state.initializePoll}
                    initiatePoll={this.initiatePoll}
                  />
                </CardContent>
              </Card>
              <Card className={classes.card} spacing={1}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="Recipe">
                      <ListIcon />
                    </Avatar>
                  }
                  title="Inactive Tasks"
                  subheader={`${inActiveCnt} Inactive Tasks`}
                />
                <CardContent
                  data-cy="inactiveTasks"
                  className={classes.cardCont}
                >
                  <TaskList
                    List={this.props}
                    onExpand={this.expandTaskList}
                    loading={this.props.task.loading}
                    isActive={false}
                    getTaskData={this.getTaskData}
                    generateTaskReport={generateTaskReport}
                    downloadTaskReport={downloadTaskReport}
                    downloadingTaskReport={task.downloadingTaskReport}
                    auth={auth}
                    fetchTaskDetails={fetchTaskDetails}
                  />
                </CardContent>
              </Card>
            </div>
          ) : (
            <Card className={classes.card} spacing={1}>
              <CardHeader
                avatar={
                  <Avatar aria-label="Recipe">
                    <ListIcon />
                  </Avatar>
                }
                action={
                  is_admin && (
                    <Tooltip id="tooltip-icon" title="Create New Task">
                      <Fab
                        data-cy="createNewTask"
                        onClick={() => this.openTaskFrom()}
                        color="primary"
                        aria-label="add"
                        className={classes.button}
                      >
                        <div className="icon-default">
                          <AddIcon />
                        </div>
                        <div className="icon-hover">
                          <ListIcon />
                        </div>
                      </Fab>
                    </Tooltip>
                  )
                }
                title="Tasks"
                subheader={`0 Tasks`}
              />
              <CardContent className={classes.cardCont}>
                {is_admin ? (
                  <p>There are no tasks created for this project.</p>
                ) : (
                  <p>You are not assigned any tasks on this project.</p>
                )}
              </CardContent>
            </Card>
          )}
        </div>
        {task.modal.taskForm && (
          <AddTask
            open={task.modal.taskForm}
            onClose={this.closeTaskFrom}
            editInfo={this.setEditInfo()}
            {...this.props}
          />
        )}
      </div>
    )
  }
}

TaskPage.contextTypes = {
  router: PropTypes.object,
}

TaskPage.propTypes = {
  classes: PropTypes.object,
  projectId: PropTypes.string,
  headerTitle: PropTypes.string,
  auth: PropTypes.object,
  task: PropTypes.object,
  fetchTaskDetails: PropTypes.func,
  resetToInitState: PropTypes.func,
  expandTask: PropTypes.func,
  collapseTask: PropTypes.func,
  openTaskModal: PropTypes.func,
  closeTaskModal: PropTypes.func,
  generateTaskReport: PropTypes.func,
  downloadTaskReport: PropTypes.func,
}

const mapStateToProps = (state) => ({
  headerTitle: selectHeaderTitle()(state),
  auth: makeSelectAuthData()(state),
  task: selectGetTaskInfo()(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTaskDetails,
      resetToInitState,
      expandTask,
      collapseTask,
      openTaskModal,
      closeTaskModal,
      generateTaskReport,
      downloadTaskReport,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(TaskPage)))

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from '@mui/material'
import {
  fetchProject,
  fetchTenantData,
  submitTenantReview,
} from '../../store/tenant/actionCreator'
import { channel } from './content'

const TenantTable = () => {
  const dispatch = useDispatch()
  const tenantData = useSelector((state) => state.tenant.data)
  const loading = useSelector((state) => state.tenant.loading)
  const projectTypes = useSelector((state) => state.tenant.projects)
  const [open, setOpen] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState(null)
  const [status, setStatus] = useState('')
  const [reviewComment, setReviewComment] = useState('')
  const [projectType, setProjectType] = useState('')
  const [channelType, setChannelType] = useState('')

  useEffect(() => {
    dispatch(fetchTenantData())
  }, [])

  const handleClickOpen = (tenant) => {
    setSelectedTenant(tenant)
    setStatus(tenant.status)
    setReviewComment(tenant.review_comments || '')
    dispatch(fetchProject())
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedTenant(null)
    setStatus('')
    setReviewComment('')
  }

  const handleSubmit = () => {
    if (!selectedTenant) return

    const trimmedComment = reviewComment.trim()
    if (!status || !trimmedComment) {
      alert('Status and Review Comment are required')
      return
    }

    const payload = {
      tenant_id: selectedTenant.tenant_id,
      review_comments: reviewComment,
      status,
      channel: channelType,
      project_type: projectType,
    }
    dispatch(submitTenantReview(payload))
    handleClose()
  }

  return (
    <TableContainer component={Paper} sx={{ p: 0, maxHeight: 560 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Tenant Name</TableCell>
            <TableCell>Tenant ID</TableCell>
            <TableCell>AD Group</TableCell>
            <TableCell>Blossom ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Review Comment</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={12}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {((tenantData && tenantData) || []).map((tenant) => (
            <TableRow key={tenant.tenant_id}>
              <TableCell>{tenant.tenant_name}</TableCell>
              <TableCell>{tenant.tenant_id}</TableCell>
              <TableCell>{tenant.ad_group}</TableCell>
              <TableCell>{tenant.blossom_id}</TableCell>
              <TableCell>{tenant.status}</TableCell>
              <TableCell>{tenant.review_comments}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                  onClick={() => handleClickOpen(tenant)}
                >
                  Review
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Review Tenant</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="CHANGES_REQUESTED">CHANGES_REQUESTED</MenuItem>
              <MenuItem value="APPROVED">APPROVED</MenuItem>
              <MenuItem value="REJECTED">REJECTED</MenuItem>
              <MenuItem value="DECOMMISSIONED">DECOMMISSIONED</MenuItem>
            </Select>
          </FormControl>
          {status === 'APPROVED' && (
            <FormControl fullWidth margin="dense">
              <InputLabel>Project Type</InputLabel>
              <Select
                label="Project Type"
                value={projectType}
                onChange={(e) => setProjectType(e.target.value)}
              >
                {projectTypes &&
                  projectTypes.map((project) => (
                    <MenuItem
                      key={project.project_type}
                      value={project.project_type}
                    >
                      {project.project_type}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {status === 'APPROVED' && (
            <FormControl fullWidth margin="dense">
              <InputLabel>Channel</InputLabel>
              <Select
                label="Channel"
                value={channelType}
                onChange={(e) => setChannelType(e.target.value)}
              >
                {channel.map((channel) => (
                  <MenuItem key={channel} value={channel}>
                    {channel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            margin="dense"
            label="Review Comment"
            type="text"
            fullWidth
            value={reviewComment}
            onChange={(e) => setReviewComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  )
}

export default TenantTable

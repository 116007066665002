import moment from 'moment'
import Moment from 'moment-timezone'
import { VIDEO_ASSET_TYPES } from '../constants/search'
import apiConfig from '../../config/apiConfig'
import { quoteValidationRegex } from '../../helpers/Regexes'

export const fetchPageEntitlements = (envConfig = {}, pageName = '') => {
  const { REACT_APP_ROLES_CONFIG = {} } = envConfig
  return REACT_APP_ROLES_CONFIG[pageName]
}

export const appendKeyParamToUrl = (url = '') => {
  const delimiter = url?.indexOf('?') === -1 ? '?' : '&'
  const apiKey = apiConfig.apiKey
  return `${url}${delimiter}key=${apiKey}`
}

export const convertUTCtoLocal = (dateString = '', format = 'lll') =>
  moment.utc(dateString).local().format(format)

export const getStorageIdentifier = (identifier = '') => {
  const lsId =
    'ah_metadata_' + identifier.toLowerCase().replace(/ /g, '_') + '_collapse'
  return lsId
}

export const convertCSTtoLocal = (dateString = '', format = 'lll') => {
  const dateToUTC = dateString?.replace('Z', '-06:00')
  return Moment(dateToUTC).local().format(format)
}

export const formatCSTDate = (dateTime = new Date(), format = 'lll') => {
  if (dateTime === '') {
    return dateTime
  }
  return Moment(dateTime).utc('Z').format(format)
}

export const flattenObject = (input) => {
  let result = {}
  for (const key in input) {
    if (!Object.prototype.hasOwnProperty.call(input, key)) {
      continue
    }
    if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
      let subFlatObject = flattenObject(input[key])
      for (const subkey in subFlatObject) {
        result[key + '_' + subkey] = subFlatObject[subkey]
      }
    } else {
      result[key] = input[key]
    }
  }
  return result
}

export const isVideoAsset = (fileExt = '') =>
  VIDEO_ASSET_TYPES?.includes(fileExt?.toLowerCase())

export const sortMyArray = function (
  myArray = [],
  myOrderField = '',
  myOrder = 'desc',
) {
  if (myArray === null || myArray.length === 0) {
    return []
  }
  const orderedArray =
    myOrder === 'desc'
      ? myArray.sort((a, b) => (b[myOrderField] < a[myOrderField] ? -1 : 1))
      : myArray.sort((a, b) => (a[myOrderField] < b[myOrderField] ? -1 : 1))
  return orderedArray
}

export const setInBrowserStorage = function (
  storageKey = '',
  storageValue = '',
) {
  window.localStorage.setItem(storageKey, storageValue)
}

export const separateFilenameExtension = function (fullFilename = '') {
  if (!fullFilename) {
    return {
      fileName: '',
      fileExt: '',
    }
  }
  const extensionIndex = fullFilename.lastIndexOf('.')
  const fileName =
    extensionIndex !== -1
      ? fullFilename.substr(0, extensionIndex)
      : fullFilename
  const fileExt =
    extensionIndex !== -1 ? fullFilename.substr(extensionIndex) : ''
  return {
    fileName,
    fileExt,
  }
}

export const getInitials = (fullName = '', delimiter = '.', maxlength = 2) => {
  const fullNameArray = fullName.split(delimiter)
  const initialsArray = []
  fullNameArray.forEach(function (name = '') {
    initialsArray.push(name.charAt(0))
  })
  return initialsArray.slice(0, maxlength).join('').toUpperCase()
}

export const getNonProductBrand = (itemInformation = []) =>
  itemInformation.find((itemInfo) => {
    const { is_non_product = false } = itemInfo
    if (is_non_product) return itemInfo
  })

export const getLowerCaseValue = (stringVal = '') =>
  stringVal?.toLowerCase() || ''

export const getUpperCaseValue = (stringVal = '') =>
  stringVal?.toUpperCase() || ''

export const isStringContainsQuotes = (inputString = '') => quoteValidationRegex.test(inputString)

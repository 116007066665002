import Moment from 'moment-timezone'
import { invert, padStart, sortBy } from 'lodash'
import {
  adminAccessFilterListKeys,
  COLOR_IDENTIFIER_GROUP,
  DEFAULT_COLORS,
  FACET_COLOR_KEY,
  FACET_IMAGEDATA_KEY,
  FILTER_CLASS_KEY,
  FILTER_DEPARTMENT_KEY,
  FILTER_IMAGEDATA_ANIMALS_KEY,
  FILTER_IMAGEDATA_FOOD_KEY,
  FILTER_IMAGEDATA_PEOPLE_KEY,
  FILTER_IS_ARCHIVED_KEY,
  FILTER_UPLOAD_DATE_KEY,
} from '../constants/search'

export const checkExpired = (usageInfo) => {
  const today = new Date()
  return (
    usageInfo?.length !== 0 &&
    usageInfo?.some((usageObj) => {
      let expDate = new Date(usageObj?.expirationDate)
      return expDate.getTime() < today.getTime()
    })
  )
}

export const checkIfUlimited = (usageInfo) => {
  if (!usageInfo?.length) {
    return true
  }
  return (
    usageInfo?.length !== 0 &&
    usageInfo?.filter((usageObj) => {
      const { expirationDateFormatted, availableChannels } = usageObj || {}
      return (
        availableChannels === 'Any and All Media' &&
        expirationDateFormatted === '9999-12-31'
      )
    })?.length === usageInfo?.length
  )
}

export const formatUsageRightsDate = (dateTime = new Date()) =>
  Moment(dateTime).utc('Z').format('LL')

export const updateFilterAccessAndCount = (filterConfig = [], filterList = {}, adminAccess = false) => {
  const newFilterConfigList = [...filterConfig]
  newFilterConfigList.forEach((filter) => {
    const { key = '', type = '' } = filter
    if (adminAccessFilterListKeys.includes(key) && adminAccess) {
      filter.show = adminAccess
    }
    filter.filtersCount = Number(0)
    if (key in filterList || type === 'custom') {
      filter.filtersCount =
        type === 'custom'
          ? calcMultiFilterCount(filterList, key)
          : filterList[key]?.length
    }
    if (key === FILTER_CLASS_KEY) {
      filter.show = filterList && FILTER_DEPARTMENT_KEY in filterList
    }
    if(key === FILTER_IS_ARCHIVED_KEY) {
      //Default Archival Filter Value is 'false', so we don't need to show chip for it.
      if(filterList[FILTER_IS_ARCHIVED_KEY].length && filterList[FILTER_IS_ARCHIVED_KEY]?.[0] === 'false') {
        filter.filtersCount = 0
      } else if(filterList[FILTER_IS_ARCHIVED_KEY].length === Number(0)) {
        //From API, we get archival filter value as empty array when include archived assets filter is selected
        filter.filtersCount = 1
      }
    }
  })
  return newFilterConfigList
}

export const calcMultiFilterCount = (filterList = {}, key = '') => {
  let filterCount = 0
  switch (key) {
    case FILTER_UPLOAD_DATE_KEY:
      filterCount = filterList[key]?.length ? 1 : 0
      break
    case FACET_IMAGEDATA_KEY: {
      const animalCount = filterList[FILTER_IMAGEDATA_ANIMALS_KEY] ? 1 : 0
      const peopleCount = filterList[FILTER_IMAGEDATA_PEOPLE_KEY] ? 1 : 0
      const foodCount = filterList[FILTER_IMAGEDATA_FOOD_KEY] ? 1 : 0
      filterCount = animalCount + peopleCount + foodCount
      break
    }
    case FACET_COLOR_KEY: {
      let colorFilterExist = false
      colorFilterExist = COLOR_IDENTIFIER_GROUP.some(
        (colorKey) => colorKey in filterList,
      )
      filterCount = colorFilterExist ? 1 : 0
      break
    }
  }
  return filterCount
}

export const isHexValue = (hexString = '') => {
  const hexRegex =
    hexString.charAt(0) === '#'
      ? /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
      : /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  return hexRegex.test(String(hexString))
}

export const hexToHSV = (hex = '') => {
  const rgbForHex = hexToRgb(hex)
  const getHSVFromRGB = rgbForHex ? rgbToHsv(rgbForHex) : null
  return getHSVFromRGB
}

const rgbToHsv = ({ r, g, b }) => {
  // eslint-disable-next-line no-unused-expressions
  ;(r /= 255), (g /= 255), (b /= 255)

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h,
    s,
    v = max

  let d = max - min
  s = max == 0 ? 0 : d / max

  if (max == min) {
    h = 0 // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6
  }

  h = mapMinMax(h, 0, 1, 0, 360)
  h = Math.round(h)
  s = Math.round(s * 100)
  v = Math.floor(v * 100)

  return [h, s, v]
}

function mapMinMax(value, oldMin, oldMax, newMin, newMax) {
  return ((newMax - newMin) * (value - oldMin)) / (oldMax - oldMin) + newMin
}

const hexToRgb = (hex = '') => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  // eslint-disable-next-line no-param-reassign
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null
}

export const getColorFacetValue = (filterValue = '') =>
  Object.keys(DEFAULT_COLORS)[
  Object.values(DEFAULT_COLORS).indexOf((filterValue || '').toUpperCase())
  ] || filterValue

export const createColorChipContent = (filterValue = '', filterName = '') => {
  const colorNamesCodes = invert(DEFAULT_COLORS)
  let colorObj = {
    background: 'transparent',
    height: '19px',
    width: '19px',
    cursor: 'pointer',
    position: 'relative',
    outline: 'none',
    borderRadius: '50%',
    transition: 'box-shadow 100ms ease 0s',
    contentVisibility: 'hidden',
  }
  colorObj['boxShadow'] =
    (colorNamesCodes[filterValue] || filterValue) + ' 0px 0px 0px 16px inset'
  return (
    <div
      style={{ display: 'flex', alignItems: 'flex-end' }}
      data={{ detail: filterValue || '', label: filterName }}
    >
      <div data-cy="filterChipFacet" title={filterValue} style={colorObj}>
        {filterName}
      </div>
      <span
        style={{ textTransform: 'upperCase', marginLeft: '2px' }}
      >{`${getColorFacetValue(filterValue)}`}</span>
    </div>
  )
}

export const prefixZerosToGetMinNChars = (N = 3, value = '') =>
  padStart(value.toString(), N, '0')

export const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value)

export const getAlphabeticSortedAndGroupedOptions = (updatedRecords = [], groupIdentifier = 'value') => {
  const sortedResults = sortBy(updatedRecords, groupIdentifier)
  let data = sortedResults.reduce((r, e) => {
    let alphabet = e[groupIdentifier][0]
    if (!r[alphabet]) r[alphabet] = [e]
    else r[alphabet].push(e);
    return r;
  }, {});
  return data
}

export const handleMultiCheckboxChangeEvent = (existingList = [], currentValue = '') => {
  const currentIndex = existingList.indexOf(currentValue)
  const newChecked = [...existingList]
  if (currentIndex === -1) {
    newChecked.push(currentValue)
  } else {
    newChecked.splice(currentIndex, 1)
  }
  return newChecked
}

import React, { useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, FormControl, Input, InputLabel } from '@mui/material'
import { METADATA_CARD_HIDE_BUTTON_TEXT, METADATA_CARD_SHOW_BUTTON_TEXT } from '../../constants/metadata'
import { getStorageIdentifier } from '../../helpers/MetadataHelper'
import { getFromBrowserStorage } from '../../helpers/UtilityHelper'
import { CollapsibleCard } from '../../components/CustomCards/CollapsibleCard'
import { trackUpdateMetadata } from '../../analytics/betaSearch'

const AssetDetailsBulkCard = ({
  classes = {},
  updateParentState = () => {},
}) => {
  const [creator, setCreator] = useState('')
  const [personShownInput, setPersonShownInput] = useState('')

  useEffect(()=> {
    if(creator !== '' || personShownInput !== '') {
      const updatedAssetDetails = {
        assetDetails: {
          personShown: personShownInput?.split(',').map((person) => person.trim()) || [],
          creator: creator,
        },
        unsavedChanges: true,
      }
      // Update parent state
      updateParentState(updatedAssetDetails)
    }
  }, [creator, personShownInput])

  const assetDetailsUpdateHandler = (event, fieldName = '') => {
    const targetValue = event.target.value
    fieldName === 'creator' ? setCreator(targetValue) : setPersonShownInput(targetValue)
  }

  return (
    <Card
    >
      <CardHeader
        title="Asset Details"
      />
      <CardContent>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="adCreator" shrink>
            Creator
          </InputLabel>
          <Input
            id="creatorBulk"
            onChange={(event) => assetDetailsUpdateHandler(event, 'creator')}
            value={creator}
            fullWidth
            multiline
            inputProps={{ 'data-cy': 'creatorBulk' }}
          />
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="personShown" shrink>
            Person Shown
          </InputLabel>
          <Input
            id="personShown"
            onChange={(event) => assetDetailsUpdateHandler(event, 'personShown')}
            value={personShownInput}
            fullWidth
            multiline
            inputProps={{ 'data-cy': 'personShownBulk' }}
          />
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default AssetDetailsBulkCard;
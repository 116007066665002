import React, { useState } from 'react'
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Typography,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import {
  submitTenantForm,
  fetchTenantData,
} from '../../store/tenant/actionCreator'

const initialFormData = {
  tenant_name: '',
  ad_group: '',
  nuid: '',
  blossom_id: '',
  uploads: false,
  search: false,
  api: false,
  kafka: false,
  is_confidential: '',
  email: '',
};

const Tenant = () => {
  const dispatch = useDispatch()
  const { lanId } = useSelector((state) => state.auth)
  const [formData, setFormData] = useState(initialFormData)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleSubmit = async () => {
    const payload = {
      tenant_name: formData.tenant_name,
      ad_group: formData.ad_group,
      nuid: formData.nuid,
      services: [
        formData.uploads ? 'UPLOADS' : '',
        formData.search ? 'SEARCH' : '',
      ].filter(Boolean),
      email: formData.email,
      blossom_id: formData.blossom_id,
      integration_mode: [
        formData.api ? 'API' : '',
        formData.kafka ? 'KAFKA' : '',
      ].filter(Boolean),
      is_confidential: formData.is_confidentiality === 'confidential',
      created_by: lanId,
    }

    dispatch(submitTenantForm(payload))
    dispatch(fetchTenantData())
  }

  return (
    <div>
        <div style={{ padding: '1rem' }}>
          <Typography>Register as a Tenant to upload assets</Typography>
          <TextField
            margin="dense"
            name="tenant_name"
            label="Name of the Tenant"
            type="text"
            fullWidth
            required
            value={formData.tenant_name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="ad_group"
            label="Admin AD Group"
            type="text"
            required
            fullWidth
            value={formData.ad_group}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="nuid"
            label="NUID Details"
            type="text"
            required
            fullWidth
            value={formData.nuid}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="blossom_id"
            label="Blossom ID"
            type="text"
            required
            fullWidth
            value={formData.blossom_id}
            onChange={handleChange}
          />
          <div>
            <h6 style={{ margin: '0.3rem 0 0 0 ' }}>
              Enterprise Services Offered*
            </h6>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.uploads}
                    onChange={handleChange}
                    name="uploads"
                  />
                }
                label="Uploads"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.search}
                    onChange={handleChange}
                    name="search"
                  />
                }
                label="Search"
              />
            </div>
          </div>
          <div>
            <h6 style={{ margin: '0.3rem 0 0 0 ' }}>
              Enterprise Services Offered*
            </h6>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.api}
                    onChange={handleChange}
                    name="api"
                  />
                }
                label="API"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.kafka}
                    onChange={handleChange}
                    name="kafka"
                  />
                }
                label="Kafka"
              />
            </div>
          </div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="confidentiality"
              name="is_confidentiality"
              value={formData.is_confidentiality}
              onChange={handleChange}
            >
              <FormControlLabel
                value="confidential"
                control={<Radio />}
                label="Confidential"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={formData.email}
            onChange={handleChange}
          />
          <div style={{ margin: '1rem 0' }}>
            <Button
              variant="outlined"
              onClick={() => setFormData(initialFormData)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={handleSubmit}
              color="primary"
              style={{ margin: ' 0 0 0 1rem' }}
            >
              Submit
            </Button>
          </div>
        </div>
    </div>
  )
}

export default Tenant

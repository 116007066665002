import GroupIcon from '@mui/icons-material/Group'
import SearchIcon from '@mui/icons-material/Search'
import ProjectIcon from '@mui/icons-material/DeviceHub'
import { ViewAgenda } from '@mui/icons-material'
import { Grid } from '@mui/material'

export const content = (classes) => [
  <div>
    <p>
      AssetHub is Target’s Digital Asset Management (DAM) tool for all 2d
      assets. Our mission is to simplify the asset creation and management
      experience within Target by enabling teams to search for assets, download
      them, collaborate on projects, and communicate with internal and external
      partners throughout the asset creation process.
    </p>
    <Grid container spacing={3} className={classes.grid}>
      <Grid lg={3}>
        <Grid justifyContent="center" container xs={12}>
          <a href="/search">
            <SearchIcon className={classes.link}></SearchIcon>
          </a>
        </Grid>
        <Grid justifyContent="center" container xs={12}>
          <p className={classes.blurb}>
            Search for an asset and download it to use in your next project
          </p>
        </Grid>
      </Grid>
      <Grid lg={3}>
        <Grid justifyContent="center" container xs={12}>
          <a href="/projects">
            <ProjectIcon className={classes.link}></ProjectIcon>
          </a>
        </Grid>
        <Grid justifyContent="center" container xs={12}>
          <p className={classes.blurb}>
            Create a project to colaborate with specific users and to upload
            assets
          </p>
        </Grid>
      </Grid>
      <Grid lg={3}>
        <Grid justifyContent="center" container xs={12}>
          <a href="/boards">
            <ViewAgenda className={classes.link}></ViewAgenda>
          </a>
        </Grid>
        <Grid justifyContent="center" container xs={12}>
          <p className={classes.blurb}>
            Save a collection of assets to a board and share them with a group
            of users
          </p>
        </Grid>
      </Grid>
      <Grid lg={3}>
        <Grid justifyContent="center" container xs={12}>
          <a href="/groups">
            <GroupIcon className={classes.link}></GroupIcon>
          </a>
        </Grid>
        <Grid justifyContent="center" container xs={12}>
          <p className={classes.blurb}>
            Create groups to save you time when adding people to projects or
            boards
          </p>
        </Grid>
      </Grid>
    </Grid>
  </div>,
  <p>
    Welcome to AssetHub! You now have access to search for approved final assets
    and reuse them in your project, Create or join a project to collaborate with
    other, create a board and share it with other users. Check out the topics to
    the left to learn more.
  </p>,
  <div>
    <p>
      Would you like to invite other Team Members, contractors, or vendors to
      collaborate with you?
    </p>
    <h4>For Team Members and Contractors access:</h4>
    <p>
      1. Navigate to myaccess.target.com and request the APP-OAUTH2-ASSETHUB
      entitlement on their behalf. Once approved they will have access within
      the hour.
    </p>
    <h4>For Agencies (external users) access:</h4>
    <p>
      1. The company will need to be added to{' '}
      <a href="https://partnersonline.com/">Partners Online</a> (POL) as a
      Marketing Partner or Merchant Partner. <br />
      2. A company admin will need to be chosen to manage users for their
      company in{' '}
      <a href="https://vmm.target.com/vmm">
        Vendor Management and Maintenance
      </a>{' '}
      (VMM). <br />
      3. Each user will need to be added and set as active in VMM by the company
      admin. <br />
      4. The Target partner for the vendor will need to request AssetHub access
      for users on their behalf by reaching out to{' '}
      <a href="mailto: david.lahaye@target.com">
        david.lahaye@target.com
      </a>. <br />
      5. Once approved the users will be able to launch AssetHub from the{' '}
      <a href="https://partnersonline.com/">Partners Online</a> (POL) portal and
      begin using.
    </p>
  </div>,
  <p>Coming Soon</p>,
]

export const channel = [
  'Broadcast',
  'Catalog',
  'Communication',
  'Cross-Channel',
  'Direct Mail',
  'Display',
  'Experiential',
  'Gift Cards',
  'In Store Market',
  'Magazine',
  'Meetings',
  'Newspaper',
  'Operational Sign',
  'Out of Home',
  'Packaging',
  'Promo',
  'RedCard',
  'Roundel',
  'Roundel Metadata Tagging' ,
  'Social',
  'Talent+Change',
  'Target.com',
]

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Switch, Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { promptConfig, conditionLabels } from '../config/PromptConfig'
import { fetchPromptData, fetchCategoryList, togglePromptStatus } from '../services/promptService'
import { convertDateToDisplay } from '../../helpers/dateHelper'
import apiConfig from '../../config/apiConfig'
import { removeEmptyValueKeysFromObj } from '../../helpers/UtilityHelper'

const useStyles = makeStyles({
  tableContainer: {
    padding: '20px 30px',
    width: 'inherit'
  },
  tableHeadingStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableStyles: {
    border: '1px solid #f4f0f0'
  },
  itemDataCell: {
    minWidth: '100px',
    minHeight: '40px',
  },
  seeMoreStyles: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    color: '#178295',
    cursor: 'pointer'
  },
  buttonStyle: {
    cursor: 'pointer',
    height: '30px',
    color: '#178295',
    background: 'white',
    gap: '5px',
    alignItems: 'flex-end',
    '&:hover': {
      color: 'white',
      background: '#178295',
    },
  },
  promptDrawerStyles: {
    width: '450px',
    backgroundColor: 'white',
    marginLeft: 70,
  },
})

const PromptListTable = ({
  selectedTab = 'ASSETHUB'
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enterpriseUploads: { tenantId = '' } = {} } = apiConfig

  let tableConfigHeaders = [...promptConfig]
  if (selectedTab === 'ASSETHUB') {
    tableConfigHeaders = tableConfigHeaders.filter((config) => config.property !== 'team_name')
  }

  const [categoryList, setCategoryList] = useState([])
  const [promptData, setPromptData] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedGroupingId, setSelectedGroupingId] = useState('')

  useEffect(() => {
    getAllCategories()
    getAllPromptDetails()
  }, [])

  const getAllCategories = () => {
    fetchCategoryList().then((response) => {
      setCategoryList(response.data)
    }).catch(() => setCategoryList([]))
  }

  const getAllPromptDetails = () => {
    fetchPromptData().then((response) => {
      setPromptData(response.data)
    }).catch((error) => {
      setPromptData([])
    })
  }

  const openAddPromptDialog = () => {
    navigate('/settings/prompts/create', { state: { categoryList, selectedTab } })
  }

  const viewMoreDetails = (promptGroupingId = '') => {
    navigate(`/settings/prompts/${promptGroupingId}`, { state: { categoryList, selectedTab } })
  }

  const convertIdToName = (validator = '', value = '') => categoryList.find((category) => category[validator] === value)?.name || ''

  const filteredList = selectedTab === 'ASSETHUB' ? promptData.filter((item) => item.tenant_id === tenantId) : promptData.filter((item) => item.tenant_id !== tenantId)

  const getTableCellData = (item, config) => {
    let itemValue = ''
    if (config.type === 'timestamp' && item[config.property]) {
      itemValue = convertDateToDisplay(item[config.property])
    } else if (config.type === 'object') {
      const conditionsList = Object.keys(removeEmptyValueKeysFromObj(item[config.property]))?.map(key => conditionLabels[key]).join(', ') || 'None'
      itemValue = item[config.property] && Object.keys(removeEmptyValueKeysFromObj(item[config.property]))?.length ? conditionsList : 'None'
    } else if (config.type === 'id_to_name') {
      itemValue = convertIdToName(config.property, item[config.property])
    } else if (config.type === 'link') {
      itemValue = (
        <Link
          component="button"
          variant="body2"
          onClick={() => viewMoreDetails(item.prompt_grouping_id)}
        >
          {item[config.property]}
        </Link>
      )
    } else {
      itemValue = item[config.property]
    }
    return itemValue
  }

  const handleTogglePrompt = (groupingId = '') => {
    setSelectedGroupingId(groupingId)
    setOpenDialog(true)
  }

  const handleConfirm = () => {
    togglePromptStatus(selectedGroupingId).then((response) => {
      setOpenDialog(false)
      getAllPromptDetails()
    })
  }

  const handleCancel = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer} data-testid='prompt-table-container'>
        <div className={classes.tableHeadingStyles}>
          <h3 data-testid='headingLabel'>Showing {filteredList?.length} Prompts</h3>
          <Button
            name='add'
            variant="outlined"
            className={classes.buttonStyle}
            onClick={() => openAddPromptDialog()}
          >
            <AddCircleOutlineOutlinedIcon fontSize='small' />
            Add
          </Button>
        </div>
        <Table className={classes.tableStyles}>
          <TableHead>
            <TableRow>
              {tableConfigHeaders.map((config) => (
                <TableCell align='left' sx={{ paddingLeft: 3 }} key={config.property} id={config.property}>{config.label}</TableCell>
              ))}
              <TableCell align='right'>Enabled</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(filteredList || []).map((item) => (
              <TableRow key={item.prompt_grouping_id} data-testid={item.prompt_grouping_id}>
                {tableConfigHeaders.map((config) => (
                  <TableCell key={config.property} align='left' sx={{ paddingLeft: 3 }}>
                    {
                      getTableCellData(item, config)
                    }
                  </TableCell>
                ))}
                <TableCell align='right'><Switch
                  sx={{
                    '& .MuiSwitch-thumb': {
                      backgroundColor: '#188295',
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: '#188295',
                    },
                    '&.Mui-checked': {
                      '& .MuiSwitch-thumb': {
                        backgroundColor: '#188295',
                      },
                      '& .MuiSwitch-track': {
                        backgroundColor: '#188295',
                      },
                    },
                  }}
                  checked={item.is_enabled}
                  onChange={() => handleTogglePrompt(item.prompt_grouping_id)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                </TableCell>
              </TableRow>
            ))}
            {promptData?.length === 0 && <div data-testid='norecords'>No Records Found</div>}
          </TableBody>
        </Table>
      </TableContainer>
      {
        openDialog &&
        <Dialog
          open={openDialog}
          onClose={handleCancel}
        >
          <DialogTitle>Confirm Toggle: {promptData.find((prompt) => prompt.prompt_grouping_id === selectedGroupingId).name}</DialogTitle>
          <DialogContent>
            <p>This change will affect Asset Metadata Enrichment.Are you sure you want to toggle the status of the prompt?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}

export default PromptListTable

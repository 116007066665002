import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import {
  Button,
  Grid,
  Drawer,
  Toolbar,
  List,
  MenuItem,
  ListItemText,
  Divider,
} from '@mui/material'
import { selectHeaderTitle } from './../../store/layout/selector'
import HeaderTitle from '../../components/Header/HeaderTitle'
import './index.css'
import { content } from './content'
import Tenant from './Tenant'
import TenantTable from './TenantTable'

const drawerWidth = 240

const useStyles = makeStyles({
  content: {
    padding: '0px 24px 0px 24px',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'white',
    marginLeft: 70,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  main: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
    zIndex: 0,
    position: 'relative',
  },
  sectionTitle: {
    marginTop: '15px',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem 0 0 0',
    alignItems: 'center',
    flexDirection: 'column',
  },
})

const HelpPage = ({ headerTitle = '' }) => {
  const classes = useStyles()
  const [selected, setSelected] = useState(0)
  const [menuItem, setMenuItem] = useState('')
  const { memberOf } = useSelector((state) => state.auth)
  const hasAdminAccess = memberOf.includes('APP-OAUTH2-ASSETHUB-ADMIN')
  const topics = [
    'Overview',
    'Getting Started',
    'Inviting others to AssetHub',
    'Dashboard',
    'Tenant',
  ]

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.content')

      let minIndex = 0
      let minDistance = Number.POSITIVE_INFINITY

      sections.forEach((section, index) => {
        const distance = Math.abs(section.getBoundingClientRect().top - 100)
        if (distance < minDistance) {
          minDistance = distance
          minIndex = index
        }
      })

      setSelected(minIndex)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div>
      <HeaderTitle id="title" title="Help" icon="Help" />
      <Helmet>
        <title>{headerTitle}</title>
      </Helmet>
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              <MenuItem>
                <ListItemText primary={<b>Topics</b>} />
              </MenuItem>
              <Divider />
              {topics.map((text, index) => (
                <MenuItem
                  key={text}
                  button
                  selected={selected === index}
                  onClick={() => {
                    setSelected(index)
                    setMenuItem(text)
                    document
                      .getElementById(text)
                      ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                  }}
                >
                  <ListItemText primary={text} />
                </MenuItem>
              ))}
            </List>
          </div>
        </Drawer>

        <main className={classes.main}>
          <Toolbar />
          {menuItem !== 'Tenant' ? (
            topics
              .filter((text) => text !== 'Tenant')
              .map((text, index) => (
                <div key={text} id={text} className={classes.content}>
                  <h3 className={classes.sectionTitle}>{text}</h3>
                  <div>{content(classes)[index]}</div>
                  <Divider />
                </div>
              ))
          ) : (
            <Grid container p={1}>
              {hasAdminAccess  ? <TenantTable />:<Tenant />}
            </Grid>
          )}
        </main>
      </div>
    </div>
  )
}

HelpPage.propTypes = {
  headerTitle: PropTypes.string,
  classes: PropTypes.object,
}
const mapStateToProps = (state) => ({
  headerTitle: selectHeaderTitle()(state),
})

export default connect(mapStateToProps)(HelpPage)

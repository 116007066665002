import axios from 'axios'
import idx from 'idx'
import queryString from 'query-string'
import { find } from 'lodash'
import firefly from '../../analytics/firefly'

import {
  PROJECTS_FILTER,
  PROJECTS_SORT,
  PROJECT_API_CALL_INNITIATED,
  PROJECT_API_CALL_SUCCESS,
  PROJECT_API_CALL_FAIL,
  CREATE_PROJECT_FAILURE,
  GET_PROJECT_TYPES,
  SUBMIT_FORM,
  CREATE_PROJECT_SUCCESS,
  GET_PROJECT_DETAILS_INIT,
  GET_PROJECT_DETAILS,
  GET_ASSET_UPLOAD_STATUS,
  PROJECT_OPEN_MODAL,
  PROJECT_CLOSE_MODAL,
  EDIT_PROJECT,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAIL,
  GET_BRANDS,
  FETCH_PROJECT_DETAILS_ONEDIT,
  UPDATE_PROJECT_ERROR,
  SET_DEFAULT_PAGINATION,
  UPDATE_PAGINATION,
  GET_ALL_PROJECT_ASSETS,
  UPDATE_ASSET_LISTS,
  RESET_PROJECT_DATA,
  SET_PROJECT_COVER_IMAGE,
  GET_PROJECT_TASKS_FULFILLED,
  GET_PROJECT_TASKS_REJECTED,
  UPDATE_PROJECT_LIST_PAGINATION,
  PROJECT_PAGE_API_CALL_INNITIATED,
  PROJECT_PAGE_API_CALL_SUCCESS,
  PROJECT_PAGE_API_CALL_FAIL,
  SET_COVER_IMAGE_LOADING,
  SET_SELECTED_ASSETS,
  UPDATE_CREATIVE_ASSET_STATUS,
  GET_REVISION_ASSETS_INIT,
  GET_REVISION_ASSETS,
  GET_PROJECT_ASSETS_INIT,
  UPDATE_PAGINATION_AND_FILTERS,
} from './actionType'
import apiConfig from '../../config/apiConfig'
import { EDIT_PROJECT_ID_EXIST_ERROR_MESSAGE } from '../../constants/notifications'
import { formatPaginationQueryParams } from '../../helpers/UrlHelper'
import { getDefaultAssetPagination } from './helpers'
import {
  PROJECT_SORT_OPTIONS,
  PROJECT_FILTER_ALL,
} from '../../constants/projects'


const KEY = apiConfig.key
const PUBLIC_URL_PATH = apiConfig.publicUrlPath

export function openModal(modalName) {
  return {
    type: PROJECT_OPEN_MODAL,
    modalName,
  }
}

export function closeModal(modalName) {
  return {
    type: PROJECT_CLOSE_MODAL,
    modalName,
  }
}

export const searchProjectsPage = (pagination = {}, lanId = '') =>
  callProjectSearchApi(
    projectPageApiCallInitiated,
    projectPageApiCallSuccess,
    projectPageApiCallFail,
    pagination,
    lanId
  )

export const searchProjects = (pagination = {}, lanId = '') =>
  callProjectSearchApi(
    projectApiCallInitiated,
    projectApiCallSuccess,
    projectApiCallFail,
    pagination,
    lanId
  )

export const callProjectSearchApi = (
  apiCallInitiated = () => { },
  apiCallSuccess = () => { },
  apiCallFail = () => { },
  pagination = {},
  lanId = ''
) => {
  const url = `${apiConfig.assethub.serviceHostMarket}/user_projects_all?size=${pagination.size}&page=${pagination.page}&key=${KEY}`

  return (dispatch) => {
    // First dispatch: the app state is updated to inform
    // that the API call is starting.
    dispatch(apiCallInitiated())

    return axios
      .post(url, createRequestBody(pagination))
      .then((response) => {
        dispatch(apiCallSuccess(response.data))
        dispatch(updateProjectListPagination(pagination))
      })
      .catch((error) => {
        dispatch(apiCallFail(error))
        firefly.trackApiError(error.response, lanId)
      })
  }
}

export const createRequestBody = (pagination = {}) => {
  const sortByOption = find(
    PROJECT_SORT_OPTIONS,
    (option) => option.value === pagination.sort_by.feature
  )

  return {
    ...pagination,
    sort_by: {
      feature: sortByOption.apiValue,
      sort: sortByOption.order,
    },
    filter_by:
      pagination.filter_by === PROJECT_FILTER_ALL ? '' : pagination.filter_by,
  }
}

export const updateProjectListPagination = (data) => ({
  type: UPDATE_PROJECT_LIST_PAGINATION,
  data,
})

export const projectsFilter = (data) => ({
  type: PROJECTS_FILTER,
  data,
})

export const projectsSort = (data) => ({
  type: PROJECTS_SORT,
  data,
})

export function projectApiCallInitiated() {
  return {
    type: PROJECT_API_CALL_INNITIATED,
  }
}

export const projectApiCallSuccess = (data) => ({
  type: PROJECT_API_CALL_SUCCESS,
  data,
})

export const projectApiCallFail = (error) => ({
  type: PROJECT_API_CALL_FAIL,
  error,
})

export function projectPageApiCallInitiated() {
  return {
    type: PROJECT_PAGE_API_CALL_INNITIATED,
  }
}

export function projectPageApiCallSuccess(data) {
  return {
    type: PROJECT_PAGE_API_CALL_SUCCESS,
    data,
  }
}

export function projectPageApiCallFail(error) {
  return {
    type: PROJECT_PAGE_API_CALL_FAIL,
    error,
  }
}

export function getTypeCallSuccess(data) {
  return {
    type: GET_PROJECT_TYPES,
    data,
  }
}

export function gotoTracker(projectId) {
  if (apiConfig.projectType === 'cgi') {
    window.location = encodeURI(
      `${apiConfig.projects.trackerUrl}?projectId=${projectId}`
    )
  } else {
    if (PUBLIC_URL_PATH && PUBLIC_URL_PATH !== '' && PUBLIC_URL_PATH !== '/') {
      window.location = encodeURI(
        `${PUBLIC_URL_PATH}${apiConfig.projects.trackerUrl}/${projectId}/dashboard`
      )
    } else {
      window.location = encodeURI(
        `${apiConfig.projects.trackerUrl}/${projectId}/dashboard`
      )
    }
  }

}

export const fetchProjectDetails =
  (reqData = {}) =>
    (dispatch) => {
      const projectDetailsUrl = `${apiConfig.assethub.serviceHostMarket}/project_details?key=${apiConfig.key}`
      const promise = axios.post(projectDetailsUrl, reqData)
      return dispatch({
        type: FETCH_PROJECT_DETAILS_ONEDIT,
        payload: promise,
      })
    }

export function createProject(data) {
  return function (dispatch) {
    dispatch(preFormSubmit())
    const url = apiConfig.projects.createProjectUrl

    return axios
      .post(`${url}?key=${KEY}`, data)
      .then((response) => {
        dispatch(createProjectSuccess())
        dispatch(gotoTracker(response.data.project_uuid))
      })
      .catch((e) => {
        dispatch(
          createProjectError({
            id: 'projectError',
            data: e,
          })
        )
        // tracker for API error
      })
  }
}

export function createProjectError(error) {
  return {
    type: CREATE_PROJECT_FAILURE,
    payload: error,
  }
}
export function createProjectSuccess() {
  return {
    type: CREATE_PROJECT_SUCCESS,
  }
}
export function preFormSubmit(error) {
  return {
    type: SUBMIT_FORM,
    payload: error,
  }
}
export function getProjectTypes() {
  return function (dispatch) {
    const url = `${apiConfig.projects.projecTypeUrl}?key=${KEY}`
    return axios
      .get(url)
      .then((response) => {
        dispatch(getTypeCallSuccess(response.data))
      })
      .catch((e) => {
        dispatch(projectApiCallFail(e))
        // tracker for API error
      })
  }
}

export const updateProjectError = (updateErrorMessage = '') => ({
  type: UPDATE_PROJECT_ERROR,
  updateErrorMessage,
})

export const updateProjectWrapper =
  (payload = {}) =>
    (dispatch) => {
      const { project_id = '', project_type = '' } = payload
      const projectDetailsUrl = `${apiConfig.assethub.serviceHostMarket}/project_details?key=${apiConfig.key}`

      axios
        .post(projectDetailsUrl, { project_id, project_type })
        .then(({ data = {} }) => {
          if (data.length > 0 && data[0].project_uuid) {
            const projectInitiator = idx(
              data[0],
              (_) => _.project_initiator.display_name
            )
            const updateErrorMessage = `${projectInitiator} ${EDIT_PROJECT_ID_EXIST_ERROR_MESSAGE} ${payload.project_id} `
            dispatch(updateProjectError(updateErrorMessage))
          } else {
            if (!!payload.project_uuid) {
              dispatch(editProject(payload))
            } else {
              dispatch(createProject(payload))
            }
          }
        })
    }

export function editProject(data) {
  return function (dispatch) {
    dispatch(editProjectInit())
    return axios
      .put(
        `${apiConfig.assethub.serviceHostMarket}/projects?key=${apiConfig.key}`,
        data
      )
      .then((response) => {
        dispatch(editProjectSuccess(response.data))
        dispatch(closeModal('editProject'))
        dispatch(getProjectAssetDetails(response.data.project_uuid))
      })
      .catch((e) => {
        dispatch(editProjectFail(e))
      })
  }
}

export function editProjectInit() {
  return {
    type: EDIT_PROJECT,
  }
}

export function editProjectSuccess(data) {
  return {
    type: EDIT_PROJECT_SUCCESS,
    data,
  }
}

export function editProjectFail(data) {
  return {
    type: EDIT_PROJECT_FAIL,
    data,
  }
}

export function getProjectAssetData(
  projectId,
  pagination = getDefaultAssetPagination()
) {
  return function (dispatch) {
    const url = `${apiConfig.assethub.projectDetailsUrl
      }${projectId}/assets?key=${KEY}&${formatPaginationQueryParams(pagination)}`
    return axios
      .get(url)
      .then((response) => {
        if (response.data) {
          const { asset_list = [], project_asset_count = 0 } = response.data
          dispatch(getProjectAssetDataCB(asset_list, project_asset_count))
        }
      })
      .catch((e) => {
        dispatch(projectApiCallFail(e))
        // tracker for API error
      })
  }
}

export function getProjectAssetsOnJobStatus(response) {
  return function (dispatch) {
    if (response.data) {
      const { asset_list = [], project_asset_count = 0 } = response.data
      dispatch(getProjectAssetDataCB(asset_list, project_asset_count))
    }
  }
}

export function getProjectAssetDataCB(assetList, assetCount) {
  return {
    type: GET_ASSET_UPLOAD_STATUS,
    payload: {
      assetList,
      assetCount,
    },
  }
}

export function updateCreativeAssetStatus(creativeAssetStatus = 'All') {
  return {
    type: UPDATE_CREATIVE_ASSET_STATUS,
    payload: creativeAssetStatus,
  }
}

export function getProjectAssetsWithFilters(
  projectID = '',
  pagination = getDefaultAssetPagination(),
  filters = {}
) {
  return function (dispatch) {
    const projectAssetsUrlWithPagination = `${apiConfig.assethub.projectDetailsUrl}${projectID}?${formatPaginationQueryParams(pagination)}`
    const paramsObj = Object.assign({}, filters, { key: KEY })
    const queryParams = queryString.stringify(paramsObj)
    const projectAssetWithFilterApi = `${projectAssetsUrlWithPagination}&${queryParams}`
    dispatch(updatePaginationAndFilters(pagination, filters))
    dispatch(getProjectAssetsInit())
    return axios
      .get(
        projectAssetWithFilterApi
      ).then((response) => {
        dispatch(getProjectDetailsCallSuccess(response.data))
      })
      .catch((error) => {
        dispatch(projectApiCallFail(error))
      })
  }
}

export function getProjectAssetDetails(
  projectID = '',
  pagination = getDefaultAssetPagination()
) {
  return function (dispatch) {
    dispatch(getProjectAssetDetailsInit())
    return axios
      .get(
        `${
          apiConfig.assethub.projectDetailsUrl
        }${projectID}?key=${KEY}&${formatPaginationQueryParams(pagination)}`
      )
      .then((response) => {
        dispatch(getProjectDetailsCallSuccess(response.data))
      })
      .catch((error) => {
        dispatch(projectApiCallFail(error))
      })
  }
}

export function getProjectRevisionAssets(
  projectID = '',
  assetNames = []
) {
  return function (dispatch) {
    dispatch(getProjectRevisionAssetsInit())
    return axios
      .post(
        `${apiConfig.projects.createProjectUrl
        }/${projectID}/assetsDetails?key=${KEY}`
        , assetNames)
      .then((response) => {
        dispatch(getProjectRevisionAssetsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(projectApiCallFail(error))
      })
  }
}

export function getProjectRevisionAssetsInit(data, projectID) {
  return {
    type: GET_REVISION_ASSETS_INIT,
    data: data,
    projectID: projectID,
  }
}

export function getProjectRevisionAssetsSuccess(data) {
  return {
    type: GET_REVISION_ASSETS,
    data: data,
  }
}

export function getProjectAssetDetailsInit(data, projectID) {
  return {
    type: GET_PROJECT_DETAILS_INIT,
    data: data,
    projectID: projectID,
  }
}

export function getProjectAssetsInit() {
  return {
    type: GET_PROJECT_ASSETS_INIT
  }
}

export function getProjectDetailsCallSuccess(data) {
  return {
    type: GET_PROJECT_DETAILS,
    data: data,
  }
}

export const getBrand =
  (name = '') =>
    (dispatch) => {
      const brandsUrl = `${apiConfig.projects.brandsUrl}?brand_name=${name}&key=${KEY}`
      const promise = axios.get(brandsUrl)
      return dispatch({
        type: GET_BRANDS,
        payload: promise,
      })
    }

export const updatePagination = (pagination = {}) => ({
  type: UPDATE_PAGINATION,
  data: pagination,
})

export const updatePaginationAndFilters = (pagination = {}, filters = {}) => ({
  type: UPDATE_PAGINATION_AND_FILTERS,
  data: {
    pagination, filters
  }
})

export const setDefaultPagination = () => (dispatch) => {
  dispatch({ type: SET_DEFAULT_PAGINATION })
}


export const resetProjectData = () => ({
  type: RESET_PROJECT_DATA,
})

export function setProjectCoverImageOnSuccess(data) {
  return {
    type: SET_PROJECT_COVER_IMAGE,
    data,
  }
}

export function setImageCoverToLoading() {
  return {
    type: SET_COVER_IMAGE_LOADING,
  }
}

export function setSelectedAssets(selectedAssets) {
  return {
    type: SET_SELECTED_ASSETS,
    data: selectedAssets,
  }
}

//set project cover image
export function setProjectCoverImage(
  projectUUID,
  imageMasterID,
  callBackSuccess
) {
  return function (dispatch) {
    dispatch(setImageCoverToLoading())
    const url = `${apiConfig.projects.setCoverImg}${projectUUID}/cover_image/${imageMasterID}`

    return axios
      .put(url, {})
      .then((response) => {
        if (response.data) {
          dispatch(setProjectCoverImageOnSuccess(response.data.cover_image))
          callBackSuccess(response.data)
        }
      })
      .catch((e) => {
        dispatch(projectApiCallFail(e))
      })
  }
}

export const fetchProjectTasks = (projectId = '') => {
  let url = `${apiConfig.assethub.serviceHostMarket}/project_review_tasks/${projectId}?key=${KEY}`
  return function (dispatch) {
    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_PROJECT_TASKS_FULFILLED,
          data: { taskInfo: response.data, projectId },
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_PROJECT_TASKS_REJECTED,
          data: { error, projectId },
        })
      })
  }
}

import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography, Grid, Button, Paper, Divider, Drawer, Chip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ClearIcon from '@mui/icons-material/Clear'
import PromptConditionalDrawer from './PromptConditionsDrawer'
import { getClassificationCode } from '../../betasearch/mappers/searchMapper'
import { promptConditions } from '../config/PromptConfig'

const useStyles = makeStyles({
  promptDrawerStyles: {
    width: '70%',
    backgroundColor: 'white',
    marginLeft: 70,
  },
  clearIconStyles: {
    cursor: 'pointer'
  },
})

const PromptConditionsView = ({
  isDisabled = false,
  conditionsConfig = [],
  appliedConditions = {},
  setAppliedConditions = () => { }
}) => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const applySelectedConditions = ({
    appliedProjectTypes,
    appliedChannels,
    appliedClassifications,
    appliedFileTypes
  }) => {
    setAppliedConditions({
      project_type: appliedProjectTypes,
      channel: appliedChannels,
      classification: appliedClassifications,
      file_type: appliedFileTypes
    })
  }

  const classificationConfig = conditionsConfig.find(({ key }) => key === 'Classification') || {}

  const handleDelete = (type, condition) => {
    setAppliedConditions({ ...appliedConditions, [type]: appliedConditions[type].filter(item => item !== condition) })
  }

  return (
    <>
      <Paper elevation={2} sx={{ padding: 2, maxWidth: 600, margin: "0px" }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Conditions
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              sx={{ textTransform: "none" }}
              onClick={() => setIsDrawerOpen(true)}
              disabled={isDisabled}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Divider />
        {promptConditions.map((item, index) => (
          <Box key={item.id} sx={{ paddingY: 2, borderBottom: index < promptConditions.length - 1 ? "1px solid #ddd" : "none" }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1">
                {item.label}
              </Typography>
            </Grid>
            <Grid container style={{ 'gap': 2 }}>
              {appliedConditions[item.id] && appliedConditions[item.id]?.length > 0 ? (<>
                {appliedConditions[item.id]?.map((condition, index) => (
                  <Chip disabled={isDisabled}
                    key={index} label={item.id === 'classification' ? getClassificationCode(condition, classificationConfig) : condition} sx={{ marginRight: 1 }} onDelete={() => handleDelete(item.id, condition)} />
                ))}
              </>) : 'None'}
            </Grid>
          </Box>
        ))}
      </Paper>
      {
        isDrawerOpen && (
          <Drawer
            anchor={'right'}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            classes={{
              paper: classes.promptDrawerStyles,
            }}
            data-testid='drawer-prompt'
          >
            <Box sx={{ padding: 2, borderBottom: "1px solid #ddd" }}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Typography variant="h6">
                  Conditions
                </Typography>
                <ClearIcon onClick={() => setIsDrawerOpen(false)} className={classes.clearIconStyles} />
              </Grid>
            </Box>
            <Box sx={{ padding: 2, flexGrow: 1 }} >
              <PromptConditionalDrawer conditionsConfig={conditionsConfig} appliedConditions={appliedConditions} applySelectedConditions={applySelectedConditions} setIsDrawerOpen={setIsDrawerOpen} />
            </Box>
          </Drawer>
        )
      }
    </>
  )
}

export default PromptConditionsView

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    searchContainer: {
      padding: '20px 0px 50px 15px',
    },
    searchInputDiv: {
      flexDirection: 'row',
      alignItems: 'baseline',
      gap: '20px',
      paddingTop: '10px !important',
      paddingLeft: '45px !important',
    },
    filtersLayout: {
      background: '#b8a7a714',
    },
    paginationLayout: {
      background: '#b8a7a714',
      paddingTop: '5px !important',
    },
    paginationBottom: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    pageContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 0px 10px 15px',
      justifyContent: 'space-between',
    },
    selectSection: {
      justifyContent: 'flex-start',
      width: '48%',
      gap: '15px',
      alignItems: 'center',
    },
    searchAssets: {
      maxHeight: '70vh',
      overflow: 'scroll',
    },
    selectAllLabel: {
      padding: '2px 11px 2px 2px',
      borderRadius: '8px',
      border: '1px solid rgb(136, 136, 136)',
      height: '2.5rem',
      margin: 1,
    },
    selectAll: {
      fontSize: '0.875rem',
    },
  }),
  { index: 1 },
);

export default useStyles;

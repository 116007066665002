import Firefly from 'firefly-sdk-ts'
import moment from 'moment-timezone'
import {
  initializeFirefly,
  trackSession,
  sessionId,
  generateSessionId,
} from './initializeFirefly'
import * as fireflyInternalAppConfig from '../config/fireflyConfig.json'

const Analytics = {
  eventType: {
    CLICK: 'click',
    SUBMIT: 'submit',
    VIEW: 'pageload',
    LOGIN: 'login',
    UNINITIATED_DOWNLOAD: 'uninitiated_download',
    ASSET_CARD_DOWNLOAD: 'asset_card_download',
    DOWNLOAD: 'download',
    PROJECT_CREATE: 'project_create',
    PROJECT_LIST_SORT: 'project_list_sort',
    PROJECT_LIST_FILTER: 'project_list_filter',
    PROJECT_LIST_PAGE: 'PROJECT_LIST_PAGE',
    SEARCH_PAGE_CHANGE: 'search_page_change',
    SEARCH_PAGE_SORT_CHANGE: 'search_page_sort_change',
    SEARCH_PAGE_RESULTS_CHANGE: 'search_page_results_change',
    BOARD_CREATE: 'board_create',
    BOARD_UPDATE: 'board_update',
    BOARD_DELETE: 'board_delete',
    BOARD_ADD_ASSET: 'board_add_asset',
    BOARD_REMOVE_ASSET: 'board_remove_asset',
    UPDATE_ASSET_METADATA: 'update_asset_metadata',
    BULK_UPDATE_ASSET_METADATA: 'bulk_update_asset_metadata',
    SEARCH_HIGHLIGHTS: 'search_highlights',
    LIBRARIAN_TEMPLATE_CHANGE: 'librarian_template_change',
    PUBLISH_ASSET_COPY: 'publish_asset_copy',
  },
  SCHEMA_VERSION: '1.2.2',
}

const APPLICATION_NAME = 'assethub-ui'

const TIMESTAMP_ISO_1806 = 'YYYY-MM-DD HH:mm:ss Z'

let searchId = ''

export const searchFilterKey = (searchTerm) => {
  // when search term is empty or equal to *
  if (searchTerm === '*' || !searchTerm) {
    return 'EmptySearch'
  }

  // when search term contains numbers and it also includes special characters like - , . ;
  if (/^[\d -.; _]+$/.test(searchTerm)) {
    return 'TcinDpciSearch'
  }

  // when search term contains file extensions
  const extensions = [
    'pdf',
    'tif',
    'tiff',
    'ai',
    'jpg',
    'jpeg',
    'psd',
    'eps',
    'png',
    'dms',
    'indd',
    'ind',
    'psb',
    'mov',
    'mp4',
  ]
  const searchExtensionSplit = searchTerm.toLowerCase().split('.')
  let extension
  for (extension in searchExtensionSplit) {
    if (extensions.includes(searchExtensionSplit[extension])) {
      return 'FileNameSearch'
    }
  }

  return 'KeywordSearch'
}

const firefly = {
  initalizeSearchId() {
    if (searchId === '') {
      searchId = new Date().toISOString()
    }
  },

  clearSearchId() {
    searchId = ''
  },

  trackLogin(loginId) {
    const application = this.getApplication(loginId)

    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
      },
      fireflyInternalAppConfig.default.persistMethod
    )
    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.LOGIN,
      },
    })
  },

  getApplication(loginId) {
    if (!sessionId) {
      generateSessionId(loginId)
    }
    return {
      name: APPLICATION_NAME,
      version: `${process.env.REACT_APP_VERSION}`,
      schemaVersion: Analytics.SCHEMA_VERSION,
      customSessionId: sessionId,
    }
  },

  // previous name is only accepting title of previous page, therefore using additionalInfo
  // to pass previous path to firefly.
  trackView(route, title, loginId, prevPath) {
    const application = this.getApplication(loginId)
    const view = {
      additionalInfo: {
        prevpath: prevPath,
      },
      globalName: route,
      name: title,
      previousName: prevPath,
    }

    initializeFirefly()

    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        view,
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.VIEW,
      },
    })
  },

  trackUninitiatedDownloads(uninitiatedDownloads = 0, loginId) {
    const application = this.getApplication(loginId)

    initializeFirefly()

    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: 'uninitiatedDownloadCount',
          value: `${uninitiatedDownloads}`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.UNINITIATED_DOWNLOAD,
      },
    })
    trackSession(loginId)
  },

  trackClick(action, actionType, searchTerm, filterData, loginId, templateId) {
    const application = this.getApplication(loginId)
    const userData = {
      customDimension1: `actionType# ${actionType}`,
      customDimension2: `searchTerm# ${searchTerm}`,
      customDimension3: `filters# ${filterData}`,
      segment: `template# ${templateId}`,
      id: `taskId: ${searchId}`,
    }

    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        user: userData,
        customInteraction: {
          key: searchFilterKey(searchTerm),
          value: `${searchTerm}`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )
    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: action,
      },
    })
  },

  trackSearchSort(
    searchTerm,
    filterData,
    loginId,
    currentPage,
    sortBy,
    templateId
  ) {
    const application = this.getApplication(loginId)
    const userData = {
      customDimension1: `actionType# Page Sort Change`,
      customDimension2: `searchTerm# ${searchTerm}`,
      customDimension3: `filters# ${filterData}`,
      segment: `template# ${templateId}`,
      id: `taskId: ${searchId}`,
    }

    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        user: userData,
        customInteraction: {
          key: 'SearchPageSortChange',
          value: `${sortBy}`,
          location: `${currentPage + 1}`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )
    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.SEARCH_PAGE_SORT_CHANGE,
      },
    })
  },

  trackSearchResultsPerPageChange(
    searchTerm,
    filterData,
    loginId,
    currentPage,
    currentPageSize,
    templateId
  ) {
    const application = this.getApplication(loginId)
    const userData = {
      customDimension1: `actionType# Page Size Change`,
      customDimension2: `searchTerm# ${searchTerm}`,
      customDimension3: `filters# ${filterData}`,
      segment: `template# ${templateId}`,
      id: `taskId: ${searchId}`,
    }

    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        user: userData,
        customInteraction: {
          key: 'SearchPageSizeChange',
          value: `${currentPageSize}`,
          location: `${currentPage + 1}`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )
    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.SEARCH_PAGE_RESULTS_CHANGE,
      },
    })
  },

  trackSearchPageChange(
    searchTerm,
    filterData,
    loginId,
    currentPage,
    previousPage,
    templateId
  ) {
    const application = this.getApplication(loginId)
    const userData = {
      customDimension1: `actionType# Page Change`,
      customDimension2: `searchTerm# ${searchTerm}`,
      customDimension3: `filters# ${filterData}`,
      segment: `template# ${templateId}`,
      id: `taskId: ${searchId}`,
    }

    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        user: userData,
        customInteraction: {
          key: 'SearchPageChange',
          value: `previousPage: ${previousPage + 1}`,
          location: `${currentPage + 1}`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )
    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.SEARCH_PAGE_CHANGE,
      },
    })
  },

  trackLibrarianTemplateChange(searchTerm, filterData, loginId, templateId) {
    const application = this.getApplication(loginId)
    const userData = {
      customDimension1: `actionType# Page Change`,
      customDimension2: `searchTerm# ${searchTerm}`,
      customDimension3: `filters# ${filterData}`,
      segment: `template# ${templateId}`,
      id: `taskId: ${searchId}`,
    }

    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        user: userData,
        customInteraction: {
          key: 'librarianTemplateChange',
          value: `template# ${templateId}`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )
    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.LIBRARIAN_TEMPLATE_CHANGE,
      },
    })
  },

  trackUpload(action, actionType, fileName, uploadFileData, loginId) {
    const application = this.getApplication(loginId)
    const userData = {
      customDimension1: `actionType# ${actionType}`,
      customDimension2: `fileName# ${fileName}`,
      customDimension3: `uploadData# ${uploadFileData}`,
    }

    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        user: userData,
      },
      fireflyInternalAppConfig.default.persistMethod
    )
    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: action,
      },
    })
  },

  trackAssetCardDownloads(downloadAsset, loginId, pageNumber = 0) {
    const application = this.getApplication(loginId)

    const userData = {
      customDimension2: `downloadFiles# ${JSON.stringify(downloadAsset)}`,
      id: `taskId: ${searchId}`,
    }
    initializeFirefly()

    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        user: userData,
        customInteraction: {
          key: 'downloadCount',
          value: `1`,
          location: `${pageNumber}`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.ASSET_CARD_DOWNLOAD,
      },
    })
  },

  trackDownloads(
    downloadAssets = [],
    pageNumber = 0,
    loginId = '',
    location = ''
  ) {
    const application = this.getApplication(loginId)

    let downloadAssetData = {
      customDimension2: `downloadFiles# ${JSON.stringify(downloadAssets)}`,
    }
    if (location === 'search') {
      downloadAssetData = { ...downloadAssetData, id: `taskId: ${searchId}` }
    }

    initializeFirefly()

    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        user: downloadAssetData,
        customInteraction: {
          key: 'downloadCount',
          value: `${downloadAssets.length}`,
          location: `${pageNumber}`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.DOWNLOAD,
      },
    })
  },

  trackApiError(error = {}, loginId = '') {
    const errorInfo = {
      id: error.status,
      message: error.data.message,
    }

    const errorUrl = {
      customDimension1: `errorUrl# ${error.data.path ? error.data.path : ''}`,
    }

    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        error: errorInfo,
        errorUrl,
      },
      fireflyInternalAppConfig.default.persistMethod
    )
    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: 'httpError',
      },
    })
  },

  trackProjectCreate(project = {}, loginId = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.PROJECT_CREATE,
          value: JSON.stringify(project),
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.PROJECT_CREATE,
      },
    })
  },

  trackProjectListSort(projectListSort = '', loginId = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.PROJECT_LIST_SORT,
          value: projectListSort,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.PROJECT_LIST_SORT,
      },
    })
  },

  trackProjectListFilter(filter = '', loginId = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.PROJECT_LIST_FILTER,
          value: filter,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.PROJECT_LIST_FILTER,
      },
    })
  },

  trackProjectListPage(page = 1, pageSize = 0, loginId = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.PROJECT_LIST_PAGE,
          value: `page: ${page}, size: ${pageSize}`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.PROJECT_LIST_PAGE,
      },
    })
  },

  trackBoardCreated(boardId = '', loginId = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.BOARD_CREATE,
          value: boardId,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.BOARD_CREATE,
      },
    })
  },

  trackBoardUpdated(boardId = '', loginId = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.BOARD_UPDATE,
          value: boardId,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.BOARD_UPDATE,
      },
    })
  },

  trackBoardDeleted(boardId = '', loginId = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.BOARD_DELETE,
          value: boardId,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.BOARD_DELETE,
      },
    })
  },

  trackBoardAddAsset(data = '', loginId = '', location = '') {
    const boards = []
    // reformat boards, assets data
    for (const [key, value] of Object.entries(data)) {
      const assets = []
      value.forEach((asset) => {
        assets.push(asset.asset_id)
      })

      boards.push({
        board_id: key,
        assets: assets,
      })
    }
    const user = location === 'search' ? { id: `taskId: ${searchId}` } : {}
    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.BOARD_ADD_ASSET,
          value: JSON.stringify(boards),
        },
        user: user,
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.BOARD_ADD_ASSET,
      },
    })
  },

  trackBoardRemoveAsset(data = {}, loginId = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.BOARD_REMOVE_ASSET,
          value: JSON.stringify(data),
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.BOARD_REMOVE_ASSET,
      },
    })
  },

  trackMetadataUpdate(metadata = {}, loginId = '', location = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    const user = location === 'search' ? { id: `taskId: ${searchId}` } : {}
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.UPDATE_ASSET_METADATA,
          value: metadata.assetId,
        },
        user: user,
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.UPDATE_ASSET_METADATA,
      },
    })
  },

  trackBulkMetadataUpdate(data = {}, loginId = '', location = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    const user = location === 'search' ? { id: `taskId: ${searchId}` } : {}
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.BULK_UPDATE_ASSET_METADATA,
          value: JSON.stringify(data),
        },
        user: user,
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.BULK_UPDATE_ASSET_METADATA,
      },
    })
  },

  trackSearchHighlights(highlights = {}, loginId = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    const userData = {
      id: `taskId: ${searchId}`,
    }

    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.SEARCH_HIGHLIGHTS,
          value: JSON.stringify(highlights),
        },
        user: userData,
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.SEARCH_HIGHLIGHTS,
      },
    })
  },

  trackPublishSearchClick(
    action = '',
    actionType = '',
    searchTerm = '',
    filterData = '',
    loginId = ''
  ) {
    firefly.initalizeSearchId()
    const application = this.getApplication(loginId)
    const userData = {
      customDimension1: `publishActionType# ${actionType}`,
      customDimension2: `publishSearchTerm# ${searchTerm}`,
      customDimension3: `publishFilters# ${filterData}`,
      id: `publishTaskId: ${searchId}`,
    }

    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        user: userData,
        customInteraction: {
          key: searchFilterKey(searchTerm),
          value: `${searchTerm}`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )
    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: action,
      },
    })
  },

  trackPublishAssetCardDownloads(eventType = '', downloadAsset, loginId = '') {
    firefly.initalizeSearchId()
    const application = this.getApplication(loginId)

    const userData = {
      customDimension2: `downloadFiles# ${JSON.stringify(downloadAsset)}`,
      id: `taskId: ${searchId}`,
    }
    initializeFirefly()

    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        user: userData,
        customInteraction: {
          key: 'downloadCount',
          value: `1`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )
    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: eventType,
      },
    })
  },

  trackPublishAssetCopy(data = {}, loginId = '') {
    firefly.initalizeSearchId()
    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.PUBLISH_ASSET_COPY,
          value: JSON.stringify(data),
          id: `taskId: ${searchId}`,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.PUBLISH_ASSET_COPY,
      },
    })
  },

  trackHomePageClicks(loginId = '', clickType = '') {
    const application = this.getApplication(loginId)
    initializeFirefly()
    Firefly.setGlobalDataLayer(
      {
        application,
        employee: { loginId },
        customInteraction: {
          key: Analytics.eventType.CLICK,
          value: clickType,
        },
      },
      fireflyInternalAppConfig.default.persistMethod
    )

    const eventManager = Firefly.getEventManager(
      fireflyInternalAppConfig.default
    )
    let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
    trackSession(loginId, eventTimestamp)
    eventManager.addEvent({
      event: {
        serverTimestamp: eventTimestamp,
        type: Analytics.eventType.CLICK,
      },
    })
  }
}
export default firefly

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Clear from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
//import Replay from '@mui/icons-material/Replay'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import ButtonBase from '@mui/material/ButtonBase'
import ReactHtmlParser from 'react-html-parser'

// cancel revision dependencies
import Moment from 'moment'
import SortIcon from '@mui/icons-material/Sort'
import Paper from '@mui/material/Paper'
import FilterList from '@mui/icons-material/FilterList'
import FormControl from '@mui/material/FormControl'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import BackupIcon from '@mui/icons-material/Backup'
import ErrorIcon from '@mui/icons-material/Error'
// cancel revision dependencies
import Select from '@mui/material/Select'

import cx from 'classnames'
import { bytesToSize } from '../../helpers/UploadHelper'
import { UPLOAD_FILE_SIZE_LIMIT } from '../../constants/projects'
import { withoutClassificationArray } from '../../constants/classification'
import { Mimetypes, FileExtensions } from '../../helpers/MimetypeHelper'
import {
  getSelectedClassification,
  getClassificationId,
  getFirstItemOfData,
} from '../../helpers/classificationHelper'
import RemoteImageLoader from '../RemoteImageLoader'
import newFileNoArrow from '../../images/revision/new-no-arrow.svg?url'
import revisionFile from '../../images/revision/Revision.svg?url'

import ToggleNewRevisionBlock from './ToggleNewRevision'
import { isMemberBelongToRoundel } from '../../helpers/UtilityHelper'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: useTheme().spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 400,
    position: 'relative',
  },
  fileterIcons: {
    position: 'absolute',
    bottom: '25px',
  },
  posRelative: {
    position: 'relative',
  },
  wrapper: {
    margin: useTheme().spacing(),
    position: 'relative',
  },
  button: {
    backgroundColor: '#0D46A0',
    '&:hover': {
      backgroundColor: '#0D46A0',
    },
  },
  dialogTitleBg: {
    backgroundColor: '#5370d2',
  },
  dialogTitle: {
    color: '#ffffff',
  },
  selectDisabled: {
    backgroundColor: '#dddddd',
  },
  iconButton: {
    float: 'right',
    color: '#ffffff',
    height: 'auto',
    padding: 'unset',
    '&:hover': {
      backgroundColor: '#5370d2',
    },
  },
  versionTable: {
    backgroundColor: '#F9F9F9',
  },
  versionTd: {
    padding: '0 !important',
    borderBottom: 'none',
  },
  borderBottomNone: {
    borderBottom: 'none',
  },
  tableTr: {
    height: '75px!important',
    WebkitTextFillColor: 'rgba(0, 0, 0, 0.54)',
  },
  padding10: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  textCenter: {
    textAlign: 'center',
  },
  avatar: {
    margin: '0 auto',
    width: 51,
    height: 51,
  },
  textField: {
    marginLeft: useTheme().spacing(),
    marginRight: useTheme().spacing(),
    fontWeight: 400,
    color: '#484848',
  },
  floatingLabelFocusStyle: {
    color: '#484848 !important',
  },
  container: {
    padding: '0 40px',
  },
  TableHead: {
    fontSize: '15px',
    fontWeight: '700',
    color: 'rgba(0, 0, 0, 0.46)',
  },
  heading: {
    marginBottom: 0,
    fontWeight: 400,
    color: '#484848',
  },
  revisionButton: {
    boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
    padding: 6,
  },
  reversionTr: {
    backgroundColor: '#FFFEDC',
  },
  revisionHelper: {
    color: '#C7B360',
  },
  toggleImg: {
    height: '25px',
    width: '25px',
    marginRight: '5px',
    verticalAlign: 'middle',
  },
  uploadSummary: {
    margin: '20px 10px 20px 40px',
    color: '#484848 !important',
    fontSize: '15px',
    fontWeight: 380,
  },
  group: {
    display: 'inline-block',
    paddingRight: '35px',
  },
  deleteIcon: {
    color: '#0D46A0 !important',
  },
  radio: {
    '&.Mui-checked': {
      color: '#0D46A0 !important',
    },
    '&.Mui-checked+label': {
      fontWeight: 600,
    },
  },
  textMessageWidth: {
    width: 188,
    fontWeight: 500,
  },
  sizeCellRoot: {
    WebkitTextFillColor: '#ff0000',
  },
}))
export class UploadDialog extends React.Component {
  constructor(props) {
    super(props)
    let files = [...props.files] || []
    let newFilesCount = 0
    let revisionFilesCount = 0

    const {
      selectedClassification = '',
      selectedAssetName = '',
      selectedClassificationId = '',
    } = props
    files.forEach(function (fileValue) {
      fileValue.version = 1
      fileValue.asset_master_id = ''
      fileValue.asset_master_uoi_id = ''
      fileValue.toggleType = 'new'
      fileValue.initToggleType = 'new'
      fileValue.cancelRevision = false
      fileValue.creative_asset_status = 'Approved'
      fileValue.classificationName = selectedClassification
      fileValue.assetType = selectedAssetName
      fileValue.classificationId = selectedClassificationId
      let latestAsset = props.revisionAssetsMap[fileValue.name] || {}
      if (!isEmpty(latestAsset)) {
        const {
          asset_sync_status = '',
          status = '',
          asset_master_id = '',
          asset_version = 1,
          asset_master_uoi_id = '',
          initiate_time = '',
        } = latestAsset
        let isAssetError = [status, asset_sync_status].filter(
          (element = '') => {
            const assetStatus = element !== null ? element.toLowerCase() : ''
            return ['not_started', 'error', 'in_progress'].includes(assetStatus)
          }
        )
        fileValue.version =
          !asset_master_id || isAssetError.length > 0
            ? asset_version
            : asset_version + 1
        fileValue.asset_master_id = asset_master_id
        fileValue.asset_master_uoi_id = asset_master_uoi_id
        fileValue.lastLoginTime = new Date(initiate_time).getTime()
        fileValue.toggleType = 'revision'
        fileValue.initToggleType = 'revision'
        fileValue.cancelRevision = true
        if (
          !asset_master_id ||
          (isAssetError.length > 0 && fileValue.version <= 1)
        ) {
          fileValue.toggleType = 'new'
          fileValue.initToggleType = 'new'
          fileValue.cancelRevision = false
        }
      }
      if (fileValue.version > 1) {
        revisionFilesCount = revisionFilesCount + 1
      } else {
        newFilesCount = newFilesCount + 1
      }
    })
    this.state = {
      files: files,
      newFilesCount,
      revisionFilesCount,
      creativeAssetStatus: 'Approved',
      uploadButtonLabel: 'UPLOAD AS APPROVED',
    }
  }

  handleChange = (index, name) => (event) => {
    let selectedFile = [...this.state.files]
    selectedFile[index][name] = event.target.value
    this.setState({
      files: selectedFile,
    })
  }
  uploadTypeChange = (event) => {
    const files = [...this.state.files]
    const assetStatus = event.target.value || 'WIP'
    const labels = { Approved: 'UPLOAD AS APPROVED', WIP: 'UPLOAD AS WIP' }
    files.forEach(function (fileValue) {
      fileValue.creative_asset_status = assetStatus
    })
    this.setState({
      files: files,
      creativeAssetStatus: assetStatus,
      uploadButtonLabel: labels[assetStatus],
    })
  }

  handleClassificationChange = (event = {}, index) => {
    const { target = {} } = event
    const { value = '' } = target
    const { classificationTypes = [] } = this.props
    let selectedFile = [...this.state.files]
    selectedFile[index]['classificationName'] = value || ''
    const selectedClassification = getSelectedClassification(
      classificationTypes,
      value
    )
    let assetsList = selectedClassification?.asset_types || []
    if (assetsList.length) {
      selectedFile[index]['assetType'] = assetsList[0].name
      selectedFile[index]['classificationId'] = assetsList[0].classification_id
    } else {
      selectedFile[index]['assetType'] = ''
      selectedFile[index]['classificationId'] = ''
    }
    this.setState({
      files: selectedFile,
    })
  }

  handleAssettypeChange = (event = {}, index) => {
    const { target = {} } = event
    const { value = '' } = target
    let selectedFile = [...this.state.files]
    const { classificationTypes = [] } = this.props
    const selectedClassificationName = selectedFile[index]['classificationName']
    selectedFile[index]['assetType'] = value || ''
    selectedFile[index]['classificationId'] = getClassificationId(
      classificationTypes,
      selectedClassificationName,
      value
    )
    this.setState({
      files: selectedFile,
    })
  }

  toggleNewRevisionFun = (event, index, fileType = 'revision') => {
    if (this.props.AllAssets && this.props.AllAssets.length > 0) {
      this.enableVersionException(index, fileType)
    }
  }
  enableVersionException = (index, toggleType = 'revision') => {
    let selectedFile = [...this.state.files]
    if (
      selectedFile[index] &&
      selectedFile[index].toggleType === toggleType &&
      toggleType === 'new'
    ) {
      return
    }
    if (selectedFile[index] && selectedFile[index].toggleType !== toggleType) {
      if (selectedFile[index].versionExceptionActive) {
        delete selectedFile[index].versionExceptionActive
        delete selectedFile[index].filterValue
        delete selectedFile[index].fileType
      } else {
        selectedFile[index].versionExceptionActive = true
        selectedFile[index].fileType = selectedFile[index].name.split('.').pop()
      }
    }
    if (selectedFile[index]) {
      selectedFile[index].toggleType = toggleType
      if (
        toggleType &&
        selectedFile[index].cancelRevision &&
        toggleType === 'new'
      ) {
        selectedFile[index].versionExceptionActive = false
        this.resetRevision(index)
      }
      if (
        toggleType &&
        selectedFile[index].cancelRevision &&
        toggleType === 'revision'
      ) {
        selectedFile[index].versionExceptionActive = true
      }
    }
    this.setState({
      files: selectedFile,
    })
  }

  setRevision = (asset, index) => {
    let selectedFile = [...this.state.files]
    selectedFile[index].versionedFile = asset
    selectedFile[index].versionExceptionActive = false
    selectedFile[index].filterValue = ''
    selectedFile[index].version = asset.asset_version + 1
    selectedFile[index].asset_master_id = asset.asset_master_id
    selectedFile[index].asset_master_uoi_id = asset.asset_master_uoi_id
    selectedFile[index].toggleType = 'revision'
    selectedFile[index].cancelRevision = true
    let newFilesCount = this.state.newFilesCount - 1
    let revisionFilesCount = this.state.revisionFilesCount + 1
    this.setState({
      files: selectedFile,
      newFilesCount,
      revisionFilesCount,
    })
  }

  deleteFile = (index) => {
    let files = [...this.state.files]
    let newFilesCount = this.state.newFilesCount
    let revisionFilesCount = this.state.revisionFilesCount
    if (files[index].version > 1) {
      revisionFilesCount = revisionFilesCount - 1
    } else {
      newFilesCount = newFilesCount - 1
    }
    files.splice(index, 1)
    this.setState({
      files: files,
      newFilesCount,
      revisionFilesCount,
    })
  }

  resetRevision = (index) => {
    let selectedFile = [...this.state.files]
    selectedFile[index].version =
      selectedFile[index].toggleType &&
      selectedFile[index].toggleType === 'new' &&
      selectedFile[index].cancelRevision
        ? Number(1)
        : selectedFile[index].version -
          selectedFile[index].versionedFile.asset_version
    delete selectedFile[index].versionedFile
    delete selectedFile[index].versionExceptionActive
    delete selectedFile[index].filterValue
    delete selectedFile[index].fileType
    selectedFile[index].asset_master_id = ''
    selectedFile[index].asset_master_uoi_id = ''
    selectedFile[index].toggleType = 'new'
    selectedFile[index].cancelRevision = false
    let newFilesCount = this.state.newFilesCount + 1
    let revisionFilesCount = this.state.revisionFilesCount - 1
    this.setState({
      files: selectedFile,
      newFilesCount,
      revisionFilesCount,
    })
  }

  getVersionTable = () => {
    const { files = [] } = this.state
    const {
      classes = {},
      AllAssets = [],
      classificationTypes = [],
      memberOf=[]
    } = this.props
    let versionTable = [],
      fileExtentionTypes
    let fileSizeExceededList = []
    const isRoundelUser = isMemberBelongToRoundel(memberOf)
    const classificationDropList = isRoundelUser ? classificationTypes: classificationTypes.concat(
      withoutClassificationArray
    )
    //file extentions as per existing aseets in current project
    fileExtentionTypes =
      (AllAssets &&
        AllAssets.map(
          (elem) => elem.asset_name && elem.asset_name.split('.').pop()
        )) ||
      FileExtensions
    fileExtentionTypes = ['All', ...new Set(fileExtentionTypes)]
    files &&
      files.forEach((file, index) => {
        let version,
          filteredAssets = [],
          keyIndex,
          trClassName,
          rowfileType
        const {
          fileType = 'All',
          name: fname = '',
          filterValue = '',
          toggleType = 'new',
          versionedFile,
          type: ftype = '',
          version: fversion = 1,
          size: fsize = '',
          versionExceptionActive = false,
          initToggleType = 'new',
          classificationName = '',
          assetType = '',
        } = file
        let selectedClassification =
          getSelectedClassification(classificationTypes, classificationName) ||
          {}
        let assetsList = selectedClassification['asset_types'] || []
        //filter type as per dropped file extension
        rowfileType =
          fileExtentionTypes && fileExtentionTypes.indexOf(fileType) === -1
            ? 'All'
            : fileType || (fname && fname.split('.').pop()) || 'All'
        filteredAssets =
          AllAssets &&
          AllAssets.filter(
            (elem) =>
              elem.asset_id &&
              (rowfileType === 'All' ||
                (rowfileType !== 'All' &&
                  elem.asset_name.split('.').pop() === rowfileType))
          )
        if (!filteredAssets.length) {
          rowfileType = 'All'
          filteredAssets =
            AllAssets && AllAssets.filter((elem) => elem.asset_id)
        }
        if (filterValue) {
          filteredAssets =
            filteredAssets &&
            filteredAssets.filter((elem) =>
              elem.asset_name.includes(filterValue)
            )
        }

        version = (
          <ToggleNewRevisionBlock
            findex={index}
            toggleType={toggleType}
            onToggle={this.toggleNewRevisionFun}
          />
        )
        trClassName =
          versionedFile ||
          (toggleType && toggleType === 'new' && initToggleType === 'revision')
            ? classes.tableTr + ' ' + classes.reversionTr
            : classes.tableTr
        keyIndex = versionTable.length

        if (UPLOAD_FILE_SIZE_LIMIT <= fsize) {
          fileSizeExceededList.push(
            <TableRow key={keyIndex} className={trClassName}>
              <TableCell
                className={classes.padding10 + ' ' + classes.textCenter}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Mimetypes[ftype] || Mimetypes['default']}
                  className={classes.avatar}
                />
              </TableCell>
              <TableCell className={classes.padding10}>
                <div>{fname}</div>
              </TableCell>
              <TableCell
                className={cx(
                  classes.padding10,
                  classes.textCenter,
                  classes.textMessageWidth
                )}
              >
                This file exceeds our limit of 2GB and won't be uploaded
              </TableCell>
              <TableCell
                align="right"
                className={classes.padding10 + ' ' + classes.textCenter}
              >
                -
              </TableCell>
              <TableCell
                align="right"
                className={cx(
                  classes.padding10,
                  classes.textCenter,
                  classes.sizeCellRoot
                )}
              >
                <span
                  style={{
                    verticalAlign: 'super',
                    marginLeft: 12,
                    marginRight: 5,
                  }}
                >
                  {bytesToSize(fsize, true)}
                </span>
                <span>
                  <ErrorIcon
                    style={{ color: '#ff0000', fontSize: 20 }}
                    fontSize="small"
                  />
                </span>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          )
        } else {
          versionTable.push(
            <TableRow key={keyIndex} className={trClassName}>
              <TableCell
                className={classes.padding10 + ' ' + classes.textCenter}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Mimetypes[ftype] || Mimetypes['default']}
                  className={classes.avatar}
                />
              </TableCell>
              <TableCell className={classes.padding10}>
                <div>{fname}</div>
                {versionedFile ? (
                  <div className={classes.revisionHelper}>
                    Revised: {versionedFile.asset_name}
                  </div>
                ) : (
                  ''
                )}
                {toggleType &&
                toggleType === 'new' &&
                initToggleType === 'revision' ? (
                  <div className={classes.revisionHelper}>Uploading as New</div>
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell className={classes.textCenter}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    labelId="classification-label"
                    id="classification-select"
                    value={
                      classificationName
                        ? classificationName
                        : getFirstItemOfData(
                            classificationDropList,
                            'classification_name'
                          )
                    }
                    displayEmpty
                    onChange={(event) =>
                      this.handleClassificationChange(event, index)
                    }
                  >
                    {classificationDropList.map((classification = {}) => {
                      const { classification_name = '' } = classification
                      return (
                        <MenuItem value={classification_name}>
                          {classification_name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell className={classes.textCenter}>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    labelId="classification-label"
                    id="classification-select"
                    value={
                      assetType
                        ? assetType
                        : getFirstItemOfData(assetsList, 'name')
                    }
                    displayEmpty
                    onChange={(event) =>
                      this.handleAssettypeChange(event, index)
                    }
                    disabled={!assetsList.length}
                    className={assetsList.length ? '' : classes.selectDisabled}
                  >
                    {assetsList.map((assetType = {}) => {
                      const { name = '' } = assetType
                      return <MenuItem value={name}>{name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell
                className={classes.padding10 + ' ' + classes.textCenter}
              >
                {version}
              </TableCell>
              <TableCell
                align="right"
                className={classes.padding10 + ' ' + classes.textCenter}
              >
                {fversion}
              </TableCell>
              <TableCell
                align="right"
                className={classes.padding10 + ' ' + classes.textCenter}
              >
                {bytesToSize(fsize)}
              </TableCell>
              <TableCell
                align="right"
                className={classes.padding10 + ' ' + classes.textCenter}
              >
                <IconButton
                  aria-label="Delete"
                  onClick={() => {
                    this.deleteFile(index)
                  }}
                  size="large"
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        }
        if (versionExceptionActive) {
          versionTable.push(
            <TableRow key={keyIndex + 1} className={classes.versionTable}>
              <TableCell className={classes.versionTd} colSpan={6}>
                <div className={classes.container}>
                  <div style={{ paddingLeft: '10px' }}>
                    <div>
                      <h3 className={classes.heading}>
                        Select the file you would like to revise:
                      </h3>
                    </div>
                    <div>
                      <Grid container spacing={3}>
                        <Grid item className={classes.posRelative}>
                          <Paper
                            sx={{
                              display: { xs: 'none', sm: 'none', md: 'none' },
                            }}
                          >
                            <SortIcon className={classes.fileterIcons} />
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            variant="standard"
                            id="file-type"
                            select
                            label="Sort By File Type"
                            className={classes.textField}
                            value={rowfileType}
                            onChange={this.handleChange(index, 'fileType')}
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                            }}
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu,
                              },
                            }}
                            fullWidth
                            margin="normal"
                          >
                            {fileExtentionTypes.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item className={classes.posRelative}>
                          <Paper
                            sx={{
                              display: { xs: 'none', sm: 'none', md: 'none' },
                            }}
                          >
                            <FilterList className={classes.fileterIcons} />
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            variant="standard"
                            id="filter"
                            label="Filter By"
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                            }}
                            className={classes.textField}
                            value={filterValue}
                            onChange={this.handleChange(index, 'filterValue')}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow style={{ visibility: 'hidden', height: 0 }}>
                        <TableCell
                          className={
                            classes.padding10 + ' ' + classes.borderBottomNone
                          }
                        >
                          File type
                        </TableCell>
                        <TableCell
                          className={
                            classes.padding10 + ' ' + classes.borderBottomNone
                          }
                        >
                          File name
                        </TableCell>
                        <TableCell
                          className={
                            classes.padding10 +
                            ' ' +
                            classes.textCenter +
                            ' ' +
                            classes.borderBottomNone
                          }
                        >
                          Revision/New
                        </TableCell>
                        <TableCell
                          className={
                            classes.padding10 +
                            ' ' +
                            classes.textCenter +
                            ' ' +
                            classes.borderBottomNone
                          }
                        >
                          Revision Number
                        </TableCell>
                        <TableCell
                          className={
                            classes.padding10 +
                            ' ' +
                            classes.textCenter +
                            ' ' +
                            classes.borderBottomNone
                          }
                        >
                          File Size
                        </TableCell>
                        <TableCell
                          className={
                            classes.padding10 +
                            ' ' +
                            classes.textCenter +
                            ' ' +
                            classes.borderBottomNone
                          }
                        >
                          Remove
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredAssets &&
                        filteredAssets.map((asset, key) => {
                          const {
                            render_urls: { thumbnail_asset_url = '' } = {},
                            asset_name = '',
                            asset_import_date = '',
                            asset_version,
                            mime_type = ''
                          } = asset
                          return (
                            <TableRow key={key} className={classes.tableTr}>
                              <TableCell
                                className={
                                  classes.padding10 +
                                  ' ' +
                                  classes.borderBottomNone
                                }
                              >
                                <RemoteImageLoader
                                  Src={thumbnail_asset_url}
                                  mimeType = {mime_type}
                                  AltText={'image'}
                                  imgStyle={{
                                    maxWidth: 50,
                                    maxHeight: 50,
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                className={
                                  classes.padding10 +
                                  ' ' +
                                  classes.borderBottomNone
                                }
                                colSpan={2}
                              >
                                {asset_name}
                              </TableCell>
                              <TableCell
                                className={
                                  classes.padding10 +
                                  ' ' +
                                  classes.textCenter +
                                  ' ' +
                                  classes.borderBottomNone
                                }
                              >
                                {Moment.utc(asset_import_date).format(
                                  'MMM DD, YYYY h:mm:ss a'
                                )}
                              </TableCell>
                              <TableCell
                                className={
                                  classes.padding10 +
                                  ' ' +
                                  classes.textCenter +
                                  ' ' +
                                  classes.borderBottomNone
                                }
                              >
                                {asset_version}
                              </TableCell>
                              <TableCell
                                className={
                                  classes.padding10 +
                                  ' ' +
                                  classes.textCenter +
                                  ' ' +
                                  classes.borderBottomNone
                                }
                              >
                                &nbsp;
                              </TableCell>
                              <TableCell
                                className={
                                  classes.padding10 +
                                  ' ' +
                                  classes.textCenter +
                                  ' ' +
                                  classes.borderBottomNone
                                }
                              >
                                <ButtonBase
                                  aria-label="Revision"
                                  className={classes.revisionButton}
                                  onClick={() => {
                                    this.setRevision(asset, index)
                                  }}
                                >
                                  <img
                                    alt={'revision file'}
                                    src={revisionFile}
                                    style={{ width: '30px' }}
                                  />
                                </ButtonBase>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </div>
              </TableCell>
            </TableRow>
          )
        }
      })
    return [...fileSizeExceededList, ...versionTable]
  }

  render() {
    const { classes = {}, files = [] } = this.props
    const {
      files: finalFiles = [],
      newFilesCount = 0,
      revisionFilesCount = 0,
      creativeAssetStatus = 'Approved',
      uploadButtonLabel = 'UPLOAD AS APPROVED',
    } = this.state
    let dialogTitle = (
      <div className={classes.dialogTitle}>
        Upload Assets
        <IconButton
          className={classes.iconButton}
          aria-label="Delete"
          onClick={this.props.onClose}
          size="large"
        >
          <Clear />
        </IconButton>
      </div>
    )
    let filesToUpload = finalFiles.filter(
      (file) => file.size <= UPLOAD_FILE_SIZE_LIMIT
    )
    let disableItem = !filesToUpload.length
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        maxWidth={'lg'}
        fullWidth
      >
        <DialogTitle className={classes.dialogTitleBg} id="scroll-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <div className={classes.uploadSummary}>
            <span style={{ fontWeight: 500 }}>
              {files.length} Total Files: &nbsp;&nbsp;
            </span>
            <img
              className={classes.toggleImg}
              alt="new files"
              src={newFileNoArrow}
            />
            <span> {newFilesCount} New Files &nbsp;&nbsp;</span>
            <img
              className={classes.toggleImg}
              alt="new files"
              src={revisionFile}
            />
            <span> {revisionFilesCount} Revisions &nbsp;&nbsp;</span>
          </div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={
                    classes.TableHead +
                    ' ' +
                    classes.padding10 +
                    ' ' +
                    classes.textCenter
                  }
                >
                  File type
                </TableCell>
                <TableCell
                  className={classes.TableHead + ' ' + classes.padding10}
                >
                  File name
                </TableCell>
                <TableCell
                  className={classes.TableHead + ' ' + classes.padding10}
                >
                  Classification
                </TableCell>
                <TableCell
                  className={classes.TableHead + ' ' + classes.padding10}
                >
                  Asset Type
                </TableCell>
                <TableCell
                  className={
                    classes.TableHead +
                    ' ' +
                    classes.padding10 +
                    ' ' +
                    classes.textCenter
                  }
                >
                  New | Revision
                </TableCell>
                <TableCell
                  className={
                    classes.TableHead +
                    ' ' +
                    classes.padding10 +
                    ' ' +
                    classes.textCenter
                  }
                >
                  Revision Number
                </TableCell>
                <TableCell
                  className={
                    classes.TableHead +
                    ' ' +
                    classes.padding10 +
                    ' ' +
                    classes.textCenter
                  }
                >
                  File Size
                </TableCell>
                <TableCell
                  className={
                    classes.TableHead +
                    ' ' +
                    classes.padding10 +
                    ' ' +
                    classes.textCenter
                  }
                >
                  Remove
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.getVersionTable()}</TableBody>
          </Table>
        </DialogContent>
        <DialogActions
          style={{ backgroundColor: '#F7F7F7', padding: '8px 4px', margin: 0 }}
        >
          <FormControl
            variant="standard"
            component="fieldset"
            className={classes.formControl}
          >
            <RadioGroup
              aria-label="Upload Option"
              name="creative_asset_status"
              className={classes.group}
              value={creativeAssetStatus}
              onChange={this.uploadTypeChange}
            >
              <FormControlLabel
                style={{ paddingRight: '30px' }}
                value="WIP"
                control={
                  <Radio
                    data-cy="wipOption"
                    classes={{ root: classes.radio, checked: classes.checked }}
                    disabled={disableItem}
                  />
                }
                label={ReactHtmlParser(
                  '<div style="margin-top:20px; color:rgba(0, 0, 0, 0.87)">Upload as WIP <div style="color:rgba(0, 0, 0, 0.54);font-size:10px;">Visible only within project</div></div>'
                )}
              />
              <FormControlLabel
                value="Approved"
                control={
                  <Radio
                    data-cy="approvedOption"
                    classes={{ root: classes.radio, checked: classes.checked }}
                    disabled={disableItem}
                  />
                }
                label={ReactHtmlParser(
                  '<div style="margin-top:20px; color:rgba(0, 0, 0, 0.87)">Upload as Approved <div style="color:rgba(0, 0, 0, 0.54);font-size:10px;">Searchable within AssetHub</div></div>'
                )}
              />
            </RadioGroup>
          </FormControl>
          <Button
            data-cy="uploadDialogButton"
            variant="contained"
            id="upload-button"
            disabled={disableItem}
            className={classes.button}
            onClick={() => {
              this.props.onUpload(filesToUpload)
            }}
            color="primary"
          >
            <BackupIcon style={{ marginRight: '20px', float: 'left' }} />
            <span style={{ width: '140px' }}>{uploadButtonLabel}</span>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

UploadDialog.propTypes = {
  AllAssets: PropTypes.arrayOf(
    PropTypes.shape({
      asset_name: PropTypes.string,
      asset_thumbnail_path: PropTypes.string,
      asset_version: PropTypes.number || PropTypes.string,
    })
  ),
  classes: PropTypes.object.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileType: PropTypes.string,
      filterValue: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.number,
      version: PropTypes.number,
      versionExceptionActive: PropTypes.bool,
      versionedFile: PropTypes.object,
      type: PropTypes.string,
    })
  ),
  onClose: PropTypes.func,
  onUpload: PropTypes.func,
  open: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const { assetClassificationReducer = {} } = state
  const {
    classificationTypes = [],
    selectedClassification = '',
    selectedClassificationId = '',
    selectedAssetName = '',
  } = assetClassificationReducer
  return {
    classificationTypes,
    selectedClassification,
    selectedClassificationId,
    selectedAssetName,
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <UploadDialog {...props} classes={classes} />
}

export default connect(mapStateToProps)(MyComponent)

import { useState, useEffect } from 'react'
import cx from 'classnames'
import {
  FormControl,
  Input,
  InputLabel,
  Chip,
  InputAdornment,
} from '@mui/material'

import CollapsibleCard from '../../components/Custom/CollapsibleCard'
import {
  METADATA_CARD_SHOW_BUTTON_TEXT,
  METADATA_CARD_HIDE_BUTTON_TEXT,
} from '../../constants/metadata'
import { separateFilenameExtension, convertUTCtoLocal } from '../../utils/utils'
import CustomAvatar from '../../../components/Custom/CustomAvatar'
import { useMetadataContext } from './MetadataContext'

const AssetDetailsCard = ({ containerClasses = '' }) => {
  const {
    setIsMetadataUnsaved = () => { },
    updateMetadata = () => { },
    assetMetadata = {},
    isEditMode = false,
  } = useMetadataContext()

  const {
    metadata: { file_metadata = {} } = {},
    uploaded_date = '',
    uploaded_by = '',
    revision_number = '',
  } = assetMetadata
  const {
    file_name = '',
    file_size_mb = '',
    pixel_dimensions = '',
    resolution = '',
    created_by = '',
    created_date = '',
    person_shown = [],
  } = file_metadata || {}
  const uploadDateFormatted = convertUTCtoLocal(uploaded_date, 'lll') || ''
  const originalCreationDateFormatted = created_date
    ? convertUTCtoLocal(created_date, 'll')
    : ''
  const { fileExt = '' } = separateFilenameExtension(file_name)
  const fileSizeMb = file_size_mb ? `${file_size_mb} MB` : ''

  const [fileNameInput, setFileNameInput] = useState(file_name)
  const [creatorInput, setCreatorInput] = useState(created_by)
  const [personShownInput, setPersonShownInput] = useState(person_shown?.join(',') || '')

  useEffect(() => {
    if (fileNameInput !== file_name) {
      setIsMetadataUnsaved(true)
      updateMetadata('file_name', fileNameInput)
    } else {
      setIsMetadataUnsaved(false)
    }
  }, [fileNameInput])

  useEffect(() => {
    if (creatorInput !== created_by) {
      setIsMetadataUnsaved(true)
      updateMetadata('created_by', creatorInput)
    } else {
      setIsMetadataUnsaved(false)
    }
  }, [creatorInput])

  useEffect(() => {
    if (personShownInput !== person_shown?.join(',') || '') {
      setIsMetadataUnsaved(true)
      updateMetadata('person_shown', personShownInput.split(',').filter(data=> data !== ''))
    } else {
      setIsMetadataUnsaved(false)
    }
  }, [personShownInput])

  const fileRenameHandler = (extension) => (event) => {
    const updatedFileName = event.target.value || ''
    const fullFilename = `${updatedFileName}${extension}`
    setFileNameInput(fullFilename)
  }

  return (
    <CollapsibleCard
      cardTitle={'Asset Details'}
      hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
      showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
      defaultCollapse={true}
    >
      <div className={containerClasses.metadataCardContent}>
        {isEditMode && (
          <FormControl
            variant="standard"
            className={cx(
              containerClasses.formControl,
              containerClasses.fullWidth,
            )}
          >
            <InputLabel htmlFor="adFileName" shrink>
              File Name
            </InputLabel>
            <Input
              id="adFileName"
              onChange={fileRenameHandler(fileExt)}
              value={separateFilenameExtension(fileNameInput)['fileName']}
              fullWidth
              multiline
              endAdornment={
                <InputAdornment position="end">
                  {separateFilenameExtension(fileNameInput)['fileExt']}
                </InputAdornment>
              }
              inputProps={{ 'data-cy': 'fileName' }}
            />
          </FormControl>
        )}
        {!isEditMode && (
          <FormControl
            variant="standard"
            className={cx(
              containerClasses.formControl,
              containerClasses.fullWidth,
            )}
            disabled
          >
            <InputLabel htmlFor="adFileName">File Name</InputLabel>
            <Input id="adFileName" value={file_name} fullWidth multiline />
          </FormControl>
        )}
        {uploadDateFormatted && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="adUploadDate">Upload Date</InputLabel>
            <Input
              id="adUploadDate"
              value={uploadDateFormatted}
              disableUnderline
            />
          </FormControl>
        )}
        {uploaded_by && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="adUploadedBy" shrink>
              Uploaded By
            </InputLabel>
            <div className={containerClasses.formStaticWrap}>
              <Chip
                avatar={
                  <CustomAvatar userDetails={{ displayName: uploaded_by }} />
                }
                id="adUploadedBy"
                label={uploaded_by}
                className={containerClasses.chipComponent}
              />
            </div>
          </FormControl>
        )}
        {revision_number && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="adRevisionNumber">Revision Number</InputLabel>
            <Input
              id="adRevisionNumber"
              value={revision_number}
              disableUnderline
            />
          </FormControl>
        )}
        {fileSizeMb && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="adFileSize">File Size</InputLabel>
            <Input id="adFileSize" value={fileSizeMb} disableUnderline />
          </FormControl>
        )}
        {pixel_dimensions && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="adPixelDimensions">
              Pixel Dimensions
            </InputLabel>
            <Input
              id="adPixelDimensions"
              value={pixel_dimensions}
              disableUnderline
            />
          </FormControl>
        )}
        {resolution && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="adResolution">Resolution</InputLabel>
            <Input id="adResolution" value={resolution} disableUnderline />
          </FormControl>
        )}
        <FormControl
          variant="standard"
          className={containerClasses.formControl}
        >
          <InputLabel htmlFor="adCreator" shrink>
            Creator
          </InputLabel>
          {isEditMode ? (
            <Input
              id="creator"
              onChange={(event) => setCreatorInput(event.target.value)}
              value={creatorInput}
              fullWidth
              multiline
              inputProps={{ 'data-cy': 'creator' }}
            />
          ) : (
            <div className={containerClasses.formStaticWrap}>
              <Chip
                avatar={
                  <CustomAvatar
                    userDetails={{ displayName: created_by || 'Not Provided' }}
                  />
                }
                id="adCreator"
                label={created_by || 'Not Provided'}
                className={containerClasses.chipComponent}
              />
            </div>
          )}
        </FormControl>
        <FormControl
          variant="standard"
          className={containerClasses.formControl}
        >
          <InputLabel htmlFor="ogCreation">Original Creation Date</InputLabel>
          <Input
            id="ogCreation"
            value={originalCreationDateFormatted || 'Not Provided'}
            disableUnderline
          />
        </FormControl>
        <FormControl
          variant="standard"
          className={containerClasses.personShownFormControl}
        >
          <InputLabel htmlFor="modelAgency">Model Name/Agency</InputLabel>
          {
            isEditMode ? (
              <Input
                id="personShown"
                onChange={(event) => setPersonShownInput(event.target.value)}
                value={personShownInput}
                fullWidth
                inputProps={{ 'data-cy': 'personShown' }}
              />
            )
              : (

                <Input
                  id="modelAgency"
                  value={
                    (person_shown?.length && person_shown.join(', ')) ||
                    'Not Provided'
                  }
                  disableUnderline
                />
              )
          }
        </FormControl>
      </div>
    </CollapsibleCard>
  )
}

export default AssetDetailsCard

import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import HorizontalFilters from './HorizontalFilters.js'
import FilterByImage from './FilterByImage'
import RefreshSearch from './RefreshSearch'
import ReverseImageSearch from './ReverseImageSearch'
import SearchInputBox from './SearchInputBox.js'
import AppliedFiltersLayout from './AppliedFiltersLayout'
import AssetMetadata from '../Assets/AssetMetadata'

const useStyles = makeStyles({
  filterLayoutDiv: { flexDirection: 'column', padding: '0px 15px' },
  appliedFiltersDiv: { paddingTop: '15px' },
  searchInputDiv: { flexDirection: 'row', alignItems: 'center', gap: '15px' },
  horizontalFiltersDiv: { paddingTop: '10px' },
})

function FiltersLayout() {
  const classes = useStyles()

  return (
    <Grid container className={classes.filterLayoutDiv}>
      <Grid item className={classes.horizontalFiltersDiv}>
        <HorizontalFilters />
      </Grid>
      <Grid item className={classes.appliedFiltersDiv}>
        <AppliedFiltersLayout />
      </Grid>
      <FilterByImage />
      <AssetMetadata />
    </Grid>
  )
}

export default FiltersLayout

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { filter, findIndex, orderBy, isEqual, isEmpty } from 'lodash'
import Alert from '@mui/material/Alert'
import CheckIcon from '@mui/icons-material/Check'
import Chip from '@mui/material/Chip'
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material'
import classNames from 'classnames'
import PauseIcon from '@mui/icons-material/PauseCircleFilled'
import AHAssetTableHead from './AHAssetTableHead'
import noAsset from '../../../../images/no-asset.png'
import ThumbnailBlock from '../ThumbnailBlock'
import { Mimetypes } from '../../../../helpers/MimetypeHelper'
import MetadataPreview from '../../../../containers/Metadata'
import { clearAssetRevisions } from '../../../../store/metadata/actionCreator'
import {
  getProjectAssetsWithFilters,
  updateCreativeAssetStatus,
} from '../../../../store/projects/actionCreator'
import withRouter from '../../../../containers/Router/WithRouter'
import { convertCSTtoLocal } from '../../../../helpers/dateHelper'

const styles = makeStyles((theme) => ({
  root: {
    borderCollapse: 'collapse',
    margin: '0px',
    boxShadow: 'none',
  },
  table: {
    maxWidth: '100%',
    padding: '10px',
    height: '100%',
    minHeight: '700px',
    minWidth: '930px',
    '& tr': {
      height: '92px',
    },
    '& td,th': {
      textAlign: 'center',
      padding: '0px',
    },
    '& td:nth-child(3),th:nth-child(3)': {
      textAlign: 'left',
    },
  },
  fileTypeImage: {
    borderRadius: '50% !important',
    maxWidth: '30px !important',
    maxHeight: '30px !important',
  },
  assetError: {
    backgroundColor: '#FBE6E2',
  },
  tableCheckbox: {
    width: useTheme().spacing(),
  },
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'scroll'
  },
  listStatus: {
    color: '#000',
    padding: useTheme().spacing(),
    marginRight: useTheme().spacing(),
    textAlign: 'center',
  },
  listStatusWrap: {
    width: '12%',
    fontSize: '12px',
    textAlign: 'left',
  },
  listNormalWrap: {
    minWidth: '10%',
    fontSize: '12px',
    color: '#666',
  },
  listLinkWrap: {
    width: '6%',
    fontSize: '12px',
    color: '#666',
  },
  listStatusBtn: {
    width: '95%',
  },
  onHoldRow: {
    backgroundColor: 'rgba(239, 239, 239, 1)',
  },
  pageSizeSelector: {
    fontSize: '14px',
    paddingLeft: '60px',
    paddingTop: '15px',
    color: '#666',
    display: 'block',
    zIndex: 1,
  },
  searchPagination: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 20px 0px 20px',
  },
  searchField: {
    width: '75%',
  },
  searchAndDropdown: {
    display: 'flex',
    gap: '10px',
  },
  missingData: {
    backgroundColor: 'unset',
    textTransform: 'capitalize',
    display: 'flex',
    gap: '5px',
  },
  checkIcon: {
    color: 'white',
    backgroundColor: 'green',
    borderRadius: '22px',
  },
  missingItem: {
    borderRadius: '5px',
    padding: '3px',
    fontSize: '12px',
    width: 'fit-content',
    margin: '5px',
  },
  dropDownFilter: {
    height: '40px',
    width: '120px',
  },
  noDataText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50vh',
  },
  loader: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  emptyWidth: {
    width: '100px'
  }
}))

export class AHAssetTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      previewCollection: [],
      currentPreviewAssetIndex: null,
      headingList: [
        {
          id: 'thumbnail',
          numeric: false,
          disablePadding: true,
          label: 'Thumbnail',
          sortable: false,
        },
        {
          id: 'mime_type',
          numeric: false,
          disablePadding: true,
          label: 'Filetype',
          sortable: true,
        },
        {
          id: 'asset_name',
          numeric: false,
          disablePadding: true,
          label: 'Filename',
          sortable: true,
        },
        {
          id: 'asset_version',
          numeric: true,
          disablePadding: true,
          label: 'Revision Number',
          sortable: true,
        },
        {
          id: 'secondary_classification',
          numeric: false,
          disablePadding: true,
          label: 'Classification',
          sortable: true,
        },
        {
          id: 'asset_type',
          numeric: false,
          disablePadding: true,
          label: 'Asset Type',
          sortable: true,
        },
        {
          id: 'creative_asset_status',
          numeric: false,
          disablePadding: true,
          label: 'Status',
          sortable: true,
        },
        {
          id: 'asset_import_date',
          numeric: false,
          disablePadding: true,
          label: 'Upload Date/Time',
          sortable: true,
        },
        {
          id: 'asset_imported_by',
          numeric: false,
          disablePadding: true,
          label: 'Uploaded By',
          sortable: true,
        },
        {
          id: 'missing_metadata',
          numeric: false,
          disablePadding: true,
          label: 'Missing Metadata',
          sortable: true,
        },
      ],
      renderUrls: {},
      isAllAssetChecked: false,
      isArrow: true,
      searchText: '',
    }
  }
  componentDidMount() {
    const {
      checkUploadProgress = () => { },
      projectAssetCount,
      updateHasPagenation = () => { },
      projectAssets = [],
      pagination = {},
      router = {},
    } = this.props
    const { params = {} } = router
    const displayAssets = this.getAssetListContract(projectAssets)
    checkUploadProgress(projectAssets)
    this.setPreviewCollection(displayAssets)
    const hasMorePages = projectAssetCount > pagination.size
    updateHasPagenation(hasMorePages)
    if (params.pageName === 'metaPage' && params['*']) {
      this.setState({ isArrow: false })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      checkUploadProgress = () => { },
      projectAssets = [],
    } = this.props
    if (!isEqual(prevProps.projectAssets, projectAssets)) {
      const displayAssets = this.getAssetListContract(projectAssets)
      this.setPreviewCollection(displayAssets)
    }
    checkUploadProgress(projectAssets)
  }

  setPreviewCollection = (displayAssets) => {
    const previewCollection = filter(displayAssets, ['inProgress', false])
    this.setState({ previewCollection: previewCollection })
  }

  loadPreview = (assetId, val) => {
    const { previewCollection = [] } = this.state
    const { handleOpenMetaPage = () => { }, projectId = '' } = this.props
    const assetIndex = findIndex(previewCollection, ['id', assetId])
    const { asset_uoi_id: assetUoiId = '' } = val
    this.setState(
      {
        currentPreviewAssetIndex: assetIndex,
      },
      () => {
        handleOpenMetaPage(projectId, assetUoiId)
      },
    )
  }

  loadNextAsset = () => {
    this.navigateAsset('next')
  }

  loadPreviousAsset = () => {
    this.navigateAsset('back')
  }

  navigateAsset = (direction) => {
    const { clearAssetRevisions } = this.props
    const { currentPreviewAssetIndex, previewCollection } = this.state
    clearAssetRevisions()
    const newAssetIndex =
      direction === 'next'
        ? currentPreviewAssetIndex + 1
        : currentPreviewAssetIndex - 1
    if (newAssetIndex > -1 && newAssetIndex < previewCollection.length) {
      this.setState({ currentPreviewAssetIndex: newAssetIndex })
    }
  }

  getAssetListContract = (projectAssets = []) => {
    const { classes = {}, onAssetSelection, selectedAssets = [] } = this.props
    return projectAssets.map((val, index) => {
      if (!val) return
      const {
        status = '',
        asset_id = '',
        asset_uoi_id = '',
        asset_master_uoi_id = '',
        mime_type = '',
        asset_name = '',
        asset_classification = {},
        render_url = '',
        asset_version = 1,
        creative_asset_status = '',
        asset_import_date = '',
        asset_imported_by = '',
        asset_master_id = '',
        missing_metadata = [],
        render_urls = {},
      } = val
      const { thumbnail_asset_url = '' } = render_urls
      const asset_thumbnail_path = thumbnail_asset_url
      const { secondary_classification = '-', asset_type = '-' } =
        asset_classification
      const inProgress = status !== 'ERROR' && asset_thumbnail_path === ''
      const isError = status === 'ERROR' ? 'assetError' : ''
      const assetSelectionData = {
        assetId: asset_uoi_id,
        assetMasterId: asset_master_uoi_id,
        fileName: asset_name,
        renderUrl: render_url,
        render_urls: render_urls,
        imageMasterId: asset_master_id,
        creativeAssetStatus: creative_asset_status,
        status: status,
      }
      const isAssetChecked = !!(selectedAssets || [])?.includes(asset_uoi_id)
      const enablePreview = status === 'COMPLETE'
      const itemData = {
        id: asset_id || (status === 'ERROR' ? Math.random() : index),
        uoId: asset_uoi_id,
        isError: isError,
        inProgress: inProgress,
        fileName: asset_name,
        renderUrl: render_url,
        render_urls: render_urls,
        data: [
          {
            value: asset_name,
            type: 'custom',
            numeric: false,
            disablePadding: true,
            custComponent: (
              <div>
                {enablePreview && (
                  <Checkbox
                    checked={isAssetChecked}
                    onChange={(event) =>
                      onAssetSelection(event.target.checked, assetSelectionData)
                    }
                    classes={{
                      root: classes.assetCheckbox,
                    }}
                  />
                )}
              </div>
            ),
          },
          {
            value: asset_id,
            type: 'custom',
            imageFallback: noAsset,
            numeric: false,
            disablePadding: true,
            altText: 'Preview Not available',
            custComponent: (
              <div
                style={{ display: 'flex', alignItems: 'center', padding: 10 }}
              >
                {isError && (
                  <span style={{ minWidth: 24 }}>
                    <Alert severity="error" style={{ color: '#CC0000' }} />
                  </span>
                )}
                <ThumbnailBlock
                  altText="Preview Not available"
                  imgSrc={`${asset_thumbnail_path}`}
                  inProgress={inProgress}
                  identifier={asset_id}
                  previewHandler={this.loadPreview}
                  enablePreview={enablePreview}
                  val={val}
                />
              </div>
            ),
          },
          {
            value: Mimetypes[mime_type],
            type: 'image',
            imageFallback: Mimetypes['default'],
            numeric: false,
            disablePadding: true,
            altText: 'Preview Not available',
            className: 'fileTypeImage',
          },
          {
            value: asset_name,
            type: 'custom',
            numeric: false,
            disablePadding: true,
            custComponent: (
              <div>
                <span style={{ display: 'block', margin: '5px 0px' }}>
                  {asset_name}
                </span>
                {isError && (
                  <span style={{ fontSize: 10, color: '#CC0000' }}>
                    Asset Hub was unable to process this asset.
                    <br />
                    Please upload this file again
                  </span>
                )}
              </div>
            ),
          },
          {
            value: asset_version,
            type: 'text',
            numeric: true,
            disablePadding: true,
          },
          {
            value: secondary_classification,
            type: 'text',
            numeric: false,
            disablePadding: true,
          },
          {
            value: asset_type,
            type: 'text',
            numeric: false,
            disablePadding: true,
          },
          {
            value: creative_asset_status,
            type: 'custom',
            numeric: false,
            disablePadding: true,
            custComponent:
              creative_asset_status == 'WIP' ? (
                <Chip
                  variant="outlined"
                  color="warning"
                  size="small"
                  label={creative_asset_status}
                />
              ) : (
                <Chip
                  variant="outlined"
                  color="info"
                  size="small"
                  label={creative_asset_status}
                />
              ),
          },
          {
            value: asset_import_date
              ? convertCSTtoLocal(asset_import_date, 'MMM DD, YYYY h:mm:ss a')
              : '',
            type: 'text',
            numeric: false,
            disablePadding: true,
          },
          {
            value: asset_imported_by,
            type: 'text',
            numeric: false,
            disablePadding: true,
          },
          {
            value: missing_metadata,
            type: 'custom',
            numeric: false,
            disablePadding: true,
            custComponent: val?.missing_metadata ? (
              isEmpty(asset_classification) ? (
                'NA'
              ) : missing_metadata && missing_metadata.length ? (
                <Alert severity="error" className={classes.missingData}>
                  {missing_metadata.map((data) => (
                    <div className={classes.missingItem}>{data}</div>
                  ))}
                </Alert>
              ) : (
                <CheckIcon className={classes.checkIcon} />
              )
            ) : (
              'Processing...'
            ),
          },
        ],
      }
      return itemData
    })
  }

  handleSort = (event, field) => {
    const {
      projectId = '',
      pagination = {},
      filters = {},
      getProjectAssetsWithFilters = () => { }
    } = this.props

    let direction = 'DESC'
    if (
      pagination.sort.field === field &&
      pagination.sort.direction === direction
    ) {
      direction = 'ASC'
    }

    const newPagination = {
      ...pagination,
      page: 0,
      sort: {
        field,
        direction,
      },
    }
    getProjectAssetsWithFilters(projectId, newPagination, filters)
  }

  handleSelectAll = (isChecked) => {
    const {
      projectAssets = [],
      selectedAssets = [],
      selectedAssetDetails = {},
    } = this.props
    const filteredAssets = projectAssets
    const projectAssetsWithUOI = filteredAssets.map((item) => item.asset_uoi_id)
    const otherPageSelAssets = (selectedAssets || [])
      .filter((item) => !projectAssetsWithUOI.includes(item))
      .reduce(
        (res, key) => Object.assign(res, { [key]: selectedAssetDetails[key] }),
        {},
      )
    let downloadAssets = Object.assign({}, otherPageSelAssets)
    if (isChecked) {
      // select all assets to download
      filteredAssets.forEach((asset = {}) => {
        const {
          asset_uoi_id = '',
          asset_master_uoi_id = '',
          asset_name = '',
          render_url = '',
          asset_master_id = '',
          render_urls = {},
          creative_asset_status = '',
          status = '',
        } = asset
        if (asset_uoi_id) {
          downloadAssets[asset_uoi_id] = {
            assetId: asset_uoi_id,
            assetMasterId: asset_master_uoi_id,
            fileName: asset_name,
            filePath: render_url,
            imageMasterId: asset_master_id,
            render_urls,
            creativeAssetStatus: creative_asset_status,
            status: status,
          }
        }
      })
    }
    this.props.handleSelectAll(downloadAssets)
  }

  closeMetaDataPreview = () => {
    this.setState(
      {
        isArrow: true,
      },
      () => {
        this.props.handleCloseMetaPage(this.props.projectId)
      },
    )
  }


  handlePagination = (event, page) => {
    const { pagination, getProjectAssetsWithFilters = () => { },
      filters = {}, projectId = ''
    } = this.props
    const newPagination = { ...pagination, page }
    getProjectAssetsWithFilters(projectId, newPagination, filters)
  }

  getPreviewMetaData = (assetList, assetUoiId, metaDataSingleAsset) => {
    let singleAssetInfo
    let assetPreviewMetaPage
    singleAssetInfo = assetList.filter((asset) => asset.uoId === assetUoiId)[0]
    if (singleAssetInfo) {
      const {
        render_urls: { optimized_asset_url: fileUrl = '' } = {},
        fileName = '',
        uoId: assetId = '',
      } = singleAssetInfo
      assetPreviewMetaPage = {
        fileUrl,
        fileName,
        assetId,
        assetDetails: {},
        assetInformation: singleAssetInfo,
      }
    } else {
      assetPreviewMetaPage = metaDataSingleAsset
    }
    return assetPreviewMetaPage
  }

  render() {
    const {
      classes = {},
      handleStatusClick,
      projectAssetCount = 0,
      selectedAssets = [],
      pagination = {},
      projectAssets = [],
      showPreview = false,
      router = {},
      projectId = '',
      metaDataSingleAsset = {},
      creativeAssetStatus = 'All',
      loading = {},
    } = this.props
    const { params = {} } = router
    const { loadAssets } = loading
    const {
      page = 0,
      size = 21,
      sort: pageSort = { field: 'assetImportedDate', direction: 'desc' },
    } = pagination
    const {
      headingList,
      previewCollection = [],
      currentPreviewAssetIndex,
      isArrow = true,
    } = this.state
    const assetInformation = previewCollection[currentPreviewAssetIndex] || {}
    const {
      renderUrl: fileUrl = '',
      render_urls = {},
      fileName = '',
      uoId: assetId = '',
    } = assetInformation
    const assetInfoPreview = {
      fileUrl,
      fileName,
      assetId,
      assetDetails: {
        render_urls,
      },
      assetInformation,
    }

    const assetList = this.getAssetListContract(projectAssets)

    const assetUoiId = params['*'] ? params['*'] : assetId
    let assetPreviewMetaPage
    if (params.pageName === 'metaPage' && assetId !== assetUoiId && !isArrow) {
      assetPreviewMetaPage = this.getPreviewMetaData(
        assetList,
        assetUoiId,
        metaDataSingleAsset,
      )
    }
    const assetPreviewInfo =
      params.pageName === 'metaPage' && assetId !== assetUoiId && !isArrow
        ? assetPreviewMetaPage
        : assetInfoPreview
    const emptyRows = size - Math.min(size, projectAssetCount - page * size)
    const currentPageSelectedCount = (
      assetList.filter((item) => (selectedAssets || []).includes(item.uoId)) ||
      []
    ).length
    const isAllAssetChecked =
      currentPageSelectedCount > 0 &&
      currentPageSelectedCount === assetList.length
    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <AHAssetTableHead
              order={pageSort.direction.toLowerCase()}
              orderBy={pageSort.field}
              handleSelectAll={this.handleSelectAll}
              onRequestSort={this.handleSort}
              headingList={headingList}
              isAllAssetChecked={isAllAssetChecked}
            />
            {
              !loadAssets ? (
                projectAssetCount > 0 ? (
                  <TableBody testid="tableBody" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    {assetList.length ? assetList.map((asset, index) => (
                      <TableRow
                        hover
                        data-cy="assetRow"
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        id={`tcin_${asset.id}`}
                        className={
                          asset.data[0].value && asset.data[0].value === 'On Hold'
                            ? classes.onHoldRow
                            : ''
                        }
                      >
                        {asset.data.map((val, idx) => (
                          <TableCell
                            data-cy={idx}
                            key={idx}
                            numeric={val.numeric}
                            padding={val.disablePadding ? 'none' : 'normal'}
                            className={
                              val.type === 'status'
                                ? classes.listStatusWrap
                                : classes.listNormalWrap
                            }
                          >
                            {val.type !== 'statusBtn' &&
                              val.type !== 'image' &&
                              val.type !== 'custom' && (
                                <div
                                  id={
                                    val.type === 'status'
                                      ? `tcin_status_${asset.id}`
                                      : ''
                                  }
                                  className={
                                    val.type === 'status'
                                      ? classNames(
                                        classes.listStatus,
                                        classes[val.className],
                                      )
                                      : ''
                                  }
                                >
                                  {val.value === 'On Hold' && (
                                    <div style={{ position: 'relative' }}>
                                      <PauseIcon style={{ color: '#8f8f8f' }} />
                                      <span
                                        style={{
                                          position: 'absolute',
                                          top: '7px',
                                        }}
                                      >
                                        &nbsp;{val.value}
                                      </span>
                                    </div>
                                  )}
                                  {val.value !== 'On Hold' && (
                                    <div>{val.value}</div>
                                  )}
                                </div>
                              )}
                            {val.type === 'statusBtn' && (
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classNames(
                                  classes.listStatus,
                                  classes.listStatusBtn,
                                  classes[val.className],
                                )}
                                onClick={handleStatusClick(val.groupId)}
                              >
                                {val.value}
                              </Button>
                            )}
                            {val.type === 'image' && (
                              <img
                                alt={val.altText}
                                className={classNames(classes[val.className])}
                                src={val.value || val.imageFallback}
                              />
                            )}
                            {val.type === 'custom' && val.custComponent}
                          </TableCell>
                        ))}
                      </TableRow>
                    )) : 'No Assets Found'}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    className={classes.noDataText}
                  >
                    {creativeAssetStatus === 'All'
                      ? 'No assets uploaded to this Project'
                      : `No ${creativeAssetStatus} assets uploaded to this Project`}
                  </Typography>
                )
              ) : (
                <Box className={classes.loader}>
                  <CircularProgress />
                </Box>
              )}
          </Table>
        </div>
        {projectAssetCount > size && !loadAssets && (
          <TablePagination
            component="div"
            testid="tablePagination"
            count={projectAssetCount}
            page={page}
            rowsPerPage={size}
            rowsPerPageOptions={[]}
            onPageChange={this.handlePagination}
          />
        )}
        {showPreview && (
          <MetadataPreview
            selectedAssetId={assetId ? assetId : assetUoiId}
            isFetching={false}
            showPreview={showPreview}
            closePreview={(isArrowShow = true) =>
              this.closeMetaDataPreview(isArrowShow)
            }
            downloadAsset={() => { }}
            nextAsset={this.loadNextAsset}
            prevAsset={this.loadPreviousAsset}
            consumer="project"
            isArrowShow={isArrow}
            projectId={projectId}
          />
        )}
      </Paper>
    )
  }
}

AHAssetTable.propTypes = {
  classes: PropTypes.object.isRequired,
  creativeAssetStatus: PropTypes.string,
  handleSelectAll: PropTypes.func,
  handleCheckbox: PropTypes.func,
  handleStatusClick: PropTypes.func,
  checkUploadProgress: PropTypes.func,
  onAssetSelection: PropTypes.func,
  projectAssets: PropTypes.arrayOf(
    PropTypes.shape({
      assetContentType: PropTypes.string,
      asset_id: PropTypes.string,
      asset_import_date: PropTypes.string,
      asset_imported_by: PropTypes.string,
      asset_name: PropTypes.string,
      render_urls: PropTypes.Object,
      asset_version: PropTypes.boolean,
      job_id: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
  projectAssetCount: PropTypes.number,
  projectId: PropTypes.string,
  selectedAssets: PropTypes.object,
  updateHasPagenation: PropTypes.func,
  pagination: PropTypes.object,
  allProjectAssets: PropTypes.array,
  clearAssetRevisions: PropTypes.func,
  handleOpenMetaPage: PropTypes.func,
  handleCloseMetaPage: PropTypes.func,
  showPreview: PropTypes.bool,
  metaDataSingleAsset: PropTypes.shape({
    fileName: PropTypes.string,
    fileUrl: PropTypes.string,
    assetId: PropTypes.string,
    assetDetails: PropTypes.object,
    assetInformation: PropTypes.object,
  }),
}

const mapStateToProps = (state = {}) => {
  const { projects = {} } = state
  const {
    projectAssets = [],
    projectAssetCount = 0,
    pagination = {},
    filters = {},
    creativeAssetStatus = 'All',
    loading = {},
  } = projects
  return {
    projectAssets,
    projectAssetCount,
    pagination,
    filters,
    creativeAssetStatus,
    loading,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearAssetRevisions,
      updateCreativeAssetStatus,
      getProjectAssetsWithFilters
    },
    dispatch,
  )

const MyComponent = (props) => {
  const classes = styles()
  return <AHAssetTable {...props} classes={classes} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MyComponent))

import React, { useState } from "react"
import { useEnv } from '@praxis/component-runtime-env'
import { makeStyles } from "@mui/styles"
import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, List, ListItem, ListItemText, TextField } from "@mui/material"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { getAlphabeticSortedAndGroupedOptions } from "../../betasearch/utils/search"
import CheckboxGroup from "../../betasearch/components/CustomCheckbox/CheckboxGroup"
import { mapFacetDataToCheckboxConfig } from "../../betasearch/mappers/searchMapper"

const useStyles = makeStyles({
  conditionSection: {
    paddingLeft: '10px'
  },
  channelFilterGrid: {
    height: '40vh',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  filterGroupLabel: {
    padding: '0px 15px',
    fontWeight: 'bolder'
  },
  listItemButton: {
    padding: '0px',
    height: '25px',
    cursor: 'pointer'
  },
  classificationList: {
    gap: '5px',
    height: '60vh',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  fileList: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileControlGrp: {
    paddingBottom: '5px',
  },
  fileGroupClass: {
    display: 'flex',
    flexDirection: 'row'
  },

  drawerConditionsClass: {
    height: '82vh',
    padding: 0,
    overflow: 'scroll'
  }
})

export default function PromptConditionsDrawer({
  conditionsConfig = [],
  appliedConditions = {},
  setIsDrawerOpen = () => { },
  applySelectedConditions = () => { }
}) {
  const classes = useStyles()
  const {
    PROMPT_SETTINGS: {
      PROJECT_TYPES = [],
      FILE_TYPES = {}
    } = {}
  } = useEnv()
  const [selectedProjectTypes, setSelectedProjectTypes] = useState(appliedConditions['project_type'] || [])
  const [selectedChannels, setSelectedChannels] = useState(appliedConditions['channel'] || [])
  const [selectedClassifications, setSelectedClassifications] = useState({})
  const [selectedFiletypes, setSelectedFiletypes] = useState(appliedConditions['file_type'] || [])

  const handleSelectionChange = (event, value) => {
    setSelectedProjectTypes(value)
  }

  const { records: channelRecords = [] } = conditionsConfig.find(({ key }) => key === 'Channel') || {}
  const groupedOptions = getAlphabeticSortedAndGroupedOptions(channelRecords, 'value')


  const { records: classificationRecords = [] } = conditionsConfig.find(({ key }) => key === 'Classification') || {}
  const classificationConfig = mapFacetDataToCheckboxConfig(classificationRecords)

  const updateParentState = ({ filterKey = '', selectedValues = [] }) => {
    selectedClassifications[filterKey] = selectedValues
    setSelectedClassifications(selectedClassifications)
  }

  const handleToggle = (type = '', option) => () => {
    const { value = '' } = option
    let currentIndex = -1, newChecked = []
    if (type === 'channel') {
      currentIndex = selectedChannels.indexOf(value)
      newChecked = [...selectedChannels]
    } else {
      currentIndex = selectedFiletypes.indexOf(value)
      newChecked = [...selectedFiletypes]
    }
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    if (type === 'channel') {
      setSelectedChannels(newChecked)
    } else {
      setSelectedFiletypes(newChecked)
    }
  }

  const onClickApplyConditions = () => {
    applySelectedConditions({
      appliedProjectTypes: selectedProjectTypes,
      appliedChannels: selectedChannels,
      appliedClassifications: Object.values(selectedClassifications).flat(1),
      appliedFileTypes: selectedFiletypes
    })
    setIsDrawerOpen(false)
  }

  return (
    <>
      <div className={classes.drawerConditionsClass}>
        <div className={classes.conditionSection}>
          <h4>{'Project Type'}</h4>
          <Autocomplete
            multiple
            size="small"
            style={{ maxWidth: 400, paddingBottom: '10px' }}
            options={PROJECT_TYPES}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={selectedProjectTypes}
            onChange={handleSelectionChange}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={selected}
                    size="small"
                  />
                  {option}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Project Type" placeholder="Select Project Type" />
            )}
          />
        </div>
        <Divider />
        <div className={classes.conditionSection}>
          <h4>Channel</h4>
          <Grid container className={classes.channelFilterGrid}>
            {Object.keys(groupedOptions).filter((group) => group !== 'undefined').map((groupName = {}) => {
              const groupList = groupedOptions[groupName]
              return (
                <List>
                  <div className={classes.filterGroupLabel}>{groupName}</div>
                  {
                    groupList.map((record = {}) => {
                      const {
                        value = '',
                      } = record
                      return (
                        <ListItem key={value} className={classes.listItemButton} onClick={handleToggle('channel', record)}
                          data-cy={`listItem_${value}`}
                        >
                          <Checkbox
                            size='small'
                            checked={selectedChannels.indexOf(value) !== -1}
                            tabIndex={-1}
                            inputProps={{ 'aria-labelledby': 'value' }}
                            data-cy={`listItem_checkbox_${value}`}
                          />
                          <ListItemText
                            data-cy={`listItemText_${value}`}
                            primary={
                              value
                            }
                          />
                        </ListItem>
                      )
                    })
                  }
                </List>
              )
            })}
          </Grid>
        </div>
        <Divider />
        <div className={classes.conditionSection}>
          <h4>Classification</h4>
          <Grid item className={classes.classificationList}>
            {(Object.keys(classificationConfig) || []).map(
              (classificationConfigKey = '') => (
                <CheckboxGroup
                  parentCheckboxLabel={classificationConfigKey}
                  parentCheckboxValue={classificationConfigKey}
                  parentCheckboxSelected={false}
                  childItems={classificationConfig[classificationConfigKey]}
                  uniqueIdentifier='id'
                  selectedFilterValues={appliedConditions['classification'] || []}
                  updateParentState={updateParentState}
                />
              ),
            )}
          </Grid>
        </div>
        <Divider />
        <div className={classes.conditionSection}>
          <h4>File Type</h4>
          <Grid item className={classes.fileList}>
            {Object.keys(FILE_TYPES).map((fileConfigKey = '') => (FILE_TYPES[fileConfigKey].length ? (
              <FormControl variant="standard" className={classes.fileControlGrp}>
                <FormLabel component="legend">{fileConfigKey}</FormLabel>
                <FormGroup className={classes.fileGroupClass}>
                  {
                    FILE_TYPES[fileConfigKey].map((fileType) => (
                      <FormControlLabel
                        control={
                          <Checkbox checked={selectedFiletypes.includes(fileType)} onClick={handleToggle('file_type', { value: fileType })} name={fileType} />
                        }
                        label={fileType}
                      />
                    ))
                  }
                </FormGroup>
              </FormControl>
            ) : <></>
            ))}
          </Grid>
        </div>
      </div>
      <Box sx={{ padding: 2, borderTop: "1px solid #ddd", display: "flex", justifyContent: "flex-end", marginTop: "auto" }}>
        <Button variant="outlined" sx={{ marginRight: 1 }} onClick={() => setIsDrawerOpen(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onClickApplyConditions}>
          Apply
        </Button>
      </Box>
    </>

  )
}

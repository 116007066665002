import axios from 'axios'
import { isEmpty } from 'lodash'
import { appendQueryParams } from './UrlHelper'
import apiConfig from '../config/apiConfig'
import firefly from '../analytics/firefly'

import { setDownloadAssetInProgress } from '../store/search/actions'
import {
  DOWNLOAD_JPEG_VERSION,
  DOWNLOAD_ORIGINAL_VERSION,
} from '../constants/search'
import { isEmptyObject } from './UtilityHelper'
import { trackBetaSearchBulkDownloads } from '../analytics/betaSearch'

const { key: KEY = '', metadata: { assetDownloadMetricUrl = '' } = {} } = apiConfig

let totalDownloads = 0
let initiatedDownloads = 0
let inProgressDownloadCount = 0
let remainingAssets = {}
let remainingFiles = []

export const startDownload = (
  downloadAssets = {},
  dispatch,
  fileType,
  userId,
  page,
  embededQueryParams = false,
  location = '',
) => {
  let downloadAssetKeys = Object.keys(downloadAssets)
  let downloadedAssets = []
  for (let i = 0; i < downloadAssetKeys.length; i++) {
    const downloadAssetDetails = downloadAssets[downloadAssetKeys[i]]
    downloadedAssets.push(downloadAssetDetails)
  }
  totalDownloads = Object.keys(downloadAssets).length
  firefly.trackDownloads(downloadedAssets, page, userId, location)
  dispatch(setDownloadAssetInProgress(true))
  downloadAllAssets(
    downloadAssets,
    dispatch,
    fileType,
    userId,
    embededQueryParams,
  )
}

const downloadAsset = async (
  { fileName = '', filePath = '', fileSizeMb = '', render_urls = {}, assetId = '' },
  dispatch,
  fileType = DOWNLOAD_ORIGINAL_VERSION,
  userId,
  embededQueryParams = false,
) => {
  let downloadFileName = fileName
  const { original_asset_url = '', optimized_asset_url = '' } = render_urls
  // adding logic to downlaod jpeg version
  let modifiedFilePath = original_asset_url
  if (fileType === DOWNLOAD_JPEG_VERSION) {
    downloadFileName =
      (fileName.indexOf('.') !== -1
        ? fileName.substring(fileName.indexOf('.'), 0)
        : fileName) + '.jpeg'
    modifiedFilePath = optimized_asset_url
  }
  const imageQueryParams = {
    file_name: downloadFileName,
    is_download: true,
    key: KEY,
  }
  const downloadFilePath = appendQueryParams(modifiedFilePath, imageQueryParams)

  const blobResponse = await downloadBulkFileAPICall(downloadFilePath)
  if (blobResponse) {
    if (downloadFilePath && !downloadFilePath.includes('streaming')) {
      updateDownloadMetricsByAssetId(assetId)
    }
    downloadBulk(blobResponse, downloadFileName)
  }
  downloadAllAssets({}, dispatch, fileType, userId, embededQueryParams)
}

export function downloadBulkFileAPICall(downloadFilePath = '') {
  return axios.get(downloadFilePath, {
    responseType: 'blob',
  })
}

export function downloadBulk(response = {}, downloadFileName = '') {
  const { data = {} } = response
  const downloadUrl = window.URL.createObjectURL(new Blob([data]))
  const downloadLink = document.createElement('a')
  downloadLink.href = downloadUrl
  downloadLink.setAttribute('download', downloadFileName)
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
  inProgressDownloadCount = inProgressDownloadCount - 1
  initiatedDownloads++
}

export const downloadAllAssets = (
  downloadAssets = {},
  dispatch,
  fileType,
  userId,
  embededQueryParams,
) => {
  if (!isEmpty(downloadAssets)) {
    remainingAssets = downloadAssets
  }
  const remainingAssetKeys = Object.keys(remainingAssets)

  if (
    remainingAssetKeys.length === 0 &&
    inProgressDownloadCount === 0 &&
    dispatch
  ) {
    // send analytics event
    if (totalDownloads != initiatedDownloads) {
      firefly.trackUninitiatedDownloads(
        totalDownloads - initiatedDownloads,
        userId,
      )
    }

    dispatch(setDownloadAssetInProgress(false))
    return
  }

  if (remainingAssetKeys.length > 0 && inProgressDownloadCount < 5) {
    for (let i = 0; i < remainingAssetKeys.length; i++) {
      const downloadAssetDetails = remainingAssets[remainingAssetKeys[i]]
      inProgressDownloadCount++
      downloadAsset(
        { ...downloadAssetDetails },
        dispatch,
        fileType,
        userId,
        embededQueryParams,
      )
      delete remainingAssets[remainingAssetKeys[i]]
      if (inProgressDownloadCount > 5) return true
    }
  }
}

/**
 * Beta Search Download Methods Start
 */
const downloadAssetFile = async (
  { fileName = '', filePath = '', fileSizeMb = '', render_urls = {}, assetId = '' },
  dispatch,
  fileType = DOWNLOAD_ORIGINAL_VERSION,
  userId,
  embededQueryParams = false,
) => {
  let downloadFileName = fileName
  const { originalAssetUrl = '', optimizedAssetUrl = '' } = render_urls
  // adding logic to downlaod jpeg version
  let modifiedFilePath = originalAssetUrl
  if (fileType === DOWNLOAD_JPEG_VERSION) {
    downloadFileName =
      (fileName.indexOf('.') !== -1
        ? fileName.substring(fileName.indexOf('.'), 0)
        : fileName) + '.jpeg'
    modifiedFilePath = optimizedAssetUrl
  }
  const imageQueryParams = {
    file_name: downloadFileName,
    is_download: true,
    key: KEY,
  }
  const downloadFilePath = appendQueryParams(modifiedFilePath, imageQueryParams)

  const blobResponse = await downloadBulkFileAPICall(downloadFilePath)
  if (blobResponse) {
    if (downloadFilePath && !downloadFilePath.includes('streaming')) {
      updateDownloadMetricsByAssetId(assetId)
    }
    downloadBulk(blobResponse, downloadFileName)
  }
}

export const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  )

export const downloadAllFiles = (
  downloadAssets = [],
  dispatch,
  fileType,
  userId,
  embededQueryParams,
  betaFireflyData = {},
) => {
  if (!isEmpty(downloadAssets)) {
    remainingFiles = downloadAssets
  }
  const downloadFileChunks = downloadAssets ? chunk(downloadAssets, 5) : []
  let remainingChunksCount = downloadFileChunks?.length

  for (let i = 0; i < downloadFileChunks?.length; i++) {
    const currentFilesList = downloadFileChunks[i] || []
    currentFilesList?.map((currentAsset = {}) => {
      downloadAssetFile(
        { ...currentAsset },
        dispatch,
        fileType,
        userId,
        embededQueryParams,
      )
      return true
    })
    remainingChunksCount--
  }

  if (remainingChunksCount === Number(0)) {
    if (totalDownloads != initiatedDownloads) {
      firefly.trackUninitiatedDownloads(
        totalDownloads - initiatedDownloads,
        userId,
      )
    }

    dispatch(setDownloadAssetInProgress(false))
    if (!isEmptyObject(betaFireflyData)) {
      trackBetaSearchBulkDownloads(betaFireflyData)
    }
    dispatch({
      type: 'search/saveSelectedItems',
      payload: { selectedItems: [] },
    })
    return true
  }
}

export const startDownloadFile = ({
  selectedAssets = [],
  dispatch = () => { },
  downloadAssetType = '',
  auth = {},
  page = Number(0),
  location = 'betaSearch',
  embededQueryParams = false,
}) => {
  // let downloadAssetKeys = Object.keys(downloadAssets)
  let downloadedAssets = []
  const { lanId = '' } = auth
  for (let i = Number(0); i < selectedAssets.length; i++) {
    const downloadAssetDetails = selectedAssets[i]
    downloadedAssets.push(downloadAssetDetails)
  }
  totalDownloads = selectedAssets.length
  firefly.trackDownloads(downloadedAssets, page, lanId, location)
  dispatch(setDownloadAssetInProgress(true))
  const betaFireflyData = {
    selectedAssets,
    downloadAssetType,
    auth,
    page,
    location,
  }
  downloadAllFiles(
    selectedAssets,
    dispatch,
    downloadAssetType,
    lanId,
    embededQueryParams,
    betaFireflyData,
  )
}

export const getAssetIdFromVaultDigitalUrl = (url) => {
  const urlObj = new URL(url)
  const pathname = urlObj.pathname
  const parts = pathname.split('/')
  return parts[parts.length - 1]
}

export const updateDownloadMetricsByUrl = (url = '') => {
  const assetId = getAssetIdFromVaultDigitalUrl(url)
  axios.post(`${assetDownloadMetricUrl.replace(':assetId', assetId)}`)
}

export const updateDownloadMetricsByAssetId = (assetId = '') => {
  axios.post(`${assetDownloadMetricUrl.replace(':assetId', assetId)}`)
}

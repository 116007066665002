import axios from 'axios'
import * as userMapper from '../../mappers/userMapper'
import * as userApi from '../../services/userService'
import apiConfig from '../../config/apiConfig'
import {
  FETCH_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES,
  UPDATE_USER_PENDING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from './actionType'

const KEY = apiConfig.key

export const fetchUserPreferences = (handleResponse = () => {}) => (dispatch) => {
  const promise =  axios.get(
    `${apiConfig.userDetails.userPreferences}?key=${KEY}`
  )
  promise.then((response) => {
    handleResponse(response?.data) // Call handleResponse with the actual data
  }).catch((error) => {
    handleResponse({})
    console.error('Error fetching user preferences:', error)
  })
  return dispatch({
    type: FETCH_USER_PREFERENCES,
    payload: promise,
  })
}

export const updateUserPreferences =
  (preferences = {}) =>
    async  (dispatch) => {
    const promise = await axios.put(
      `${apiConfig.userDetails.userPreferences}?key=${KEY}`,
      preferences
    )
    return dispatch({
      type: UPDATE_USER_PREFERENCES,
      payload: promise,
    })
  }

export function updateUser(userInfo = {}) {
  return (dispatch) => {
    dispatch(updateUserPending(true))
    const { lanId = '' } = userInfo
    userApi
      .updateUsers(lanId, userMapper.updateUserToUserInformation(userInfo))
      .then((res) => {
        dispatch(updateUserSuccess(true))
      })
      .catch((err) => {
        dispatch(updateUserFailure(err))
      })
      .finally(() => {
        dispatch(updateUserPending(false))
      })
  }
}

export function updateUserPending(pending = false) {
  return {
    type: UPDATE_USER_PENDING,
    payload: { pending: pending },
  }
}

export function updateUserSuccess(status = false) {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: {
      status: status,
    },
  }
}

export function updateUserFailure() {
  return {
    type: UPDATE_USER_FAILURE,
  }
}

export const VIDEO_ASSET_TYPES = ['mp4', 'mov', 'gif']

export const usageRightsInfoColumns = [
  {
    id: 'drmId',
    class: 'drmId',
    label: 'DRM ID',
  },
  {
    id: 'buyer',
    class: 'usageText',
    label: 'Buyer',
  },
  {
    id: 'rightsHolderName',
    class: 'usageText',
    label: 'Rights holder name',
  },
  {
    id: 'rightsHolderType',
    class: 'usageText',
    label: 'Rights holder type',
  },
  {
    id: 'availableChannels',
    class: 'usageText',
    label: 'Available channels',
  },
  {
    id: 'availableTerritories',
    class: 'usageText',
    label: 'Available territories',
  },
  {
    id: 'expirationDate',
    class: 'usageText',
    label: 'Expiration date',
  },
  {
    id: 'urmLanguage',
    class: 'urmLangText',
    label: 'Usage Rights Language',
  },
]

export const DEFAULT_SEARCH_TYPE = 'ALL'
export const DEFAULT_SEARCH_RENDER_PAGE = 'SEARCH'
export const DEFAULT_PAGE = Number(0)
export const DEFAULT_ROWSPERPAGE = Number(100)
export const SEARCH_PAGE_SIZES = [20, 50, 100, 200]
export const SORT_OPTIONS = {
  relevance: 'Sort by:  Relevance',
  uploadDateASC: 'Sort by: Upload Date | Old to New',
  uploadDateDESC: 'Sort by: Upload Date | New to Old',
  fileNameASC: 'Sort by: File Name | Ascending',
  fileNameDESC: 'Sort by: File Name | Descending',
}

export const SORT_BY_OPTIONS = {
  uploadDateASC: { uploadedDate: 'asc' },
  uploadDateDESC: { uploadedDate: 'desc' },
  fileNameASC: { 'metadata.fileMetadata.fileName': 'asc' },
  fileNameDESC: { 'metadata.fileMetadata.fileName': 'desc' },
  relevance: {},
}

export const DEFAULT_SORT_BY_OPTION = 'relevance'
export const SEARCH_TEXT = 'SEARCH_TEXT'
export const UPLOAD_DATE_TEXT = 'UPLOAD_DATE'

export const FILTER_CHANNEL_KEY = 'metadata.projectInfo.channel'
export const FILTER_BRAND_KEY = 'metadata.itemInfo.brand.nested'
export const FILTER_DEPARTMENT_KEY =
  'metadata.itemInfo.merchandiseClassification.departmentName.nested'
export const FILTER_CLASS_KEY =
  'metadata.itemInfo.merchandiseClassification.className.nested'
export const FILTER_SUBCLASS_KEY =
  'metadata.itemInfo.merchandiseClassification.subclass.nested'
export const FILTER_FILE_TYPE_KEY = 'metadata.fileMetadata.fileType'

export const DEPARTMENT_CLASS_IDENTIFIER_GROUP = [
  FILTER_DEPARTMENT_KEY,
  FILTER_CLASS_KEY,
]

export const FACET_COLOR_KEY = 'colors'
export const FILTER_COLOR_BUCKET_KEY =
  'metadata.imageData.colorBuckets.bucket.nested'
export const FILTER_COLOR_HEX_KEY = 'metadata.imageData.colors.hex.nested'
export const FILTER_COLOR_HSV_KEY = 'metadata.imageData.colors.hsv.nested'

export const COLOR_IDENTIFIER_GROUP = [
  FILTER_COLOR_BUCKET_KEY,
  FILTER_COLOR_HEX_KEY,
  FILTER_COLOR_HSV_KEY,
]

export const FACET_IMAGEDATA_KEY = 'imageData'
export const FILTER_IMAGEDATA_ANIMALS_KEY = 'metadata.imageData.animals'
export const FILTER_IMAGEDATA_PEOPLE_KEY = 'metadata.imageData.people'
export const FILTER_IMAGEDATA_FOOD_KEY = 'metadata.imageData.withFood'

export const FILTER_UPLOAD_DATE_KEY = 'uploadedDate'
export const FILTER_IS_ARCHIVED_KEY = 'isArchived'
export const FILTER_IS_REUSABLE_KEY = 'isReusable'
export const FILTER_SOURCE_KEY = 'source'
export const FILTER_LIBRARIAN_STATUS_KEY = 'librarianReviewStatus'
export const FILTER_CLASSIFICATION_EXISTS_KEY =
  'metadata.assetClassification.classificationId.exists'
export const FILTER_CLASSIFICATION_MISSING_METADATA_KEY =
  'metadata.assetClassification.missingMetadata'
export const FILTER_CLASSIFICATION_KEY =
  'metadata.assetClassification.classificationId'
export const FILTER_NO_CLASSIFICATION_ID =
  'metadata.assetClassification.classificationId.not'


export const FILTER_AI_CLASSIFICATION_TALENT = 'metadata.ai'
export const FILTER_AI_CLASSIFICATION = 'metadata.ai.assetClassification.classification'
export const FILTER_AI_TALENT = 'metadata.ai.assetTalent.isTalent'

export const REVIEW_TEMPLATE_KEY = 'template_id'

export const searchDefaultFilters = {
  isConfidential: ['false'],
  isLatestRevision: ['true'],
  creativeAssetStatus: ['Approved', 'Final'],
  status: ['ACTIVE'],
  isArchived: ['false']
}

export const searchDefaultPayload = {
  filters: searchDefaultFilters,
  sort_fields: {
    uploadedDate: 'desc',
  },
  search_term: '',
  search_type: 'ALL'
}

export const filterKeyLabels = {
  [FILTER_FILE_TYPE_KEY]: 'File Type',
  [FILTER_CHANNEL_KEY]: 'Channel',
  [FILTER_BRAND_KEY]: 'Brand',
  [FILTER_CLASSIFICATION_KEY]: 'Classification Type',
  [FILTER_UPLOAD_DATE_KEY]: 'Upload Date',
  [FILTER_DEPARTMENT_KEY]: 'Department',
  [FILTER_CLASS_KEY]: 'Class',
  [FACET_COLOR_KEY]: 'Colors',
  [FILTER_IS_ARCHIVED_KEY]: 'Archived',
  [FILTER_IS_REUSABLE_KEY]: 'Reusable',
  [FILTER_LIBRARIAN_STATUS_KEY]: 'Librarian Status',
  [FILTER_CLASSIFICATION_EXISTS_KEY]: 'Classification',
  [FILTER_CLASSIFICATION_MISSING_METADATA_KEY]: 'Metadata',
  [FILTER_SOURCE_KEY]: 'Source',
  [FILTER_NO_CLASSIFICATION_ID]: 'Classification Id',
  [FILTER_AI_CLASSIFICATION]: 'AI Classification',
  [FILTER_AI_TALENT]: 'AI Talent',
}

export const DEFAULT_COLORS = {
  '#FF0000': 'Red',
  '#FF8000': 'Orange',
  '#FFFF00': 'Yellow',
  '#99FF33': 'Light Green',
  '#00CC00': 'Green',
  '#00994D': 'Green Cyan',
  '#00FFFF': 'Cyan',
  '#007FFF': 'Light Blue',
  '#0000FF': 'Blue',
  '#7F00FF': 'Violet',
  '#FF00FF': 'Magenta',
  '#FF0080': 'Pink',
  '#000000': 'Black',
  '#FFFFFF': 'White',
  '#808080': 'Grey',
}

export const horizontalFiltersConfig = [
  {
    order: 1,
    id: 'file_type',
    key: FILTER_FILE_TYPE_KEY,
    label: 'File Type',
    type: 'default',
    filtersCount: Number(0),
    show: true,
  },
  {
    order: 2,
    id: 'Channel',
    key: FILTER_CHANNEL_KEY,
    label: 'Channel',
    type: 'default',
    filtersCount: Number(0),
    show: true,
  },
  {
    order: 3,
    id: 'Brand',
    key: FILTER_BRAND_KEY,
    label: 'Brand',
    type: 'default',
    filtersCount: Number(0),
    show: true,
  },
  {
    order: 4,
    id: 'classification',
    key: FILTER_CLASSIFICATION_KEY,
    label: 'Classification',
    type: 'default',
    filtersCount: Number(0),
    show: true,
  },
  {
    order: 5,
    id: 'isReusable',
    key: FILTER_IS_REUSABLE_KEY,
    label: 'Reuse Ready',
    type: 'default',
    filtersCount: Number(0),
    show: false,
  },
  {
    order: 6,
    id: 'imageData',
    key: FACET_IMAGEDATA_KEY,
    label: 'Asset Subject',
    type: 'custom',
    filtersCount: Number(0),
    show: true,
  },
  {
    order: 7,
    id: 'upload_date',
    key: FILTER_UPLOAD_DATE_KEY,
    label: 'Upload Date',
    type: 'custom',
    filtersCount: Number(0),
    show: true,
  },
  {
    order: 8,
    id: 'Department',
    key: FILTER_DEPARTMENT_KEY,
    label: 'Department',
    type: 'default',
    filtersCount: Number(0),
    show: true,
  },
  {
    order: 9,
    id: 'Class',
    key: FILTER_CLASS_KEY,
    label: 'Class',
    type: 'default',
    filtersCount: Number(0),
    show: false,
  },
  {
    order: 10,
    id: 'colors',
    key: FACET_COLOR_KEY,
    label: 'Color',
    type: 'custom',
    filtersCount: Number(0),
    show: true,
  },
  {
    order: 11,
    id: 'archived',
    key: FILTER_IS_ARCHIVED_KEY,
    label: 'Archived Assets',
    type: 'default',
    filtersCount: Number(0),
    show: true,
  },
  {
    order: 12,
    id: 'ai_classification',
    key: FILTER_AI_CLASSIFICATION_TALENT,
    label: 'AI Classification',
    type: 'default',
    filtersCount: Number(0),
    show: false,
  },
]

export const SEARCH_TYPE_ALL = 'ALL'
export const SEARCH_TYPE_TCIN = 'TCIN'
export const SEARCH_TYPE_DPCI = 'DPCI'
export const SEARCH_TYPE_FILE_NAME = 'FILE_NAME'
export const SEARCH_TYPE_PROJECT_ID = 'PROJECT_ID'
export const SEARCH_TYPE_ASSET_ID = 'ASSET_ID'
export const SEARCH_TYPE_DOCUMENT_ID = 'DOCUMENT_ID'
export const SEARCH_TYPE_AI_METADATA = 'AI_METADATA'

export const searchTypeListForUsers = [
  { label: 'Default', value: SEARCH_TYPE_ALL, },
  { label: 'TCIN List', value: SEARCH_TYPE_TCIN },
  { label: 'DPCI List', value: SEARCH_TYPE_DPCI },
  { label: 'File Name List', value: SEARCH_TYPE_FILE_NAME },
  { label: 'Project ID List', value: SEARCH_TYPE_PROJECT_ID },
]

export const searchTypeAddonsForAdmins = [
  { label: 'Asset ID', value: SEARCH_TYPE_ASSET_ID },
  { label: 'Document ID', value: SEARCH_TYPE_DOCUMENT_ID },
  { label: 'AI Metadata', value: SEARCH_TYPE_AI_METADATA },
]

export const placeholderTextBySearchType = {
  [SEARCH_TYPE_ALL]: 'Enter a TCIN, DPCI, file name, project ID, or keywords',
  [SEARCH_TYPE_TCIN]: 'Enter one or many TCIN values, example: 12866294, 16867626',
  [SEARCH_TYPE_DPCI]: 'Enter one or many DPCI values, example: 060-17-4011, 062-02-0126',
  [SEARCH_TYPE_FILE_NAME]: 'Enter one or many file names with extension, example: 20230219_p21_Baby.pdf, 20230219_p21_Baby.pdf ',
  [SEARCH_TYPE_PROJECT_ID]: 'Enter project ID or part of a project ID, example: C-000518-01-006, C-000518-01',
  [SEARCH_TYPE_ASSET_ID]: 'Enter asset ID',
  [SEARCH_TYPE_DOCUMENT_ID]: 'Enter document ID',
  [SEARCH_TYPE_AI_METADATA]: 'Enter keywords to find assets based on AI generated text',
}

export const adminAccessFilterListKeys = [FILTER_AI_CLASSIFICATION_TALENT, FILTER_IS_REUSABLE_KEY]

export const fileTypeDisplayOrder = ['Images', 'Documents', 'Multimedia', 'Other']

export const aiClassificationFilterList = [
  { label: 'Outline', key: 'Outline' },
  { label: 'Environment', key: 'Environment' }
]

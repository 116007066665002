import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Button, Grid } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import HeaderTitle from '../../components/Header/HeaderTitle'
import MetadataSvg from '../../images/homepage/metadata.svg?url'
import AssetMetadatSvg from '../../images/homepage/asset-metadata.svg?url'
import ProductOutlineWithTalentSvg from '../../images/homepage/product-outline-with-talent.svg?url'
import ProductOutlineWithoutTalentSvg from '../../images/homepage/product-outline-without-talent.svg?url'
import ProductEnvironmentWithTalentSvg from '../../images/homepage/product-environment-with-talent.svg?url'
import ProductEnvironmentWithoutTalentSvg from '../../images/homepage/product-environment-without-talent.svg?url'
import firefly from '../../analytics/firefly'
import { updateUserPreferences } from '../../store/preferences/actionCreator'

const useStyles = makeStyles({
  homeContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '30px',
  },
  pageInfoStyle1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10%',
    height: '60vh'
  },
  pageInfoStyles2: {
    display: 'flex',
    gap: '10%',
    padding: '5% 12%',
    height: '90vh',
  },
  pageInfoStyles3: {
    backgroundColor: '#FBF9FB',
    display: 'flex',
    gap: '10%',
    padding: '10% 12%',
  },
  pageInfoContent: {
    width: '40%'
  },
  contentHeading: {
    fontFamily: 'Helvetica for Target',
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: '60px',
    letterSpacing: 0,
    paddingBottom: '30px'
  },
  homeHeading: {
    fontFamily: 'Helvetica for Target',
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: '60px',
    letterSpacing: 0,
    paddingBottom: '8px'
  },
  contentDetails: {
    fontFamily: 'Helvetica for Target',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 0,
    color: '#2B2D31'
  },
  contentDetailsHighlight: {
    color: '#CC0000'
  },
  contentImages: {
    display: 'flex',
    gap: '30px',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  actionButtons: {
    paddingTop: '10px',
    display: 'flex',
    gap: '24px'
  },
  readMoreButton: {
    width: 140,
    height: 40,
    maxHeight: '40px',
    gap: '8px',
    borderRadius: '6px',
    borderWidth: '1px',
    padding: '12px 16px',
    textTransform: 'capitalize',
    color: '#2B2D31'
  },
  gotItButton: {
    width: 80,
    height: 40,
    maxHeight: '40px',
    gap: '8px',
    borderRadius: '6px',
    borderWidth: '1px',
    padding: '12px 16px',
    textTransform: 'capitalize',
    backgroundColor: '#3D70D6',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#3D70D6',
      color: '#ffffff',
    },
    '&:disabled': {
      backgroundColor: '#E6E7EA',
      color: '#8B8F98',
      width: 'fit-content !important'
    }
  },
  imageDetailsGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'self-start'
  },
  imageHeading: {
    fontFamily: 'Helvetica for Target',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 0,
    color: '#000000',
  },
  imageContent: {
    padding: '10px 0px',
    fontWeight: 400,
    fontStyle: 'normal',
    fontFamily: 'Helvetica for Target',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 0,
    color: '#000000',
  }
})

function HomePageContainer() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { auth = {}, userPreferences = {} } = useSelector((state) => state) || {}
  const { lanId = '' } = auth
  const {
    preferences: {
      custom_preference = {}
    } = {},
  } = userPreferences
  const {
    home_got_it_click = ''
  } = custom_preference
  const [gotItDisabled, setGotItDisabled] = useState(home_got_it_click)
  const imageTypeMetadata = [{
    title: 'Product image types',
    classificationList: [{
      name: 'Outline without talent',
      image: ProductOutlineWithoutTalentSvg,
      productCode: '93726738',
      usageRights: 'DRM3534',
      talent: '',
      source: 'Ivan Cheung'
    }, {
      name: 'Outline with talent',
      image: ProductOutlineWithTalentSvg,
      productCode: '012-04-5356,81529243',
      usageRights: 'DRM6727, DRM6726',
      talent: 'Harper Springer / New York Model Management',
      source: 'Dane Tashima'
    }, {
      name: 'Environment without talent',
      image: ProductEnvironmentWithoutTalentSvg,
      productCode: '064-20-0043',
      usageRights: 'DRM8748',
      talent: '',
      source: 'Lisa Petrole'
    }, {
      name: 'Environment with talent',
      image: ProductEnvironmentWithTalentSvg,
      productCode: '249-07-0901, 062-26-0071, 074-01-9958',
      usageRights: 'DRM8131, DRM8173',
      talent: 'Taylor Welsch; Ezra Rutledge; Roman Rutledge; Adrian Rutledge / Alexis Chau',
      source: 'Jeff Johnson'
    }
    ]
  }]

  useEffect(()=> {
    setGotItDisabled(home_got_it_click)
  }, [home_got_it_click])

  const handleClick = (clickType = '') => {
    firefly.trackHomePageClicks(lanId, clickType)
    if (clickType === 'HOME_PAGE_READ_MORE') {
      window.open('https://targetonline.sharepoint.com/sites/AssetHub/SitePages/Tips-and-Tricks.aspx', '_blank')
    } else {
      const clonedUserPreferences = {...userPreferences}
      const customPreferences = {...clonedUserPreferences['preferences']['custom_preference'] || {}}
      customPreferences['home_got_it_click'] = true
      setGotItDisabled(true)
      dispatch(
        updateUserPreferences({ 
          ...clonedUserPreferences, 
          preferences: { 
            ...clonedUserPreferences['preferences'],
            custom_preference: customPreferences 
          } 
        }),
      )
    }
  }

  return (
    <>
      <HeaderTitle title="Home" icon="Home" />
      <Grid container className={classes.homeContainer}>
        <Grid item container className={classes.pageInfoStyle1}>
          <Grid item className={classes.pageInfoContent}>
            <div className={classes.contentHeading}>Metadata—the secret to effortless search</div>
            <div className={classes.contentDetails}>
              Metadata is information about your asset that makes finding it easy for everyone. Think attributes, ownership and key details about the asset you're uploading.
            </div>
            <div className={classes.contentDetails}><span className={classes.contentDetailsHighlight}>Adding metadata allows team members to find assets</span> with minimal time and effort, enhancing their AssetHub experience.</div>
          </Grid>
          <Grid item>
            <img src={MetadataSvg} />
          </Grid>
        </Grid>
        <Grid item container className={classes.pageInfoStyle1} style={{ backgroundColor: '#FBF9FB' }}>
          <Grid item className={classes.pageInfoContent}>
            <div className={classes.contentHeading}>Find that needle in the haystack</div>
            <div className={classes.contentDetails}>
              While AssetHub uses AI to add metadata, your inputs go a long way in making it 100% accurate and complete.</div>
            <div className={classes.contentDetails}>So next time you upload on AssetHub, <span className={classes.contentDetailsHighlight}>remember to add metadata—it makes all the difference.</span></div>
          </Grid>
          <Grid item>
            <img src={AssetMetadatSvg} />
          </Grid>
        </Grid>
        <Grid item container className={classes.pageInfoStyles2}>
          {
            imageTypeMetadata.map((metadata) => (
              <>
                <Grid item className={classes.contentHeading}>
                  <span className={classes.contentDetailsHighlight}>{metadata.title}</span> & metadata
                </Grid>
                <Grid item className={classes.contentImages}>
                  {
                    metadata.classificationList.map((classification) => {
                      const {
                        name = '',
                        image = '',
                        productCode = '',
                        usageRights = '',
                        talent = '',
                        source = ''
                      } = classification
                      return (
                        <Grid container className={classes.imageDetailsGrid}>
                          <Grid item className={classes.imageHeading}>{name}</Grid>
                          <Grid item><img src={image} alt={name} />
                          </Grid>
                          <Grid item>
                            {
                              productCode ? <div className={classes.imageContent}> <div><span className={classes.imageHeading}>Product code:</span> {productCode}</div><div>DPCIs or TCINs</div></div> : ''
                            }
                            {
                              usageRights ? <div className={classes.imageContent}><div><span className={classes.imageHeading}>Usage rights: </span>{usageRights}</div><div>DRM IDs</div></div> : ''
                            }
                            {
                              talent ? <div className={classes.imageContent}><div><span className={classes.imageHeading}>Talent: </span>{talent}</div><div>Name of Talent and Agency</div></div> : ''
                            }
                            {
                              source ? <div className={classes.imageContent}><div><span className={classes.imageHeading}>Source:</span> {source}</div><div>Photographer</div></div> : ''
                            }
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </>
            ))
          }
        </Grid>
        <Grid item container className={classes.pageInfoStyles3}>
          <Grid item>
            <div className={classes.homeHeading}>Let's make <span className={classes.contentDetailsHighlight}>AssetHub</span> better together!</div>
            <div className={classes.contentDetails}>
              Still have questions? Submit a ticket via the help icon in the upper right corner or visit us on Slack at #assethub-help.</div>
            <div className={classes.actionButtons}>
              <Button className={classes.readMoreButton} variant='outlined' onClick={() => handleClick('HOME_PAGE_READ_MORE')}> Read more <LaunchIcon /></Button>
              <Button className={classes.gotItButton} variant='outlined' onClick={() => handleClick('HOME_PAGE_GOT_IT')} disabled={gotItDisabled}> {gotItDisabled ? 'Thank you for your feedback' :
                'I got it'}</Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default HomePageContainer

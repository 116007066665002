import { UPLOAD_FILE_TARGET_NAME } from '../constants/projectTask'
import { getCurrentUserLocation } from '../components/ProjectsPage/project/assets/helper'
import apiConfig from '../config/apiConfig'

const { enterpriseUploads: { tenantId = '' } = {} } = apiConfig

export const constructSimpleUploadPayload = (file = {}, auth = {}) => {
  const {
    type = '',
    name = '',
    classificationId = '',
    project_uuid = '',
    asset_master_uoi_id = '',
    creative_asset_status = 'WIP',
    version = '',
    confidential,
    channel = '',
  } = file
  const { lanId = '' } = auth
  let simpleUploadData = {
    classification_id: classificationId,
    project_id: project_uuid,
    source: 'assethub',
    asset_uploaded_channel: 'EU_UI',
    file_name: name,
    revision_number: version,
    original_asset_id: asset_master_uoi_id,
    creative_asset_status: creative_asset_status,
    tenant_id: tenantId,
    is_confidential: confidential,
  }
  const simpleUploadDataBlob = new Blob([JSON.stringify(simpleUploadData)], {
    type: 'application/json',
  })
  return simpleUploadDataBlob
}

export const constructSimpleFileUploadPayload = (file, auth) => {
  const simpleUploadPayload = constructSimpleUploadPayload(file, auth)
  let simpleFileData = new FormData()
  simpleFileData.append('metadata', simpleUploadPayload)
  simpleFileData.append('file', file)
  return simpleFileData
}

export const constructStartPayload = (file = {}, isEnterpriseApi = false) => {
  let startPayload = {} 
  if (isEnterpriseApi) {
    startPayload = {
      content_type: file?.type || '',
      file_name: file?.name,
      tenant_id: tenantId,
    }
  } else {
    startPayload = {
      headers: {
        UploadChunks: true,
      },
      targets: [UPLOAD_FILE_TARGET_NAME],
      original_file_name: file?.name || '',
    }
  }
  return startPayload
}

export const constructChunkPayload = (file = {}, isEnterpriseApi = false) => {
  let chunkedFormData = new FormData()
  isEnterpriseApi
    ? chunkedFormData.append(
        'chunkPublishRequest',
        constructEnterpriseChunkBlob(file)
      )
    : chunkedFormData.append(
        'chunkUploadRequest',
        constructAssethubChunkBlob(file)
      )
  chunkedFormData.append(
    'file',
    file.slice(
      file.currentChunk * file.chunkSize,
      (file.currentChunk + 1) * file.chunkSize
    )
  )
  return chunkedFormData
}

const constructEnterpriseChunkBlob = (file = {}) => {
  const {
    uploadId = '',
    assetId = '',
    currentChunk = '',
    chunkSize = '',
  } = file
  return new Blob(
    [
      JSON.stringify({
        upload_id: uploadId,
        asset_id: assetId,
        chunk_number: currentChunk + 1,
        chunk_size: chunkSize,
      }),
    ],
    {
      type: 'application/json',
    }
  )
}

const constructAssethubChunkBlob = (file = {}) => {
  const {
    uploadId = '',
    name = '',
    currentChunk = '',
    chunkSize = '',
    totalNumberOfChunks = '',
  } = file
  return new Blob(
    [
      JSON.stringify({
        upload_id: uploadId,
        file_name: name,
        chunk_number: currentChunk,
        total_number_of_chunks: totalNumberOfChunks,
        chunk_size: chunkSize,
      }),
    ],
    {
      type: 'application/json',
    }
  )
}

export const constructEnterpriseEndPayload = (file = {}, auth = {}) => {
  const {
    assetId = '',
    uploadId = '',
    name = '',
    jobId = '',
    classificationId = '',
    partList = [],
    project_uuid = '',
    asset_master_uoi_id = '',
    creative_asset_status = 'WIP',
    version = '',
    confidential,
    channel = '',
  } = file
  const { lanId = '' } = auth
  let endUploadData = {}
  endUploadData = {
    asset_id: assetId,
    upload_id: uploadId,
    part_etags: partList,
    job_id: jobId,
    metadata: {
      tenant_id: tenantId,
      classification_id: classificationId,
      project_id: project_uuid,
      source: 'assethub',
      asset_uploaded_channel: 'EU_UI',
      file_name: name,
      original_asset_id: asset_master_uoi_id,
      creative_asset_status: creative_asset_status,
      revision_number: version,
      is_confidential: confidential,
    },
  }
  return endUploadData
}

export const constructEndPayload = (file = {}, auth = {}) => {
  let requestPayload = {}
  const metadataParams = constructMetadataPayload(file, (auth = {}))
  requestPayload = {
    method: 'POST',
    targets: [UPLOAD_FILE_TARGET_NAME],
    upload_id: file.uploadId,
    file_name: file.name,
    total_number_of_chunks: file.totalNumberOfChunks,
    chunk_size: file.chunkSize,
    total_file_size: file.size,
    metadata: JSON.stringify(metadataParams),
  }
  return requestPayload
}

export const constructMetadataPayload = (file = {}, auth = {}) => {
  const { lanId = '', email = '' } = auth
  const {
    name = '',
    jobId = '',
    classificationId = '',
    asset_master_uoi_id = '',
    project_name = '',
    project_uuid = '',
    asset_master_id = '',
    creative_asset_status = 'WIP',
    version = '',
    size = '',
    uoi_id = '',
    confidential,
  } = file
  let payload = {
    job_id: jobId,
    user_id: lanId,
    vault_upload_status: '',
    original_asset_name: name,
    project_uuid: project_uuid,
    project_name: project_name,
    content_signature: '',
    asset_content_type: '',
    asset_size: size,
    metadata: '',
    sync_folder_id: uoi_id,
    location: getCurrentUserLocation() || '',
    version: version,
    asset_master_id: asset_master_id,
    asset_master_uoi_id: asset_master_uoi_id,
    user_email_address: email,
    creative_asset_status: creative_asset_status || 'WIP',
    asset_classification_id: classificationId || '',
    confidential: confidential,
  }
  return payload
}

export const updateJobStatusPayload = (
  response = {},
  isEnterpriseApi = false
) => {
  let jobStatusPayload = {}
  const {
    internal_url = '',
    job_id = '',
    job_status = '',
    content_signature = '',
    content_size = '',
    render_url = '',
    asset_id = '',
  } = response
  isEnterpriseApi
    ? (jobStatusPayload = {
        open_text_id: asset_id,
        job_id: job_id,
        vault_upload_status: 'IN_PROGRESS',
        render_url: internal_url,
      })
    : (jobStatusPayload = {
        job_id: job_id,
        vault_upload_status: job_status,
        content_signature: content_signature,
        asset_size: content_size,
        render_url: render_url,
      })
  return jobStatusPayload
}

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Tabs, Tab } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { tabsConfig } from '../config/PromptConfig'
import HeaderTitle from '../../components/Header/HeaderTitle'
import PromptListTable from './PromptListTable'

const useStyles = makeStyles({
  settingsTabStyles: {
    background: '#F5F5F5'
  }
})

const PromptListPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('ASSETHUB')

  const {
    state: routerState= {}
  } = useLocation()
  const { selectedTab: existingTab = '' } = routerState || {}

  useEffect(() => {
    existingTab && setSelectedTab(existingTab)
  }, [existingTab])

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  useEffect(() => {
    navigate('/settings/prompts')
  }, [])

  return (
      <div>
        <HeaderTitle title='Settings' />
        <Tabs value={selectedTab} onChange={handleTabChange} centered className={classes.settingsTabStyles}>
          {tabsConfig.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} data-testid={`tab-${tab.value}`}/>
          ))}
        </Tabs>
        <PromptListTable data-cy='prompt-list-table' selectedTab={selectedTab} />
      </div>
  )
}

export default PromptListPage

import axios from 'axios'
import { momentHelper } from '../../helpers/dateHelper'

import {
  CREATE_PROJECT_TASK,
  EDIT_PROJECT_TASK,
  TASK_API_CALL_INNITIATED,
  TASK_API_CALL_SUCCESS,
  TASK_API_CALL_FAIL,
  EXPAND_TASK,
  COLLAPSE_TASK,
  EXPAND_TASK_SUCCESS,
  EXPAND_TASK_FAIL,
  EXPAND_TASK_NOTIFICATIONS,
  COLLAPSE_TASK_NOTIFICATIONS,
  OPEN_MODAL_TASK,
  CLOSE_MODAL_TASK,
  TASK_INFO_TASK_API_CALL_INNITIATED,
  TASK_INFO_API_CALL_SUCCESS,
  TASK_INFO_API_CALL_FAIL,
  EXPAND_GROUP,
  EXPAND_GROUP_SUCCESS,
  EXPAND_GROUP_FAIL,
  COLLAPSE_GROUP,
  RESET_TO_INIT_STATE,
  DOWNLOAD_TASK_REPORT_PENDING,
  DOWNLOAD_TASK_REPORT_FULFILLED,
  DOWNLOAD_TASK_REPORT_REJECTED,
} from './actionType'
import { makeTaskPayload } from './helper'
import { selectIsTaskInProgress } from './selector'
import apiConfig from '../../config/apiConfig'
import { showNotification } from '../notification/actionCreator'

const KEY = apiConfig.key
const fileDownload = require('js-file-download')

export function taskApiCallInnitiated(data) {
  return {
    type: TASK_API_CALL_INNITIATED,
    data,
  }
}
export function taskApiSuccess(data) {
  return {
    type: TASK_API_CALL_SUCCESS,
    data,
  }
}
export function taskApiFail(data) {
  return {
    type: TASK_API_CALL_FAIL,
    data,
  }
}
export function fetchTask(loginId, projectId) {
  let url = `${apiConfig.assethub.serviceHostMarket}/project_review_tasks/${projectId}?key=${KEY}`
  return function (dispatch) {
    dispatch(taskApiCallInnitiated())
    return axios
      .get(url, {
        data: {},
      })
      .then((response) => {
        dispatch(taskApiSuccess(response.data))
      })
      .catch((error) => {
        dispatch(taskApiFail(error))
      })
  }
}

// ============ Expand Collapse start ==================

export function collapseTask(taskId) {
  return {
    type: COLLAPSE_TASK,
    taskId,
  }
}
export function expandTaskNotifications(ntype, index) {
  return {
    type: EXPAND_TASK_NOTIFICATIONS,
    ntype,
    index,
  }
}
export function collapseTaskNotifications(ntype, index) {
  return {
    type: COLLAPSE_TASK_NOTIFICATIONS,
    ntype,
    index,
  }
}
export function expandTaskInnitiated(taskId) {
  return {
    type: EXPAND_TASK,
    taskId,
  }
}
export function expandTaskSuccess(data, taskId) {
  return {
    type: EXPAND_TASK_SUCCESS,
    data,
    taskId,
  }
}
export function expandTaskFail(data, taskId) {
  return {
    type: EXPAND_TASK_FAIL,
    data,
    taskId,
  }
}
export function expandTask(taskId) {
  let url = `${apiConfig.assethub.serviceHostMarket}/tasks_details/${taskId}?key=${KEY}`
  let jobsApi = `${apiConfig.assethub.serviceHostMarketV2}/jobs/${taskId}/assets?key=${KEY}`
  let taskDetails = {}
  return function (dispatch) {
    dispatch(expandTaskInnitiated(taskId))
    axios
      .get(url)
      .then((response) =>
        axios
          .get(jobsApi)
          .then((jobsApiRes) => {
            taskDetails = {
              users: response.data,
              assets: jobsApiRes.data,
            }
            dispatch(expandTaskSuccess(taskDetails, taskId))
          })
          .catch((error) => {
            dispatch(expandTaskFail(error, taskId))
          })
      )
      .catch((error) => {
        dispatch(expandTaskFail(error, taskId))
      })
  }
}
// ============ Expand Collapse End ==================

// ============ Task Details API calls ===============
function getCatalog() {
  let url = `${apiConfig.assethub.serviceHostMarket}/catalog?catalog_type=review_task&key=${KEY}`
  return axios.get(url, {
    data: {},
  })
}
function getProjectUserAssets(loginId, projectId) {
  let url = `${apiConfig.assethub.serviceHostMarketV2}/projects/${projectId}?key=${KEY}`
  return axios.get(url, {
    data: {},
  })
}
function getProjectReviewTask(loginId, projectId) {
  let url = `${apiConfig.assethub.serviceHostMarket}/project_review_tasks/${projectId}?key=${KEY}`
  return axios.get(url, {
    data: {},
  })
}

export function resetToInitState() {
  return {
    type: RESET_TO_INIT_STATE,
  }
}
// ============ Task Details API calls End ==================

export function fetchTaskDetails(loginId, projectId, hasReload = true) {
  let taskInfoDetails = {}
  return function (dispatch) {
    if (hasReload) {
      dispatch(taskApiCallInnitiated())
    }
    return axios
      .all([
        getCatalog(),
        getProjectUserAssets(loginId, projectId),
        getProjectReviewTask(loginId, projectId),
      ])
      .then(
        axios.spread(function (catalog, projectUserAssets, projectReviewTask) {
          taskInfoDetails = {
            catalog: catalog.data,
            projectUserAssets: projectUserAssets.data,
            projectReviewTask: projectReviewTask.data,
          }
          dispatch(taskApiSuccess(taskInfoDetails))
        })
      )
      .catch((error) => {
        dispatch(taskApiFail(error))
      })
  }
}

// For all Modal
export function openTaskModal(modalName) {
  return {
    type: OPEN_MODAL_TASK,
    modalName,
  }
}

export function closeTaskModal(modalName) {
  return {
    type: CLOSE_MODAL_TASK,
    modalName,
  }
}

// ------------------  Task Details API Edit call ------------
function getTaskAssets(taskId) {
  let assetsUrl = `${apiConfig.assethub.serviceHostMarketV2}/jobs/${taskId}/assets?key=${KEY}`
  return axios.get(assetsUrl)
}

function getTaskParticipants(taskId) {
  let participantsUrl = `${apiConfig.assethub.serviceHostMarket}/tasks_details/${taskId}?key=${KEY}`
  return axios.get(participantsUrl)
}
function getTaskNotification(taskId) {
  // if (!isEdit) return
  let notificationsUrl = `${apiConfig.assethub.serviceHostMarket}/notifications/${taskId}?key=${KEY}`
  return axios.get(notificationsUrl)
}

export function getTaskFullDetails(taskId, isEdit) {
  let p1 = getTaskAssets(taskId)
  let p2 = getTaskParticipants(taskId)
  let p3 = {}
  if (isEdit) {
    p3 = getTaskNotification(taskId)
  }
  let taskInfoDetails = {}
  return function (dispatch) {
    dispatch(taskInfoApiCallInnitiated())
    return axios
      .all([p1, p2, p3])
      .then(
        axios.spread(function (userAssets, participants, notification) {
          taskInfoDetails = {
            assets: userAssets.data,
            participants: participants.data,
            notification: notification.data,
          }
          dispatch(taskInfoApiSuccess(taskInfoDetails))
        })
      )
      .then((response) => {
        dispatch(taskInfoApiSuccess(response.data))
      })
      .catch((error) => {
        dispatch(taskInfoApiFail(error))
      })
  }
}
export function taskInfoApiCallInnitiated(data) {
  return {
    type: TASK_INFO_TASK_API_CALL_INNITIATED,
    data,
  }
}
export function taskInfoApiSuccess(data) {
  return {
    type: TASK_INFO_API_CALL_SUCCESS,
    data,
  }
}
export function taskInfoApiFail(data) {
  return {
    type: TASK_INFO_API_CALL_FAIL,
    data,
  }
}
export function collapseTaskGroup(groupId) {
  return {
    type: COLLAPSE_GROUP,
    groupId,
  }
}

export function expandTaskGroupInnitiated(groupId) {
  return {
    type: EXPAND_GROUP,
    groupId,
  }
}

export function expandTaskGroupSuccess(data, groupId) {
  return {
    type: EXPAND_GROUP_SUCCESS,
    data,
    groupId,
  }
}

export function expandTaskGroupFail(data, groupId) {
  return {
    type: EXPAND_GROUP_FAIL,
    data,
    groupId,
  }
}
// ------------ Task Details API Edit call  End -------------
export function expandTaskGroups(groupId, taskId) {
  return function (dispatch) {
    dispatch(expandTaskGroupInnitiated(groupId))
    //var url = apiConfig.assethub.taskGroupUsersUrl + '?taskId=' + '' + '&groupId=' + groupId + ' &key=' + apiConfig.api.key;
    return axios
      .get(
        `${apiConfig.assethub.taskGroupUsersUrl +
        '?taskId=' +
        taskId +
        '&groupId=' +
        groupId
        }&key=${KEY}`,
        {
          data: {},
        }
      )
      .then((response) => {
        dispatch(expandTaskGroupSuccess(response.data, groupId))
      })
      .catch((error) => {
        dispatch(expandTaskGroupFail(error, groupId))
      })
  }
}

// ------------ Create/Edit Task  -------------

export const createProjectTask =
  (project = {}, newTask = {}) =>
    (dispatch, getState) => {
      const isInProgress = selectIsTaskInProgress()(getState())
      if (!isInProgress) {
        const payload = makeTaskPayload(project, newTask)
        const createTaskUrl = `${apiConfig.assethub.serviceHostMarket}/review_tasks?key=${KEY}`

        const promise = axios.post(createTaskUrl, payload)

        return dispatch({
          type: CREATE_PROJECT_TASK,
          payload: promise,
        })
      }
    }

export const editProjectTask =
  (project = {}, newTask = {}, taskId = '') =>
    (dispatch, getState) => {
      const isInProgress = selectIsTaskInProgress()(getState())
      if (!isInProgress) {
        const payload = makeTaskPayload(project, newTask, taskId)
        const createTaskUrl = `${apiConfig.assethub.serviceHostMarket}/review_tasks?key=${KEY}`
        const promise = axios.put(createTaskUrl, payload)

        return dispatch({
          type: EDIT_PROJECT_TASK,
          payload: promise,
        })
      }
    }

// ------------ Review Task Report  -------------

export const generateTaskReport =
  (projectId = '', taskId = '', auth = {}) =>
    (dispatch) => {
      const reviewTaskReport = `${apiConfig.assethub.reviewTaskReportUrl
        }${taskId}?key=${KEY}&time_zone=${momentHelper.tz.guess()}&project_id=${projectId}`

      axios
        .post(reviewTaskReport, {})
        .then(() => {
          fetchTaskDetails(auth.lanId, projectId, false)(dispatch)
        })
        .catch((error) => {
          dispatch(
            showNotification(
              true,
              `Error generating report: ${error}`,
              'error',
              5000
            )
          )
        })
    }

export const downloadTaskReport =
  (url, projectName = '', jobName = '', dueDate = '', taskId = '', enableKeyValidation = true) =>
    (dispatch) => {
      const taskDownloadUrl = enableKeyValidation ? `${url}?key=${KEY}&is_download=true` : `${url}`
      const promise = axios.get(taskDownloadUrl, {
        responseType: 'arraybuffer',
      })
      dispatch({
        type: DOWNLOAD_TASK_REPORT_PENDING,
        payload: {
          taskId,
        },
      })

      promise
        .then((res) => {
          fileDownload(
            res.data,
            `${projectName}_${jobName}_${dueDate}.pdf`,
            'application/pdf'
          )
          dispatch({ type: DOWNLOAD_TASK_REPORT_FULFILLED })
        })
        .catch((error) => {
          dispatch(
            showNotification(
              true,
              `Error downloading report: ${error}`,
              'error',
              5000
            )
          )
          dispatch({ type: DOWNLOAD_TASK_REPORT_REJECTED })
        })
    }

export const resetChannels = (channels = []) => {
  let tempChannels = [...channels]
  for (let index = 0; index < tempChannels.length; index++) {
    tempChannels[index].isChecked = false
    tempChannels[index].isOpen = false
    tempChannels[index].checkStatus = 'NONE'
    if (
      tempChannels[index].subchannel &&
      tempChannels[index].subchannel.length > 0
    ) {
      resetChannels(tempChannels[index].subchannel)
    }
  }
  return tempChannels
}

export const convert = function (str) {
  let date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [day, mnth, date.getFullYear()].join('-')
}

export const convertToStringForm = function (list, tail = '') {
  let str = ''
  list.forEach((item, index) => {
    if (index === 0) {
      str = item
    } else {
      str = str + ', ' + item
    }
  })
  return str + ' ' + tail + ' '
}

export const convertDateReadableFormat = function (timeStamp) {
  const tempDate = new Date(timeStamp)
  const dateStr =
    tempDate.getDate() +
    '/' +
    (tempDate.getMonth() + 1) +
    '/' +
    tempDate.getFullYear()
  return dateStr
}

export const divideString = function (str, index) {
  const stringArr = str.split(' ')
  return stringArr[index]
}

export const channelConvertion = (channels = []) => {
  let tempChannel = []
  for (let index = 0; index < channels.length; index++) {
    let channelObj = {
      label: channels[index].label,
      selected: channels[index].isChecked,
    }
    if (channels[index].subchannel && channels[index].subchannel.length > 0) {
      channelObj.subchannel = channelConvertion(channels[index].subchannel)
    }
    tempChannel.push(channelObj)
  }
  return tempChannel
}

export const convertIntoRequiredChannel = (channels, parentNode = null) => {
  let tempChannels = [...channels]
  for (let index = 0; index < tempChannels.length; index++) {
    tempChannels[index].isChecked = false
    tempChannels[index].isOpen = false
    tempChannels[index].parentNode = parentNode
    tempChannels[index].checkStatus = 'NONE' // "INDETERMINATE" or "FULL"
    if (
      tempChannels[index].subchannel &&
      tempChannels[index].subchannel.length > 0
    ) {
      convertIntoRequiredChannel(
        tempChannels[index].subchannel,
        tempChannels[index].label,
      )
    }
  }
  return tempChannels
}

export const removeDifferences = (drmChannels = []) => {
  let tempChannel = []
  for (let i = 0; i < drmChannels.length; i++) {
    let subCh = []
    if (drmChannels[i].subchannel && drmChannels[i].subchannel.length > 0) {
      subCh = removeDifferences(drmChannels[i].subchannel)
    }
    tempChannel.push({
      label: drmChannels[i].label,
      subchannel: subCh,
    })
  }
  return tempChannel
}

export const checkUsageRightsExpiry = (usageRightsList = []) => {
  let usageExpiryStatus = false
  if (!usageRightsList?.length) {
    return usageExpiryStatus
  }
  const filteredUsageInfo = usageRightsList.filter(
    (urm) => !urm.expiration_date?.includes('9999') || !urm.expirationDate?.includes('9999'),
  )
  if (filteredUsageInfo.length) {
    filteredUsageInfo.forEach((urmDetails) => {
      const usageExpiryDate = urmDetails.expiration_date || urmDetails.expirationDate
      if (
        usageExpiryDate &&
        new Date(usageExpiryDate).getTime() < new Date().getTime()
      ) {
        usageExpiryStatus = true
      }
    })
  }
  return usageExpiryStatus
}

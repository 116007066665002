import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { useSearchContext } from '../SearchContext'

const useStyles = makeStyles({
  searchByImageButton: {
    fontSize: '16px',
    textTransform: 'initial',
    color: '#188295',
    backgroundColor: 'white',
    '&:hover': {
      color: '#115E6C',
      backgroundColor: 'white',
    },
  },
})

function ReverseImageSearch() {
  const { setImageSearchDialogOpen = () => {} } = useSearchContext()
  const classes = useStyles()

  return (
    <Button
      variant="outlined"
      onClick={() => {
        setImageSearchDialogOpen(true)
      }}
      className={classes.searchByImageButton}
      startIcon={<CameraAltOutlinedIcon className={classes.cameraIcon} />}
      data-cy={`searchByImages`}
    >
      Image Search
    </Button>
  )
}

export default ReverseImageSearch

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import BackupIcon from '@mui/icons-material/Backup'
import { Launch } from '@mui/icons-material'
import ViewAgenda from '@mui/icons-material/ViewAgenda'
import HelpIcon from '@mui/icons-material/Announcement'
import { withEnv } from '@praxis/component-runtime-env'
import { withAuth } from '@praxis/component-auth'
import { useTheme, makeStyles } from '@mui/styles'
import {
  Tabs,
  Tab,
  Toolbar,
  AppBar,
  Typography,
  Button,
  Tooltip,
  Divider,
} from '@mui/material'
import {
  selectUserEmail,
  selectIsAuthorized,
  selectUserId,
  selectMemberOf,
  selectUserCompany,
} from '../../store/auth/selector'
import { useLocation } from 'react-router-dom'
import { toggleUploadManager } from '../../store/uploads/actionCreator'
import { toggleRightSidenav } from '../../store/layout/actionCreator'
import {
  selectIsToggleRightSidenav,
  selectRightSideNavComponent,
} from '../../store/layout/selector'
import { praxis } from '../../config/themeConfig'
import { appBarStyles } from '../SecondaryNav'
import { BOARDS_TITLE } from '../../constants/search'
import withRouter from '../../containers/Router/WithRouter'
import Feedback from '../Feedback/Feedback'
import Dropdown from './Dropdown'
import {
  SINGLE_PLAYGROUND_VIEW_AI,
  BULK_TESTS_VIEW_AI,
  REFERENCE_LINK,
} from '../../constants/routes'
import GenAITermsCondition from '../../genai/pages/GenAITermsCondition'
import { fetchUserPreferences } from '../../store/preferences/actionCreator'

const styles = makeStyles((theme) => ({
  header: {
    height: 50,
    [`${useTheme().breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [useTheme().breakpoints.up('sm')]: {
      height: 50,
    },
  },
  appBar: {
    ...appBarStyles,
    zIndex: 1,
    position: 'relative',
    paddingLeft: '15px',
    backgroundColor: 'white!important',
    boxShadow: '0px 2px 6px #0000000F',
  },
  appBarHelp: {
    ...appBarStyles,
    zIndex: 9,
    position: 'relative',
    paddingLeft: '15px',
    backgroundColor: 'white!important',
    boxShadow: '0px 2px 6px #0000000F',
  },
  button: {
    color: useTheme().palette.primary.contrastText,
  },
  headerBlock: {
    minHeight: 64,
    fontSize: 13,
    marginLeft: 50,
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  flex: {
    flex: 1,
    fontSize: '20px',
    color: praxis.themePrimary,
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none !important',
    },
    margin: '0 0 0 4rem !important',
  },
  menuButton: {
    marginLeft: -12,
  },
  uploadManagerActive: {
    backgroundColor: '#43425D',
    padding: '20px 16px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#43425D !important',
    },
  },
  navButtonActive: {
    backgroundColor: useTheme().palette.primary.themePrimary,
    padding: '20px 16px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: useTheme().palette.primary.themePrimary + ' !important',
    },
  },
  uploadManager: {
    padding: '20px 16px',
    borderRadius: 0,
  },
  headerIcon: {
    color: '#BCBCCB',
    height: '22px',
  },
  divider: {
    marginTop: '15px',
    marginBottom: '15px',
    marginRight: '10px',
  },
  buttonGroup: {
    position: 'relative',
    top: '3.12rem',
    color: '#3D70D6',
    borderBottom: '1px solid #D3D5D8',
    width: '98%',
    padding: ' 0 0 0 6.5rem',
  },
  referenceStyleButton: {
    marginLeft: '100px',
    color: '#178295',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}))

const Header = (props) => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const classes = styles()
  const [activeTab, setActiveTab] = useState(
    props.router?.location.pathname === SINGLE_PLAYGROUND_VIEW_AI
      ? 0
      : props.router?.location.pathname === BULK_TESTS_VIEW_AI
        ? 1
        : 0,
  )
  const [acknowledged, setAcknowledged] = useState(false)
  const location = useLocation()
  const [isGenAI, setIsGenAI] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const handleResponse = (data) => {
    setIsGenAI(data)
    setIsLoading(false)
  }

  useEffect(() => {
    if (location.pathname.includes('/ai_imagetotext')) {
      setIsLoading(true)
      dispatch(fetchUserPreferences(handleResponse))
    }
  }, [dispatch, location.pathname])

  useEffect(() => {
    if (!isLoading) {
      if (isGenAI?.preferences?.custom_preference?.genai_preference === false || isGenAI?.preferences?.custom_preference?.genai_preference === undefined) {
        handleOpenDialog();
      } else if (isGenAI?.preferences?.custom_preference?.genai_preference === true) {
        handleCloseDialog();
      }
    }
  }, [isGenAI, isLoading])

  useEffect(() => {
    const breadCrumb = document.getElementById('breadCrumb')
    if (breadCrumb) {
      Array.from(breadCrumb.querySelectorAll('a[href]')).forEach((node) => {
        node.addEventListener('click', (e) => {
          e.preventDefault()
          props.router?.navigate(e.target.getAttribute('href'))
        })
      })
    }
  }, [props.router])

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
    if (newValue === 0) {
      props.router?.navigate(SINGLE_PLAYGROUND_VIEW_AI)
    } else {
      props.router?.navigate(BULK_TESTS_VIEW_AI)
    }
  }

  const addFeedback = () => {
    setIsModalOpen(true)
  }

  const onClose = () => {
    setIsModalOpen(false)
  }

  const toggleUploadManager = () => {
    props.toggleUploadManager()
  }

  const handleReferenceUrl = (e) => {
    e.preventDefault()
    open(REFERENCE_LINK, '_blank')
  }

  const handleOpenDialog = () => {
    setAcknowledged(true)
  }

  const handleCloseDialog = () => {
    setAcknowledged(false)
  }
  const {
    title,
    showFeedBack,
    uploads = {},
    isToggleRightSidenav,
    rightSideNavComponent,
    toggleRightSidenav = () => {},
    user,
    env = {},
    auth = {},
  } = props
  const { REACT_APP_ROLES_CONFIG = {} } = env
  const { showUploadManager = false } = uploads
  const { company = '', displayName = '' } = user
  const { projects = [], boards = [] } = REACT_APP_ROLES_CONFIG
  const { ai_imagetotext_bulk = [] } = REACT_APP_ROLES_CONFIG

  const isAuthorized = auth?.isAuthorized || (() => false)
  const singleViewRoute =
    props.router?.location.pathname === SINGLE_PLAYGROUND_VIEW_AI
  const bulkViewRoute = props.router?.location.pathname === BULK_TESTS_VIEW_AI
  const genAiButtonsVisible = singleViewRoute || bulkViewRoute

  return (
    <div style={{ height: '64px' }}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography
            variant="subtitle1"
            id="breadCrumb"
            color="inherit"
            className={classes.flex}
          >
            {ReactHtmlParser(title)}
          </Typography>

          {isAuthorized(projects) && (
            <Tooltip title="Toggle Upload Manager">
              <Button
                onClick={toggleUploadManager}
                className={
                  showUploadManager
                    ? classes.uploadManagerActive
                    : classes.uploadManager
                }
              >
                <BackupIcon className={classes.headerIcon} />
              </Button>
            </Tooltip>
          )}

          {isAuthorized(boards) && (
            <Tooltip title="Toggle Boards Dialog">
              <Button
                onClick={() =>
                  toggleRightSidenav(
                    BOARDS_TITLE,
                    isToggleRightSidenav &&
                      rightSideNavComponent !== BOARDS_TITLE,
                  )
                }
                className={
                  isToggleRightSidenav && rightSideNavComponent === BOARDS_TITLE
                    ? classes.uploadManagerActive
                    : classes.uploadManager
                }
              >
                <ViewAgenda className={classes.headerIcon} />
              </Button>
            </Tooltip>
          )}

          {showFeedBack && (
            <Tooltip title="Need Help?">
              <Button onClick={addFeedback}>
                <HelpIcon className={classes.headerIcon} />
              </Button>
            </Tooltip>
          )}

          <Divider className={classes.divider} />
          <Dropdown user={displayName} company={company} />
        </Toolbar>
      </AppBar>

      {isModalOpen && <Feedback onClose={onClose} modalOpen={isModalOpen} />}

      {isAuthorized(ai_imagetotext_bulk) && genAiButtonsVisible && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          className={classes.buttonGroup}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { backgroundColor: '#3D70D6' } }}
            sx={{
              '& .MuiTab-root': {
                color: '#3D70D6',
                marginRight: '20px',
                textTransform: 'capitalize',
              
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#3D70D6',
              },
              '.Mui-selected': {
                color: '#3D70D6 !important',
              },
            }}
          >
            <Tab label="Single Image" sx={{ m: 0, p: 0 }} />
            <Tab label="Bulk Images" sx={{ m: 0, p: 0 }} />
          </Tabs>
          <div style={{ padding: ' 0 7.5rem 0 0 ' }}>
            <Button
              onClick={handleReferenceUrl}
              sx={{ color: '#3D70D6', fontWeight: '500', fontSize: '14px' }}
            >
              GenAI Guidelines{' '}
              <Launch color="#178295" marginLeft="30%" fontSize="small" />
            </Button>
            <Button
              onClick={handleOpenDialog}
              sx={{ color: '#3D70D6', fontWeight: '500', fontSize: '14px' }}
            >
              GenAI Terms & Conditions
            </Button>
            <GenAITermsCondition
              open={acknowledged}
              onClose={handleCloseDialog}
            />
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  uploads: state.uploads,
  user: {
    email: selectUserEmail()(state),
    isAuthorized: selectIsAuthorized()(state),
    noAccessModalShown: state.auth?.noAccessModalShown,
    lanId: selectUserId()(state),
    company: selectUserCompany()(state),
    memberOf: selectMemberOf()(state),
    displayName: state.auth?.displayName,
  },
  role: state.headerReducer?.role,
  isToggleRightSidenav: selectIsToggleRightSidenav()(state),
  rightSideNavComponent: selectRightSideNavComponent()(state),
})

export default connect(mapStateToProps, {
  toggleUploadManager,
  toggleRightSidenav,
})(withAuth()(withEnv()(withRouter(Header))))

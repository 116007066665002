import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'
import { isEqual } from 'lodash'

import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Check as CheckIcon } from '@mui/icons-material'
import { bindActionCreators } from 'redux'
import withRouter from '../../containers/Router/WithRouter'
import { UsageInfoCard } from './UsageInfoCard'
import LibrarianInfoCard from './LibrarianInfoCard'
import { LibrarianStatusCard } from './LibrarianStatusCard'

import {
  getMetadataContract,
  getSaveBulkMetadataContract,
  getStorageIdentifier,
  collapseCallback,
  bulkEditErrorMessageBuilder,
} from '../../helpers/MetadataHelper'
import {
  updateBulkMetadata,
  fetchCatalogData,
  isMetadataFormEdited,
} from '../../store/metadata/actionCreator'
import {
  selectDownloadAssets,
  selectRightSideNavComponent,
  selectBSearchSelAssets,
} from '../../store/search/selector'
import { selectBoardDownloadAssets } from '../../store/boards/selector'
import {
  selectIsAdGroupSuperAdmin,
  selectIsLibrarian,
  selectUserEmail,
  selectUserId,
} from '../../store/auth/selector'
import {
  ASSET_REVIEW_PENDING,
  METADATA_CATALOG_TYPES,
} from '../../constants/metadata'
import { dispatchNotification } from '../../store/notification/actionCreator'
import { selectProjectSelectedAssets } from '../../store/projects/selector'
import { trackBulkUpdateMetadata } from '../../analytics/betaSearch'
import AssetDetailsBulkCard from './AssetDetailsBulkCard'

const styles = makeStyles((theme) => ({
  chipContainer: {
    display: 'grid',
  },
  chipComponent: {
    marginBottom: '8px',
    justifyContent: 'space-between',
    backgroundColor: useTheme().palette.primary.lightestGrey,
  },
  errorStyle: {
    color: useTheme().palette.primary.bostonRed + ' !important',
  },
  formControl: {
    minWidth: '100px',
    margin: useTheme().spacing(1),
    '& label': {
      color: useTheme().palette.primary.lightGrey + ' !important',
    },
    '& input, textarea': {
      color: useTheme().palette.primary.midGrey,
    },
  },
  formStaticWrap: {
    marginTop: '18px',
  },
  fullWidth: {
    width: '90%',
    display: 'block',
  },
  progressSpinner: {
    width: '20px !important',
    height: '20px !important',
  },
  metadataWrap: {
    padding: '5px',
    minHeight: '100%',
    height: '90vh',
    overflowY: 'auto',
  },
  metadataWrapMedium: {
    padding: '10px',
  },
  metadataCard: {
    paddingTop: '20px',
  },
  librarianStatus: {
    paddingTop: '20px',
    paddingBottom: '10%',
  },
  metadataCardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '20px',
  },
  metadataCardActionWrapper: {
    marginLeft: '20px',
  },
  metadataDivider: {
    width: '100%',
    margin: '10px 0',
  },
  metadataSubHeading: {
    fontSize: useTheme().typography.pxToRem(18),
    fontWeight: useTheme().typography.fontWeightRegular,
  },
  metadataTable: {
    margin: '0',
    padding: '0 8px',
    '& tr:last-child td': {
      borderBottom: 'none',
    },
  },
  metadataTableRow: {
    '&:hover': {
      backgroundColor: useTheme().palette.primary.lightestGrey,
      '& .actionBtnHover': {
        visibility: 'visible',
      },
    },
  },
  metadataTableCell: {
    flex: 1,
    padding: '0 !important',
    '& label, input': {
      fontSize: '0.8rem !important',
    },
  },
  metadataTableCellAction: {
    width: '20px',
    marginLeft: '10px',
    '& .error': {
      color: useTheme().palette.primary.bostonRed + ' !important',
    },
  },
  metadataTableCellActionBtn: {
    visibility: 'hidden',
  },
  noResults: {
    width: '50%',
    textAlign: 'center',
    color: useTheme().palette.primary.midGrey,
    marginTop: '2%',
    fontSize: '20px',
    margin: '0 auto',
    paddingBottom: '3%',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionMessage: {
    color: useTheme().palette.primary.primaryGreen,
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    fontSize: 'small',
  },
  noMDAccessMessage: {
    display: 'flex',
    alignItems: 'center',
    color: useTheme().palette.primary.bostonRed,
    textTransform: 'capitalize',
    fontSize: '12px',
  },
  actionButtonStyle: {
    color: useTheme().palette.primary.primaryBlue,
    padding: '10px',
    marginLeft: '10px',
  },
  actionButtonPrimary: {
    backgroundColor: useTheme().palette.primary.primaryBlue,
    color: useTheme().palette.primary.contrastText,
    '&:hover': {
      backgroundColor: useTheme().palette.primary.primaryDarkBlue,
    },
  },
  librarianCardSubHeading: {
    paddingLeft: '20px',
    paddingTop: '20px',
    fontSize: useTheme().typography.pxToRem(18),
    fontWeight: useTheme().typography.fontWeightRegular,
  },
  usageDetails: {
    font: '12px',
    padding: '0px 40px 20px 40px',
  },
  usageDetailsLabel: {
    color: '#555555',
    border: 'none',
  },
  usageSummaryLabel: {
    width: 'max-content !important',
  },
  usageDetailsText: {
    color: '#212121',
    fontSize: '13px',
  },
  noBorderClass: {
    border: 'none',
  },
  usageRightsAccordion: {
    backgroundColor: '#FAFAFA',
    margin: '10px 20px !important',
  },
}))

export class BulkEditBar extends React.Component {
  constructor(props) {
    super(props)
    const {
      selectedAssets = {},
      selectedBoardAssets = {},
      fetchCatalogData = () => {},
    } = props

    this.state = {
      selectedAssets: selectedAssets,
      selectedBoardAssets: selectedBoardAssets,
      ...getMetadataContract({}),
      metadataParentTimestamp: Date.now(),
      unsavedChanges: false,
      isSaved: false,
      librarianReviewStatus: ASSET_REVIEW_PENDING,
    }
    fetchCatalogData(METADATA_CATALOG_TYPES)
  }

  componentDidUpdate(prevProps) {
    const {
      selectedAssets,
      saveAlertActiontype = '',
      rightSideNavComponent = '',
      selectedBoardAssets = {},
    } = this.props
    if (!isEqual(prevProps.selectedAssets, selectedAssets)) {
      this.setState({ selectedAssets: selectedAssets })
    }
    if (!isEqual(prevProps.selectedBoardAssets, selectedBoardAssets)) {
      this.setState({ selectedBoardAssets: selectedBoardAssets })
    }
    if (
      !isEqual(prevProps.saveAlertActiontype, saveAlertActiontype) &&
      saveAlertActiontype === 'save_' + rightSideNavComponent
    ) {
      this.saveMetadata()
    }
  }

  componentWillUnmount() {
    //Reset to initial state
    this.setState({ unsavedChanges: false, isSaved: false })
  }

  checkEditPermissions = () => {
    // Need to add RBA related checks here
    // Check if all the selected Assets are editable
    const canEdit = true // TODO: need to add logic here to allow edit
    return {
      canEdit,
    }
  }

  updateContainerState = (updatedMetadata = {}) => {
    this.setState((state) => ({
      ...Object.assign({}, state, updatedMetadata),
      unsavedChanges: true,
    }))
    this.props.isMetadataFormEdited(true)
  }

  saveMetadata = () => {
    const {
      updateBulkMetadata = () => {},
      lanId = '',
      consumer = '',
      router = {},
      selectedProjectAssets = {},
      betaSearchSelectedAssets = [],
    } = this.props
    const { location = {} } = router
    const { pathname = '' } = location
    const updatedState = Object.assign({}, this.state)
    let view = 'search'
    const boardView = pathname.includes('/boards')
    const projectsView = pathname.includes('/project')
    const betaSearchView = pathname.includes('/betaSearch')
    if (boardView) {
      updatedState.selectedAssets = updatedState.selectedBoardAssets
      view = 'boards'
    } else if (projectsView) {
      updatedState.selectedAssets = selectedProjectAssets
      view = 'projects'
    } else if (betaSearchView) {
      updatedState.selectedAssets = betaSearchSelectedAssets
      view = 'betaSearch'
    }
    const payload = getSaveBulkMetadataContract(updatedState)
    // call the BulkEdit Metadata Update API
    updateBulkMetadata(
      payload,
      lanId,
      this.saveBulkMetadataCallback,
      consumer,
      view,
    )
  }

  saveBulkMetadataCallback = (response, responseType, searchView, payload) => {
    const {
      saveAlertActiontype = '',
      rightSideNavComponent = '',
      setSaveAlertAction = () => {},
      toggleRightSidenav = () => {},
      isMetadataFormEdited = () => {},
      dispatchNotification = () => {},
      lanId = '',
      email = '',
    } = this.props
    const { selectedAssets, selectedBoardAssets } = this.state

    if (responseType === 'success') {
      if (searchView === 'betaSearch') {
        trackBulkUpdateMetadata({
          authData: { lanId, email },
          bulkMetadataPayload: payload,
        })
      }
      this.setState({ isSaved: true, unsavedChanges: false })
      isMetadataFormEdited(false)
      if (saveAlertActiontype === 'save_' + rightSideNavComponent) {
        setSaveAlertAction('')
        setTimeout(() => {
          toggleRightSidenav(rightSideNavComponent, false)
        }, 700)
      }
    } else {
      let assetErrorMessages =
        searchView === 'boards'
          ? bulkEditErrorMessageBuilder(response, selectedBoardAssets)
          : bulkEditErrorMessageBuilder(response, selectedAssets)

      if (assetErrorMessages) {
        dispatchNotification(true, assetErrorMessages, 'error', 50000)
      }
    }
  }

  onLibrarianReviewStatusChange = (event) => {
    this.setState({
      librarianReviewStatus: event.target.value,
      unsavedChanges: true,
    })
  }

  render() {
    const {
      classes = {},
      isMetadataEditEnabled,
      storedCatalog = {},
    } = this.props
    const {
      usageInformation = [],
      usageInstructions = {},
      assetDetails = {},
      metadataParentTimestamp = '',
      projectDetails = {},
      productInformation = [],
      storeInformation = [],
      assetDescriptions = {},
      assetClassifications = {},
      unsavedChanges,
      isSaved,
      librarianReviewStatus = '',
    } = this.state
    const libMetadata = {
      assetDetails,
      projectDetails,
      productInformation,
      storeInformation,
      assetDescriptions,
      assetClassifications,
    }
    const editPermissions = this.checkEditPermissions()
    return (
      <div className={classes.metadataWrap}>
        {isMetadataEditEnabled && (
          <Fragment>
            <div className={classes.actionsContainer}>
              <div className={classes.actionButtonWrap}>
                {isSaved && (
                  <span className={classes.actionMessage}>
                    <CheckIcon />
                    <span>Metadata Added</span>
                  </span>
                )}
                <Button
                  data-cy="bulkEditSave"
                  className={cx(
                    classes.actionButtonStyle,
                    classes.actionButtonPrimary,
                  )}
                  variant="contained"
                  id="bulkEditSaveButton"
                  onClick={this.saveMetadata}
                  disabled={!unsavedChanges}
                >
                  Save Changes
                </Button>
              </div>
            </div>
            <div className={classes.metadataCard}>
              <AssetDetailsBulkCard
                classes={classes}
                metadataParentTimestamp={metadataParentTimestamp}
                libMetadata={libMetadata}
                collapseCallback={collapseCallback}
                getStorageIdentifier={getStorageIdentifier}
                editPermissions={editPermissions}
                isBulkEdit
                updateParentState={this.updateContainerState}
              />
            </div>
            <div className={classes.metadataCard}>
              <UsageInfoCard
                classes={classes}
                usageInformation={usageInformation}
                usageInstructions={usageInstructions}
                metadataParentTimestamp={metadataParentTimestamp}
                collapseCallback={collapseCallback}
                getStorageIdentifier={getStorageIdentifier}
                editPermissions={editPermissions}
                isBulkEdit
                updateParentState={this.updateContainerState}
              />
            </div>
            <div className={classes.metadataCard}>
              <LibrarianInfoCard
                containerClasses={classes}
                projectDetails={projectDetails}
                libMetadata={libMetadata}
                storedCatalog={storedCatalog}
                metadataParentTimestamp={metadataParentTimestamp}
                collapseCallback={collapseCallback}
                getStorageIdentifier={getStorageIdentifier}
                editPermissions={editPermissions}
                isBulkEdit
                updateParentState={this.updateContainerState}
              />
            </div>
            <div className={classes.librarianStatus}>
              <LibrarianStatusCard
                classes={classes}
                librarianReviewStatus={librarianReviewStatus}
                onLibrarianReviewStatusChange={
                  this.onLibrarianReviewStatusChange
                }
              />
            </div>
          </Fragment>
        )}
      </div>
    )
  }
}

BulkEditBar.propTypes = {
  classes: PropTypes.object,
  consumer: PropTypes.string,
  selectedAssets: PropTypes.object,
  storedCatalog: PropTypes.object,
  fetchCatalogData: PropTypes.func,
  updateBulkMetadata: PropTypes.func,
  isMetadataFormEdited: PropTypes.func,
  isMetadataEditEnabled: PropTypes.bool,
  saveAlertActiontype: PropTypes.string,
  rightSideNavComponent: PropTypes.string,
  setSaveAlertAction: PropTypes.func,
  toggleRightSidenav: PropTypes.func,
  dispatchNotification: PropTypes.func,
  lanId: PropTypes.string,
  selectedBoardAssets: PropTypes.object,
  location: PropTypes.object,
}

const mapStateToProps = (state = {}) => {
  const { metadata = {} } = state
  const { assetCatalog = {} } = metadata
  return {
    storedCatalog: assetCatalog,
    selectedAssets: selectDownloadAssets()(state),
    selectedBoardAssets: selectBoardDownloadAssets()(state),
    betaSearchSelectedAssets: selectBSearchSelAssets()(state),
    selectedProjectAssets: selectProjectSelectedAssets()(state),
    isMetadataEditEnabled:
      selectIsAdGroupSuperAdmin()(state) || selectIsLibrarian()(state),
    rightSideNavComponent: selectRightSideNavComponent()(state),
    lanId: selectUserId()(state),
    email: selectUserEmail()(state),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCatalogData,
      updateBulkMetadata,
      isMetadataFormEdited,
      dispatchNotification,
    },
    dispatch,
  )

const MyComponent = (props) => {
  const classes = styles()
  return <BulkEditBar {...props} classes={classes} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MyComponent))

import { useState } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'

import {
  removeFilterValue,
  updateSearchPayload,
} from '../../../store/search/searchSlice'
import { FILTER_IS_ARCHIVED_KEY } from '../../../constants/search'
import CustomButtonGroup from '../../../components/CustomButtonGroup'
import { useSearchContext } from '../SearchContext'
import { BetaAnalytics } from '../../../../analytics/betaSearch'

const useStyles = makeStyles({
  archivalContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
  },
  archivalItem: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    '&.Mui-disabled': {
      color: '#00000061 !important',
    },
  },
})

function ArchivalAssetFilter({
  filterKey = '',
  closePopOver = () => { },
  availableFilters = {},
  isAdmin = false
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { trackAnalyticsData = () => { } } = useSearchContext()

  const availabledFilteredValue =
    availableFilters?.[FILTER_IS_ARCHIVED_KEY]?.[0] || ''
  const [includedArchivedChecked, setIncludedArchivedChecked] = useState(
    availabledFilteredValue === '',
  )
  const [onlyArchivedChecked, setArchivedChecked] = useState(availabledFilteredValue === 'true')
  const onClickApply = () => {
    let archivalFilters = {}
    if (includedArchivedChecked) {
      archivalFilters[filterKey] = ['ALL']
      dispatch(updateSearchPayload({ filters: archivalFilters }))
      trackAnalyticsData({
        eventType: BetaAnalytics.event.ADD_FILTER,
        eventName: BetaAnalytics.event.FILTER_INCLUDE_ARCHIVED,
        eventData: { key: FILTER_IS_ARCHIVED_KEY, value: 'ALL' },
      })
    } else if (onlyArchivedChecked) {
      archivalFilters[filterKey] = ["true"]
      dispatch(updateSearchPayload({ filters: archivalFilters }))
      trackAnalyticsData({
        eventType: BetaAnalytics.event.ADD_FILTER,
        eventName: BetaAnalytics.event.FILTER_IS_ARCHIVED,
        eventData: { key: FILTER_IS_ARCHIVED_KEY, value: 'true' },
      })
    } else {
      archivalFilters[filterKey] = [`false`]
      dispatch(updateSearchPayload({ filters: archivalFilters }))
      trackAnalyticsData({
        eventType: BetaAnalytics.event.ADD_FILTER,
        eventName: BetaAnalytics.event.FILTER_IS_ARCHIVED,
        eventData: {
          key: FILTER_IS_ARCHIVED_KEY,
          value: 'false',
        },
      })
    }
    closePopOver()
  }

  const onSelectOnlyArchived = (eventChecked) => {
    setIncludedArchivedChecked(false)
    setArchivedChecked(eventChecked)
  }

  return (
    <Grid container className={classes.archivalContainer}>
      <Grid container item className={classes.archivalItem}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={includedArchivedChecked}
                onChange={(event) =>
                  setIncludedArchivedChecked(event.target.checked)
                }
                value={includedArchivedChecked}
                size="small"
                disabled={!includedArchivedChecked && onlyArchivedChecked}
                className={classes.checkbox}
                data-cy={`includeArchivedCheckbox`}
              />
            }
            label={'Include Archived'}
          />
        </Grid>
        {isAdmin &&
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyArchivedChecked}
                  onChange={(event) => onSelectOnlyArchived(event.target.checked)}
                  value={onlyArchivedChecked}
                  className={classes.checkbox}
                  size="small"
                  data-cy={`onlyArchivedCheckbox`}
                />
              }
              label={'Show Only Archived'}
            />
          </Grid>
        }
      </Grid>
      <Grid item>
        <CustomButtonGroup onClickApply={onClickApply} hideClearButton={true} />
      </Grid>
    </Grid>
  )
}

export default ArchivalAssetFilter

import { combineReducers } from 'redux'
import annotation from './annotation/reducer'
import auth from './auth/reducer'
import layout from './layout/reducer'
import notification from './notification/reducer'
import projects from './projects/reducer'
import projectTypes from './projects/projectTypeReducer'
import metadata from './metadata/reducer'
import tmList from './teamMemberList/reducer'
import boards from './boards/reducer'
import groups from './groups/reducer'
import task from './task/reducer'
import uploads from './uploads/reducer'
// TODO rename later once basic flow completed.
import assetsSearch from './search/reducer'
import userPreferences from './preferences/reducer'
import assetPublishUpload from './publishUpload/reducer'
import userPublish from './publishUpload/users/userReducer'
import publish from './publish/reducer'
import channelPublish from './channelUpload/reducer'
import headerReducer from './header/reducer'
import assetClassificationReducer from './classification/reducer'
import tableContextReducer from './tableContext/reducer'

//BetaSearch Store
import searchReducer from '../betasearch/store/search/searchSlice'
import userPreferenceReducer from '../betasearch/store/preference/userPreferenceSlice'
import metadataReducer from '../betasearch/store/metadata/metadataSlice'

//Tenant
import tenantReducer from './tenant/actionCreator'

export const staticReducers = {
  auth,
  annotation,
  boards,
  layout,
  notification,
  projects,
  metadata,
  tmList,
  projectTypes,
  groups,
  task,
  uploads,
  assetsSearch,
  userPreferences,
  assetPublishUpload,
  userPublish,
  publish,
  channelPublish,
  headerReducer,
  assetClassificationReducer,
  tableContextReducer,
  newSearch: searchReducer,
  newUserPreference: userPreferenceReducer,
  newMetadata: metadataReducer,
  tenant: tenantReducer,
}

export const createReducer = (asyncReducers = {}) =>
  combineReducers({
    ...staticReducers,
    ...asyncReducers,
  })

export default createReducer
